<template>
  <q-card>
    <div class="card__title no-mrpd">
      <q-btn
        flat
        :icon="$icons.matClose"
        color="grey-6"
        @click.native="cancelEdit"
        size="12px"
        dense
        round
        class="absolute-top-right q-mr-xs q-mt-xs"
      />
    </div>

    <div class="card__text  fill-height flex-y" style="padding: 0 50px;">
      <div>
        <div class="flex-1 py-3">
          <strong class="an-20 medium-text">Share Message</strong>
        </div>
      </div>
      <!-- Editor -->
      <div class="flex-y">
        <div
          class="flex-y flex-1 border-radius5 mx-1 editor-shadow-edit-share"
          style="background: #FFF;"
          id="collaborationPostUploader"
        >
          <q-linear-progress
            color="green"
            :indeterminate="query"
            size="5px"
            class="ma-0 progress"
            :value="progress"
            v-if="progress > 0"
            dark
            rounded
          />
          <div class="row flex-1 share-post-editor">
            <add-media-plus
              @clicked="fileUpload.dialog = true"
              @openSharedFile="sharedFileDialog = true"
              @dropBox="() => dropBox(null, currentUser.id)"
              @googleDrive="() => googleDrive(null, currentUser.id)"
              @fromComputer="fromComputer"
            />
            <!-- Editor -->
            <editor
              v-model="content"
              :isAbPosition="true"
              :mentions="mentionsList"
              :class-name="['ProseMirror-collaboration-editor']"
              :autofocus="true"
              @submit="savePost"
              placeholder="Type a message here"
              @paste="pastedContent"
              :maxHeight="200"
              :minHeight="100"
            />
            <shared-files-dialog
              v-model="sharedFileDialog"
              @closeDialog="sharedFileDialog = false"
              :imageList="imageList"
              :attachMediaToPost="addSharedFileToUploader"
              :isloadingMedia="isloadingMedia"
              :currentWorkspaceId="currentWorkspace"
            />
            <vue-dropzone-file-upload
              hidden
              ref="vueFileUploader"
              :workspace="currentWorkspace"
              :isWorkspaceOwner="isWorkspaceOwner"
              :authToken="authToken"
              @close="fileUploadCloseHandler"
              @setProgress="setProgress"
            ></vue-dropzone-file-upload>
          </div>
          <div
            class="row no-wrap scroll-y edited-medias pa-3"
            style="margin-left: 30px;margin-bottom: 30px;"
            v-if="editedMedias && editedMedias.length > 0"
          >
            <div v-for="media in editedMedias" :key="media.id">
              <editor-edit-media
                :media="media"
                :deleteMediaFromPost="deleteMediaFromPost"
              />
            </div>
          </div>
        </div>
        <div class="row q-mt-md">
          <div>
            <q-avatar size="48px" color="blue-grey-11" text-color="grey-10">
              <img
                :src="returnPost.user.pic"
                :alt="returnPost.user.first"
                class="text-avatar-style"
                v-if="returnPost.user.pic"
              />
              <!-- <span class="text-bold text-h6" v-else>
                {{ returnPost.user.first | firstChar
                }}{{ returnPost.user.last | firstChar }}
              </span> -->
              <avatar v-else :size="48" :customStyle="{'font-size':'24px', 'font-weight':'600'}" :username="returnPost.user.first+' '+returnPost.user.last"></avatar>
            </q-avatar>
          </div>
          <div class="flex-1 px-2">
            <div class="messaging-user-detail-chip ml-2">
              <span class="user-name">{{ returnPost.user.first }}</span>
              <span class="time">
                <span v-if="returnPost.updated_at > returnPost.created_at">
                  {{ returnPost.created_at | gettime }}
                  <span class="capitalize-text an-12">(Edited)</span>
                </span>
                <span v-else>{{ returnPost.created_at | gettime }}</span>
              </span>
            </div>
            <div
              v-html="postHtml"
              style="max-height: 150px;overflow: auto;"
              class="post-content mt-0 dont-break-out ml-2"
              wrap
              fluid
              grid-list-lg
              v-show="returnPost.content"
            ></div>
            <div
              class="row no-wrap scroll-y edited-medias q-pl-xs q-pt-xs"
              v-if="returnPost.media"
            >
              <div v-for="(media, index) in returnPost.media" :key="index">
                <editor-edit-media
                  :isdelete="false"
                  :media="media"
                  :showProgress="showProgress"
                />
                <span
                  v-if="media.filename.length <= 19"
                  class="ellipses an-14 "
                  >{{ media.filename }}</span
                >
                <span v-else class="ellipses an-14">
                  {{ media.filename.substring(0, 16) + "..." }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <q-card-actions class="q-pb-sm q-pr-xl">
      <q-space />
      <q-btn
        flat
        no-caps
        color="grey-6"
        class="q-px-md"
        size="16px"
        @click="cancelEdit"
        label="Cancel"
      />
      <q-btn
        color="teal-14"
        class="q-px-md"
        size="16px"
        no-caps
        :disabled="!content || showProgress"
        @click="savePost"
        label="Share"
      />
    </q-card-actions>
    <upgrade-storage-dialog
      v-if="company && company.pricing_version === 3 && upgradeStorageDialog"
      v-model="upgradeStorageDialog"
      :reachedLimitPlan="company && company"
    />
  </q-card>
</template>

<script>
import Editor from "@/components/Editor/Editor";
import getUnixTime from "date-fns/getUnixTime";
import { mapGetters } from "vuex";
import get from "lodash/get";
import keys from "lodash/keys";
import filter from "lodash/filter";

import EditorEditMedia from "@/components/Editable/EditorEditMedia";
import AddMediaPlus from "@/components/Editor/AddMediaPlus";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import { compile } from "@/components/Editor/MarkupUtils";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import UpgradeStorageDialog from "@/components/Dialogs/UpgradeStorageDialog";
import { CreatePostMutation } from "@/gql";
import mixpanel from "@/mixpanel";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import Avatar from 'vue-avatar';

export default {
  name: "SharePostTextEditorDialog",
  props: [
    "sharedata",
    "mentions",
    "workspace",
    "currentUser",
    "taskLabels",
    "isWorkspaceOwner",
    "imageList",
    "readMessageNotifications",
    "isloadingMedia",
  ],
  components: {
    Editor,
    EditorEditMedia,
    AddMediaPlus,
    SharedFilesDialog,
    UpgradeStorageDialog,
    VueDropzoneFileUpload,
    Avatar,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
    },
    company: {
      cacheKey: "CompanyQuery",
    },
  },
  mixins: [GoogleDriveMethodMixin, DropBoxMixin, CopiedImageOnEditor],
  data() {
    return {
      content: null,
      previousTagged: [],
      fileUpload: {
        flag: false,
        dialog: false,
      },
      fileList: null,
      sharedFileDialog: false,
      upgradeStorageDialog: false,
      progress: 0,
    };
  },
  mounted() {
    document.body.classList.remove("vabotu-body");
  },
  beforeDestroy() {
    document.body.classList.add("vabotu-body");
  },
  methods: {
    async createSharedPost(outsourceMediaIds = []) {
      const content = this.content;

      const media = [];
      // For Any drive Files
      const driveData = this.makeDriveData();

      this.$emit("close");

      if (content && content.trim() !== "") {
        const variables = {
          workspace_id: this.currentWorkspace,
          content: content,
          media: [...this.s3MediaIds, ...outsourceMediaIds], // Media ids
          post_id: this.sharedata.id, // Post id in case of forward post,
          media_urls: driveData,
        };

        const OptID = getUnixTime(new Date());
        const oldPost = {
          id: this.sharedata.id,
          workspace_id: this.currentWorkspace,
          media: this.sharedata.media,
          user_id: this.sharedata.user.id,
          created_at: this.sharedata.created_at,
          owner: this.sharedata.user,
          content: this.sharedata.content,
          __typename: "post",
        };

        const fakePost = {
          id: OptID,
          __typename: "post",
          content: variables.content,
          media: media,
          user_id: this.currentUser.id,
          can_delete: false,
          is_bot: false,
          // unread: false, // NEEDTOREMOVE
          user: this.currentUser,
          created_at: getUnixTime(new Date()),
          updated_at: getUnixTime(new Date()),
          forwardedPost: oldPost,
          reactionset: [],
          reactiondata: [],
          seens: [],
        };
        this.reset();
        this.readMessageNotifications();
        // 1. get query
        const postsQuery = this.$api.getQuery(
          `MessagesQuery:${this.currentWorkspace}`
        );
        if (postsQuery.data) {
          // 2. add fake entity
          postsQuery.data.messages.unshift(fakePost);

          // 3. get real entity
          const response = await this.$api.mutate({
            mutation: CreatePostMutation,
            variables,
          });
          // 4. replace fake entity
          postsQuery.data.messages = postsQuery.data.messages.map((task) =>
            task === fakePost ? response.data.createPost : task
          );
        } else {
          await this.$api.mutate({
            mutation: CreatePostMutation,
            variables,
          });
        }
        mixpanel.track("Message Create", { quote: true });
      }
    },
    async savePost() {
      if (this.content && this.content.trim() !== "") {
        this.commonOutSourceFileHandler((outsourceMediaIds, code) => {
          if (code === 403) {
            this.upgradeStorageDialog = true;
          } else {
            this.createSharedPost(outsourceMediaIds);
          }
        });
      }
    },

    cancelEdit() {
      this.$emit("close");
      this.$nextTick(() => {
        this.$emit("reset");
        this.reset();
      });
    },
    reset() {
      this.content = null;
      this.$eventBus.$emit("clearFilterOnPost");
      this.$eventBus.$emit("messagesScollReset");
    },
    async deleteMediaFromPost(media) {
      const fileList = this.fileList;
      delete fileList[media.id];
      this.fileList = { ...fileList };
      if (media.file_resource === "s3" && media["verifyKey"] !== "fromShared") {
        this.deleteMediaMutation(media);
      }
      if (!this.uploadedFileIds.length) {
        this.progress = 0;
      }
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    setProgress(progress) {
      this.progress = progress;
      if (progress === 1) {
        this.showProgress = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
      workspaceTextEditorPost: "workspaceTextEditorPost",
      workspaceTextEditorMedia: "workspaceTextEditorMedia",
    }),
    postHtml() {
      return compile(this.returnPost.content);
    },
    editedMedias() {
      const optmedia =
        this.uploadedFileIds && this.uploadedFileIds.length > 0
          ? Object.values(this.fileList)
          : [];
      if (optmedia) {
        return optmedia;
      }
      return null;
    },
    currentWorkspace() {
      return this.workspace ? this.workspace.id : -1;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    s3MediaIds() {
      const s3File = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "outsource" &&
          (a.file_resource === "s3" || a.hasOwnProperty("verifyKey"))
      );
      const data = {};
      for (var media in s3File) {
        data[s3File[media].id] = s3File[media];
      }
      if (data) {
        return keys(data);
      } else {
        return [];
      }
    },
    uploadedFileIds() {
      if (this.fileList) {
        return keys(this.fileList);
      } else {
        return [];
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    mentionsList() {
      return this.mentions;
    },
    returnPost() {
      return this.sharedata;
    },
  },
};
</script>
