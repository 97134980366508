<template>
  <div
    class="saved-media-column q-ml-lg"
    :style="
      savedFiles &&
        !savedFiles.length && {
          'max-width': '365px',
          'min-width': '365px',
        }
    "
  >
    <div class="row items-center flex-no-wrap">
      <div class="media-icon relative-position">
        <q-icon
          class="absolute text-bold"
          :name="$icons.mdiFileMultiple"
          color="white"
          size="15px"
        />
      </div>
      <div class="media-text q-ml-md">Files</div>
    </div>
    <div
      :class="[
        savedFiles &&
          !savedFiles.length &&
          'bg-white q-pa-md full-height q-mt-md',
        'saved-media q-pt-md row',
      ]"
      style="border-radius: 11px;"
      :style="
        isBannerVisible
          ? { 'max-height': 'calc(100vh - 300px)' }
          : { 'max-height': 'calc(100vh - 170px)' }
      "
    >
      <template v-if="savedFolders && savedFolders.length">
        <template v-for="savedFolder in savedFolders">
          <saved-folder
            :key="savedFolder.id"
            class="q-mr-sm"
            :user="user"
            :folder="savedFolder"
            :openFolder="openFolder"
            :removeMediaFolderBookmark="removeMediaFolderBookmark"
            :deleteFolderHandler="deleteFolderHandler"
          />
        </template>
      </template>
      <template v-if="savedFiles && savedFiles.length">
        <template v-for="savedFile in savedFiles">
          <saved-file
            class="q-mr-sm"
            :user="user"
            :media="savedFile"
            :currentCompany="currentCompany"
            :activeCompany="activeCompany"
            :gotoWorkspace="gotoWorkspace"
            :deleteFile="deleteFile"
            :fullview="fullview"
            :videoPlayer="videoPlayer"
            :audioPlayer="audioPlayer"
            :pdfView="pdfView"
            :key="savedFile.id"
            :editDoc="editDoc"
          />
        </template>
      </template>
      <template v-else>
        <div
          class="row justify-center text-center "
          style="font-size:16px;padding:0 44px"
        >
          <div class="q-pt-md">
            <q-avatar size="58px" :style="{ background: '#e9e5ff' }">
              <q-icon
                size="30px"
                style="color:#6f61b1"
                :name="$icons.mdiFileMultiple"
              />
            </q-avatar>
            <div class="q-mt-sm q-mb-xs text-bold">
              There are no saved files
            </div>
            <span>
              Click the bookmark icon on a file to show it here.
            </span>
          </div>
        </div>
      </template>
    </div>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="savedFiles"
      :ishide="ishide"
      :labels="taskLabels"
      :moreOption="moreOption"
      :currentUser="user"
      :dialogMedia="returndialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :resetDialog="() => resetDialog()"
      @sendComment="sendComment"
      @updateComment="updateComment"
      @updatepositions="updatepositions"
      @clickonhidebutton="ishide = !ishide"
      @clickonmoreoption="clickonmoreoptionHandler"
      @deleteMediaComment="deleteMediaComment"
      @deleteCommentPoint="deleteCommentPoint"
      @closeMediaModel="closeMediaModel"
      @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
      @setActiveMedia="setActiveMedia"
    ></image-viewer-dialog>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :medias="savedFiles"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    />
    <audio-dialog
      v-model="showAudioModel"
      v-if="showAudioModel"
      :medias="savedFiles"
      :resetAudioDialog="resetAudioDialog"
      :audioMediaData="audioMediaData"
      :displayAudioPlayer="displayAudioPlayer"
    />
    <pdf-view-dialog
      v-if="showpdfModel"
      :fileExt="fileExt"
      v-model="showpdfModel"
      @resetPdfDialog="resetPdfDialog"
      :pdfMediaData="pdfMediaData"
    />
    <confirm-dialog
      v-if="confirm"
      v-model="confirm"
      title="Delete File?"
      question="Are you sure you want to delete this file?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => (confirm = false)"
      :successMethod="removeMediaHandler"
      :loading="false"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="taskLabels"
      :authToken="currentCompany.accessToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :isMoveAction="copyMoveDialog.isMoveAction"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="moveCopyFilFolderHandler"
      :currentWorkspaceId="currentWorkspace"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";

import SavedFile from "@/views/SavedItemsView/SavedFiles/SavedFile";
import SavedFolder from "@/views/SavedItemsView/SavedFiles/SavedFolder";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import PdfViewDialog from "@/components/Dialogs/PdfViewDialog";
import AudioDialog from "@/components/Dialogs/AudioPlayerDialog";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";

import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import FolderMethodMixin from "@/mixins/FolderMethodMixin";
import { DeleteMediaMutation } from "@/gql";
export default {
  name: "SavedFiles",
  mixins: [MediaMethodMixin, FolderMethodMixin],
  props: [
    "savedFiles",
    "gotoWorkspace",
    "isBannerVisible",
    "savedFolders",
    "editDoc",
    "openDocEditor",
  ],
  components: {
    SavedFile,
    ConfirmDialog,
    VideoDialog,
    PdfViewDialog,
    AudioDialog,
    ImageViewerDialog,
    SavedFolder,
    FolderDetailsDialog,
    PostCopyMoveFileFolder,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    taskLabels: {
      defaultValue: [],
      cacheKey: "TaskLabelsQuery",
    },
  },
  data() {
    return {
      showModal: false,
      showVideoModel: false,
      showAudioModel: false,
      displayPlayer: false,
      displayAudioPlayer: false,
      dialogMedia: null,
      videoMediaData: null,
      audioMediaData: null,
      pdfMediaData: null,
      showpdfModel: false,
      confirm: false,
      deleteFileId: null,
      gridMediaId: "",
      ishide: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
        {
          title: "Hide Comments",
        },
      ],
    };
  },
  methods: {
    deleteFile(mediaId) {
      this.confirm = true;
      this.deleteFileId = mediaId;
    },
    removeMediaHandler() {
      this.removeMedia(this.deleteFileId);
      this.confirm = false;
    },
    async removeMedia(id) {
      const variables = {
        id,
      };
      const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
      savedItemsQuery.data.savedItems.media = savedItemsQuery.data.savedItems.media.filter(
        (item) => item.id != id
      );
      await this.$api.mutate({
        mutation: DeleteMediaMutation,
        variables,
      });
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    videoPlayer(media) {
      this.videoMediaData = media;
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    audioPlayer(media) {
      this.audioMediaData = media;
      this.displayAudioPlayer = true;
      this.showAudioModel = true;
    },
    resetAudioDialog() {
      this.displayAudioPlayer = false;
      setTimeout(() => {
        this.showAudioModel = false;
      }, 10);
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
      this.$emit("closeMediaModel", {
        id: this.gridMediaId,
      });
    },
    pdfView(media) {
      this.pdfMediaData = media;
      this.showpdfModel = true;
      // if (this.fileExt.match(/pdf.*/)) {
      //   window.open(media.file, "_self");
      // } else {
      // }
    },
    resetPdfDialog() {
      this.pdfMediaData = null;
      this.showpdfModel = false;
    },
    clickonmoreoptionHandler(item) {
      const data = {
        title: item.title,
        gridMediaId: this.gridMediaId,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.clickonmoreoption(data);
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    fileExt() {
      if (this.pdfMediaData) {
        return this.pdfMediaData.filename.split(".")[1];
      }
      return null;
    },
    returndialogMedia() {
      return this.dialogMedia;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = this.savedFiles.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = this.savedFiles.filter((o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    currentWorkspace() {
      return (
        this.folderDetailsDialog.data &&
        this.folderDetailsDialog.data.workspace_id
      );
    },
    isWorkspaceOwner() {
      if (this.currentWorkspace && this.user) {
        const workspace = this.$api.getEntity(
          "workspace",
          this.currentWorkspace
        );
        return this.user.id === workspace.owner.id;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-media-column {
  max-width: 335px;
  min-width: 335px;
  .media-text {
    font-size: 17px;
  }
  .media-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6f61b1;
  }
  .saved-media {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
  }
}
</style>
