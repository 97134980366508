<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card style="min-width:500px;  " class="q-pa-lg">
      <div class="row title q-mb-md">
        <span class="q-mx-auto">Notes</span>
      </div>
      <q-input
        placeholder="Type description here"
        type="textarea"
        dense
        outlined
        autofocus
        v-model="noteTxt"
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="15px"
          label="Cancel"
          style="width:100px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Done"
          @click="handleNoteInput(noteTxt)"
          style="width:100px"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: "NotesEditDialog",
  props: ["value", "description", "handleNoteInput"],
  data() {
    return {
      noteTxt: null,
    };
  },
  mounted() {
    this.noteTxt = this.description;
  },
  methods: {},
};
</script>
<style scoped>
.title {
  font-weight: 500;
  font-size: 18px;
}
</style>
