<template>
  <div class="saved-tasks-column">
    <div class="row items-center flex-no-wrap">
      <div class="task-icon relative-position">
        <q-icon
          class="absolute text-bold"
          :name="$icons.fasCheck"
          color="white"
          size="15px"
        />
      </div>
      <div class="tasks-text q-ml-md">Tasks</div>
    </div>
    <div
      :class="[
        savedTasks && !savedTasks.length && 'bg-white q-pa-md full-height',
        'saved-tasks q-mt-md',
      ]"
      style="border-radius: 11px;"
      :style="
        isBannerVisible
          ? { 'max-height': 'calc(100vh - 300px)' }
          : { 'max-height': 'calc(100vh - 170px)' }
      "
    >
      <template v-if="savedTasks && savedTasks.length">
        <template v-for="savedTask in savedTasks">
          <saved-task
            :currentCompany="currentCompany"
            :task="savedTask"
            :gotoWorkspace="gotoWorkspace"
            :key="savedTask.id"
            :editTaskDialogHandler="openTaskDailog"
            @delete="deleteTaskConfirmBoxHandler"
          />
        </template>
      </template>
      <template v-else>
        <div
          class="row justify-center text-center "
          style="font-size:16px;padding:0 125px"
        >
          <div class="q-pt-md">
            <q-avatar size="58px" :style="{ background: '#defff5' }">
              <q-icon
                size="30px"
                style="color:#15d89a"
                :name="$icons.fasCheck"
              />
            </q-avatar>
            <div class="q-mt-sm q-mb-xs text-bold">
              You have no saved tasks
            </div>
            <span>
              Click the bookmark icon on a task to show it here.
            </span>
          </div>
        </div>
      </template>
    </div>
    <div v-if="confirmBox.taskRemove.task && confirmBox.taskRemove.flag">
      <confirm-dialog
        v-model="confirmBox.taskRemove.flag"
        title="Delete task?"
        question="Deleting this task will also delete all the sub-tasks and comments in it."
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="resetTaskRemoveConfirmbox"
        :successMethod="deleteSavedTask"
        :loading="confirmBox.taskRemove.loader"
      />
    </div>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      v-model="taskDialog"
      persistent
      @before-hide="closeDialog"
      @keydown.esc="resetTaskDialog"
    >
      <task-dialog
        v-if="editTaskdata"
        place="board"
        :tasks="editTaskdata"
        :boardId="task.board_id"
        :taskLabels="taskLabels"
        :workspaceid="workspace.id"
        :workspace="workspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :currentUser="user"
        :authToken="currentCompany.accessToken"
        :workspaceMembers="workspaceMembers"
        :currentCompany="currentCompany"
        :openSubtask="openSubtask"
        :openTaskComment="openTaskComment"
        :reactions="reactions"
        :attachTaskMediaHandler="attachTaskMedia"
        :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
        :deleteTaskCommentHandler="deleteTaskComment"
        :detachTaskAttachmentHandler="detachTaskAttachment"
        @reactOnPost="reactOnPost"
        @updateMediaCache="updateMediaCache"
        @createSubTask="createSubTask"
        @close="resetTaskDialog"
        @success="taskDialogSuccessHandler"
        @clearSubtasks="clearSubtasks"
        @createComment="createComment"
        @clickonmoreoption="clickonmoreoption"
        @deleteCommentPoint="deleteCommentPoint"
        @updatepositions="updatepositions"
        @closeMediaModel="closeMediaModel"
        @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
        @updateComment="updateComment"
        @sendComment="sendComment"
        @deleteMediaComment="deleteMediaComment"
        @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
        @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
      ></task-dialog>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";

import SavedTask from "@/views/SavedItemsView/SavedTasks/SavedTask";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import TaskDialog from "@/views/BoardView/TaskDialog";

import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import { DeleteTaskMutation, PrivateTasksQuery } from "@/gql";
export default {
  name: "SavedTasks",
  props: ["savedTasks", "gotoWorkspace", "isBannerVisible"],
  mixins: [BoardsMethodMixin, MediaMethodMixin],
  components: {
    SavedTask,
    ConfirmDialog,
    TaskDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    taskLabels: {
      defaultValue: [],
      cacheKey: "TaskLabelsQuery",
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
  },
  data() {
    return {
      confirmBox: {
        taskRemove: {
          flag: false,
          loader: false,
          id: null,
        },
      },
      workspace: null,
      workspaceMembers: null,
      task: null,
      taskDialog: false,
    };
  },
  methods: {
    async openTaskDailog(data) {
      if (data.task.board_id) {
        const board = this.$api.getEntity("board", data.task.board_id);
        this.workspace = this.$api.getEntity("workspace", board.workspace_id);
      } else {
        const card = this.$api.getEntity("card", data.task.card_id);
        const board = this.$api.getEntity("board", card.board_id);
        this.workspace = this.$api.getEntity("workspace", board.workspace_id);
      }
      if (this.workspace) {
        await this.$api.query({
          query: PrivateTasksQuery,
          variables: {
            board_id: this.workspace.boards[0].id,
          },
          cacheKey() {
            return `PrivateTasksQuery:${this.workspace.boards[0].id}`;
          },
        });
      }
      this.workspaceMembers = this.workspace.users;
      this.editTask(data);
    },
    deleteTaskConfirmBoxHandler(task) {
      this.confirmBox.taskRemove = {
        flag: true,
        task: task,
        loader: false,
      };
    },
    async deleteSavedTask() {
      this.confirmBox.taskRemove.loader = true;
      if (this.confirmBox.taskRemove.id !== null) {
        const id = this.confirmBox.taskRemove.task.id;
        const variables = {
          id,
        };
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.task = savedItemsQuery.data.savedItems.task.filter(
          (item) => item.id != id
        );
        await this.$api.mutate({
          mutation: DeleteTaskMutation,
          variables,
        });
        this.resetTaskRemoveConfirmbox();
      } else {
        this.resetTaskRemoveConfirmbox();
      }
    },
    resetTaskRemoveConfirmbox() {
      this.confirmBox.taskRemove = {
        flag: false,
        task: null,
        loader: false,
      };
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    taskWorkspace() {
      if (this.task.board_id) {
        const board = this.$api.getEntity("board", this.task.board_id);
        const workspace = this.$api.getEntity("workspace", board.workspace_id);
        return workspace;
      } else {
        const card = this.$api.getEntity("card", this.task.card_id);
        const board = this.$api.getEntity("board", card.board_id);
        const workspace = this.$api.getEntity("workspace", board.workspace_id);
        return workspace;
      }
    },
    isWorkspaceOwner() {
      if (this.taskWorkspace && this.user) {
        return this.user.id === this.taskWorkspace.owner.id;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-tasks-column {
  min-width: 530px;
  .tasks-text {
    font-size: 17px;
  }
  .task-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #15d89a;
  }
  .saved-tasks {
    overflow: auto;
  }
}
</style>
