<template>
  <div class="saved-messages-column">
    <div class="row items-center flex-no-wrap flex-1">
      <div class="message-icon relative-position">
        <q-icon
          class="absolute"
          :name="$icons.matTextsms"
          color="white"
          size="12px"
        />
      </div>
      <div class="messages-text q-ml-md">Messages</div>
    </div>
    <div
      :class="[
        savedMessages && !savedMessages.length && 'full-height',
        'bg-white saved-messages q-mt-md q-pa-md flex-1 ',
      ]"
      :style="
        isBannerVisible
          ? { 'max-height': 'calc(100vh - 300px)' }
          : { 'max-height': 'calc(100vh - 170px)' }
      "
    >
      <template v-if="savedMessages && savedMessages.length">
        <template v-for="savedMessage in savedMessages">
          <saved-message
            :key="savedMessage.id"
            class="q-mb-lg"
            :reactions="reactions"
            :taskLabels="taskLabels"
            :message="savedMessage"
            :gotoWorkspace="gotoWorkspace"
            :user="user"
            :editDoc="editDoc"
            @reactOnPost="reactOnMessage"
            @showUserInfo="showUserInfo"
            @sharePost="sharePost"
            @clickonmoreoption="clickonmoreoption"
            @deleteCommentPoint="deleteCommentPoint"
            @updatepositions="updatepositions"
            @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
            @updateComment="updateComment"
            @sendComment="sendComment"
            @deleteMediaComment="deleteMediaComment"
          />
        </template>
      </template>
      <template v-else>
        <div
          class="row justify-center text-center q-px-lg"
          style="font-size:16px"
        >
          <div class="q-pt-md">
            <q-avatar size="58px" :style="{ background: '#fff4d2' }">
              <q-icon
                size="30px"
                style="color:#ffa21e"
                :name="$icons.matBookmark"
              />
            </q-avatar>
            <div class="q-mt-sm q-mb-xs text-bold">
              You have no saved messages
            </div>
            <span>
              Click the bookmark icon on a message to show it here.
            </span>
          </div>
        </div>
      </template>
    </div>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      persistent
      v-if="sharepostDialog"
      v-model="sharepostDialog"
      full-width
    >
      <div style="flex:0 1 80%;max-height:100%">
        <share-post-text-editor-dialog
          slot="body"
          v-if="sharepostDialog"
          :sharedata="sharedata"
          :currentCompany="currentCompany"
          :isPersonal="false"
          :mentions="taggUsers"
          @close="sharepostDialog = false"
          @reset="resetPost"
          :workspace="userWorkspace"
          :taskLabels="taskLabels"
          :currentUser="user"
          :isWorkspaceOwner="isWorkspaceOwner"
          :readMessageNotifications="readMessageNotificationsHandler"
          @clickonmoreoption="clickonmoreoption"
          @deleteCommentPoint="deleteCommentPoint"
          @updatepositions="updatepositions"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @closeMediaModel="closeMediaModel"
          @deleteMediaFromPost="deleteMediaFromPost"
        />
      </div>
    </q-dialog>
    <profile-dialog
      v-model="profileDialog.show"
      v-if="profileDialog.show"
      :recepient="userProfileDialog"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :currentWorkspaceId="userWorkspace.id"
      :currentCompany="currentCompany"
      @close="clearUserInfoDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import getUnixTime from "date-fns/getUnixTime";

import SavedMessage from "@/views/SavedItemsView/SavedMessages/SavedMessage";
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import SharePostTextEditorDialog from "@/views/MessagingView/Threads/SharePostTextEditorDialog";
import ProfileDialog from "@/components/ProfileDialog";

import {
  DetachPostMediaMutation,
  UpdateNotificationsMutation,
  AddMessageReactionMutation,
  RemoveMessageReactionMutation,
} from "@/gql";
export default {
  name: "SavedMessages",
  mixins: [MediaMethodMixin],
  props: ["savedMessages", "gotoWorkspace", "isBannerVisible", "editDoc"],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
    taskLabels: {
      defaultValue: [],
      cacheKey: "TaskLabelsQuery",
    },
  },
  components: {
    SavedMessage,
    SharePostTextEditorDialog,
    ProfileDialog,
  },
  data() {
    return {
      sharepostDialog: false,
      sharepostdata: null,
      workspaceMembers: null,
      userWorkspace: null,
      profileDialog: {
        show: false,
        user: null,
      },
    };
  },
  methods: {
    async sharePost(post) {
      const workspace = this.$api.getEntity("workspace", post.workspace_id);
      this.workspaceMembers = workspace.users;
      this.userWorkspace = workspace;
      this.sharepostdata = post;
      this.sharepostDialog = !this.sharepostDialog;
    },
    resetPost() {
      this.sharepostdata = null;
      this.workspaceMembers = null;
      this.userWorkspace = null;
    },
    async deleteMediaFromPost(variables) {
      await this.$api.mutate({
        mutation: DetachPostMediaMutation,
        variables,
      });
    },
    showUserInfo(user, workspace) {
      this.profileDialog.show = true;
      this.profileDialog.user = user;
      this.userWorkspace = workspace;
    },
    clearUserInfoDialog() {
      this.profileDialog.show = false;
      this.profileDialog.user = null;
      this.userWorkspace = null;
    },
    async readMessageNotificationsHandler() {
      const query = this.$api.getQuery("NotificationsQuery");
      let notification_ids;
      if (query.data) {
        notification_ids = query.data.notifications
          .filter((n) => !n.read)
          .filter((n) => n.action.scope.id === Number(this.currentWorkspaceId))
          .filter((n) => n.action.object_type === "App\\Post")
          .map((n) => {
            n.read = true;
            return n.id;
          });
        if (notification_ids.length) {
          const variables = {
            notification_ids: notification_ids,
            read: true,
          };
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables,
          });
        }
      }
    },
    async reactOnMessage(args) {
      let { post, reaction } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }

      const variables = {
        id: post.id,
        reaction_id: reaction.id,
      };
      const newReactiondata = { ...post.reactiondata };
      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          this.readMessageNotificationsHandler();
          await this.$api.mutate({
            mutation: RemoveMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            pic: this.user.pic,
            time: getUnixTime(new Date()),
          });
          this.readMessageNotificationsHandler();
          await this.$api.mutate({
            mutation: AddMessageReactionMutation,
            variables,
            skipUpdates: true,
          });
        }
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = { ...newReactiondata };
            }
          });
        }
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            pic: this.user.pic,
            time: getUnixTime(new Date()),
          },
        ];
        const query = this.$api.getQuery("NotificationsQuery");
        if (query.data) {
          query.data.notifications.map((n) => {
            if (n.action.object_id === post.id) {
              n.action.object.reactiondata = { ...newReactiondata };
            }
          });
        }
        this.readMessageNotificationsHandler();
        this.$api.updateEntity("post", post.id, {
          reactiondata: newReactiondata,
        });
        await this.$api.mutate({
          mutation: AddMessageReactionMutation,
          variables,
          skipUpdates: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    sharedata() {
      return this.sharepostdata;
    },

    taggUsers() {
      if (this.workspaceMembers) {
        return this.workspaceMembers;
      } else {
        return null;
      }
    },
    isWorkspaceOwner() {
      if (this.userWorkspace && this.user) {
        return this.user.id === this.userWorkspace.owner.id;
      }
      return false;
    },
    userProfileDialog() {
      return this.profileDialog.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-messages-column {
  max-width: 372px;
  min-width: 372px;
  .messages-text {
    font-size: 17px;
  }
  .message-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #007aff;
  }
  .saved-messages {
    border-radius: 11px;
    overflow: auto;
  }
}
</style>
