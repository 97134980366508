<template>
  <q-card class="document-editor-container">
    <NoteDocumentEditor
      :doc="doc"
      :updateDocStateOnClose="updateDocStateOnClose"
      :isMobileView="isMobileView"
      class="document-editor overflow-hidden"
      style="height: 100%;"
    />
  </q-card>
</template>

<script>
import * as Y from "yjs";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import NoteDocumentEditor from "@/components/DocumentEditor/NoteDocumentEditor";
import { UpdateTaskNoteMutation, TaskNoteQuery } from "@/gql";
export default {
  components: { NoteDocumentEditor },
  props: {
    documentProp: {
      type: Object,
      default: null,
    },
    tasks: {
      type: Object,
      default: null,
    },
    closeDocEditor: Function,
  },
  data() {
    return {
      version: null,
      isMobileView: false,
    };
  },
  created() {
    this.doc = new Y.Doc();
    this.debouncedPush = debounce(this.push, 1000);
    this.throttledPull = throttle(this.pull, 2000);

    this.doc.on("update", this.debouncedPush);
    window.addEventListener("resize", this.handleResize);

    this.throttledPull();

    this.interval = setInterval(() => {
      this.throttledPull();
    }, 2000);
  },
  mounted() {
    if (this.document) {
      this.version = this.document.document_version
        ? this.document.document_version
        : 0;
      let { note } = this.document;
      const state = note
        ? Uint8Array.from(note.split(",").map((x) => parseInt(x, 10)))
        : "";
      if (state) {
        Y.applyUpdate(this.doc, state);
      }
      this.handleResize();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 775) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    },
    async push() {
      try {
        const state = Y.encodeStateAsUpdate(this.doc);
        this.updateDoc(state);
      } catch {
        await this.throttledPull();
        await this.debouncedPush();
      }
    },
    async pull() {
      const response = await this.$api.mutate({
        mutation: TaskNoteQuery,
        variables: {
          id: this.document.id,
        },
      });
      if (response && response.data.taskNote) {
        this.version = response.data.taskNote.document_version;
        let { note } = response.data.taskNote;
        const state = note
          ? Uint8Array.from(note.split(",").map((x) => parseInt(x, 10)))
          : "";
        if (state) {
          Y.applyUpdate(this.doc, state);
        }
      }
    },
    updateDocStateOnClose() {
      if (this.document.note) {
        const state = Y.encodeStateAsUpdate(this.doc);
        this.debouncedPush.cancel();
        this.updateDoc(state);
      }
    },
    async updateDoc(state) {
      try {
        const response = await this.$api.mutate({
          mutation: UpdateTaskNoteMutation,
          variables: {
            id: this.document.id,
            note: state ? state.toString() : "",
            document_version: this.version,
          },
        });
        if (this.tasks.note && response.data) {
          this.tasks.note = response.data.updateTaskNote;
          this.$api.updateEntity("task", this.tasks.id, this.tasks);
        }
      } catch (error) {
        console.error("er", error);
      }
      // this.content = JSON.parse(response.data.updateDocument.document_content);
    },
  },
  computed: {
    document() {
      return this.documentProp;
    },
    currentWorkspaceId() {
      if (this.$route.params.workspace) {
        return Number(this.$route.params.workspace);
      } else {
        return 1;
      }
    },
  },
};
</script>
<style scoped>
.document-editor-container {
  border-radius: 0px;
  box-shadow: none;
}
</style>
