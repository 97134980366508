<template>
  <div class="saved-message group-hover relative-position">
    <div class="workspace-name row items-center">
      <q-icon
        v-if="messageWorkspace.is_preferred"
        size="12px"
        :name="$icons.matGrade"
      />
      <q-icon
        v-if="messageWorkspace.type === 'private'"
        size="12px"
        class="q-ml-xs"
        :name="$icons.matLock"
      />
      <div
        class="cursor-pointer"
        :class="[messageWorkspace.type === 'people' ? '' : 'q-ml-sm']"
        @click="gotoWorkspace('MessagingView', messageWorkspace.id)"
      >
        {{
          messageWorkspace.type === "people"
            ? "Direct-Message"
            : messageWorkspace.title
        }}
      </div>
    </div>
    <div class="q-mt-md row flex-no-wrap">
      <div class="q-mr-md">
        <q-avatar
          size="40px"
          color="blue-grey-11"
          text-color="grey-10"
          @click="$emit('showUserInfo', message.user, messageWorkspace)"
        >
          <img
            :src="message.user.pic"
            :alt="message.user.first"
            v-if="message.user.pic"
          />
          <!-- <span class="text-bold text-subtitle1" v-else>
            {{ message.user.first | firstChar
            }}{{ message.user.last | firstChar }}
          </span> -->
          <avatar v-else :size="40" :customStyle="{'font-size':'16px', 'font-weight':'600'}" :username="message.user.first+' '+message.user.last"></avatar>
        </q-avatar>
      </div>
      <div>
        <div class="user-name">{{ message.user.first }}</div>
        <div style="width:100%">
          <div
            v-if="PostHtml.length >= 165 && readMoreToggle === false"
            v-html="trucateContet(PostHtml)"
            class="post-content q-mr-md dont-break-out"
          ></div>
          <div
            v-if="PostHtml.length < 165 || readMoreToggle === true"
            v-html="PostHtml"
            class="post-content q-mr-md notification-content dont-break-out"
          ></div>
          <span
            class="post-content text-primary  cursor-pointer"
            @click="readMoreToggle = true"
            v-show="PostHtml.length >= 165 && readMoreToggle === false"
          >
            read more
          </span>
          <div v-if="PostHtml.length < 165 || readMoreToggle === true">
            <div
              v-if="
                (message.media && message.media.length) ||
                  (message.mediafolder && message.mediafolder.length)
              "
            >
              <post-media
                v-if="message.media || message.mediafolder"
                :attachments="message.media"
                :canRemoveMedia="
                  canRemoveMessageFromWorkspace ||
                    isWorkspaceOwner ||
                    (user && user.id === message.user.id)
                "
                :forceDisableRemove="
                  message.content === '' && message.media.length <= 1
                "
                :openFolder="openFolder"
                :isSavedMessageMedia="true"
                :removeMedia="removeMedia"
                :postId="message.id"
                :labels="taskLabels"
                :user="user"
                :workspace="messageWorkspace.id"
                :isWorkspaceOwner="isWorkspaceOwner"
                :deleteFolderHandler="deleteFolderHandler"
                :post="message"
                :editDoc="editDoc"
                @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
                @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                @deleteCommentPoint="
                  (data) => $emit('deleteCommentPoint', data)
                "
                @sendComment="(data) => $emit('sendComment', data)"
                @updatepositions="(data) => $emit('updatepositions', data)"
                @updateMediaCommentPointLabel="
                  (data) => $emit('updateMediaCommentPointLabel', data)
                "
                @deleteMediaComment="
                  (data) => $emit('deleteMediaComment', data)
                "
                @updateComment="(data) => $emit('updateComment', data)"
              />
            </div>
          </div>
          <div
            style="margin-top:10px"
            v-if="!Array.isArray(message.reactiondata) && message.reactiondata"
          >
            <div class="row inline">
              <template v-for="(reactiondata, index) in message.reactiondata">
                <div :key="index" class="q-mr-xs">
                  <div class="relative-position reaction-icon row items-center">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="react-icon-size q-mr-xs cursor-pointer"
                      @click="
                        $emit('reactOnPost', { post: message, reaction: emoji })
                      "
                    />
                    <div class="text-black" style="font-size:12px">
                      {{ reactiondata.length }}
                    </div>
                  </div>
                  <q-tooltip
                    :offset="[-50, 0]"
                    content-class="bg-white elevation-2 q-pa-md"
                  >
                    <div class="row reaction-menu">
                      <img
                        :src="`/static/react-icons/${index}.svg`"
                        :alt="index"
                        class="q-mr-md react-menu-icon"
                      />
                      <q-list class="column justify-center" v-if="reactiondata">
                        <q-item
                          :style="
                            reactiondata.length === 1 && {
                              'margin-bottom': '0px',
                            }
                          "
                          class="q-pa-none q-mb-sm"
                          style="min-height:30px"
                          v-for="item in reactiondata"
                          :key="item.id"
                        >
                          <div class="row items-center ">
                            <div class="q-mr-sm">
                              <q-avatar
                                color="blue-grey-11"
                                text-color="grey-10"
                                size="26px"
                              >
                                <img
                                  :src="item.pic"
                                  :alt="item.first"
                                  v-if="item.pic"
                                />
                                <!-- <span
                                  class="text-avatar-style"
                                  style="font-size:9px"
                                  v-else
                                >
                                  {{ item.first | firstChar
                                  }}{{ item.last | firstChar }}
                                </span> -->
                                <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                              </q-avatar>
                            </div>
                            <div class="reacted-user">
                              {{ item.first }} {{ item.last }}
                            </div>
                            <div v-if="item.time" class="reaction-time q-ml-xs">
                              {{ item.time | formatDateTime }}
                            </div>
                          </div>
                        </q-item>
                      </q-list>
                    </div>
                  </q-tooltip>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="post-time q-mt-sm">
          {{ dateTimeFormat }}
          <span
            class="text-primary cursor-pointer q-ml-sm"
            style="font-size:16px;font-weight:500"
            @click="readMoreToggle = false"
            v-if="readMoreToggle"
          >
            read less
          </span>
        </div>
      </div>
      <div
        class="absolute thread-icons row items-center group-hover-item"
        style="right:0"
      >
        <q-btn
          flat
          :icon="$icons.matSentimentSatisfied"
          size="12px"
          class="thread-icon"
          rounded
          dense
        >
          <q-menu content-class="overflow-auto apply-width" auto-close>
            <div class="card react-emoji-card">
              <img
                v-for="(emoji, index) in reactions"
                :key="index"
                :src="'/static/react-icons/' + emoji.icon"
                alt="ANGRY"
                @click="
                  $emit('reactOnPost', { post: message, reaction: emoji })
                "
                class="react-emoji-icon q-mr-sm cursor-pointer"
              />
            </div>
          </q-menu>
          <q-tooltip>
            Add Reaction
          </q-tooltip>
        </q-btn>
        <div
          v-if="message.isbookmarked"
          @click="removeMessageBookmark(message)"
          class="cursor-pointer"
        >
          <q-icon size="xs" color="pink" :name="$icons.matBookmark">
            <q-tooltip>
              Remove from Saved items
            </q-tooltip>
          </q-icon>
        </div>
        <q-btn
          flat
          :icon="$icons.mdiDotsHorizontal"
          size="xs"
          class="thread-icon q-pa-xs"
          rounded
          dense
        >
          <q-tooltip>
            More
          </q-tooltip>
          <q-menu content-class="overflow-auto" auto-close>
            <q-list>
              <q-item
                clickable
                @click="gotoWorkspace('MessagingView', messageWorkspace.id)"
              >
                <q-item-section>
                  <q-item-label>Go to workspace</q-item-label>
                </q-item-section>
              </q-item>
              <q-item @click="$emit('sharePost', message)" clickable>
                <q-item-section>
                  <q-item-label>Share message</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
    <div v-if="delete_media.flag">
      <confirm-dialog
        v-if="delete_media.flag"
        v-model="delete_media.flag"
        title="Remove File?"
        question="Are you sure you want to detach the media from the post?"
        cancleText="Cancel"
        successText="Delete"
        :cancleMethod="() => removeMediaDialogHandler(false)"
        :successMethod="() => removeMediaDialogHandler(true)"
      />
    </div>
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="message.workspace_id"
      :user="user"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="taskLabels"
      :authToken="currentCompany.accessToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Remove Folder?"
      question="Are you sure you want to detach this folder from post?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash/get";

import { compile } from "@/components/Editor/MarkupUtils";
import { fromUnixTime } from "date-fns";
import UserScope from "@/mixins/UserScope";
import PostMedia from "@/components/Miscellaneous/PostMedia";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import Avatar from 'vue-avatar';

import {
  DetachMessageFolderMutation,
  DetachPostMediaMutation,
  RemoveMessageBookmarkMutation,
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
} from "@/gql";
export default {
  name: "SavedMessage",
  mixins: [UserScope],
  props: ["message", "reactions", "gotoWorkspace", "taskLabels", "editDoc"],
  components: {
    PostMedia,
    ConfirmDialog,
    FolderDetailsDialog,
    Avatar,
  },
  data() {
    return {
      delete_media: {
        id: null,
        post: null,
        flag: null,
      },
      readMoreToggle: false,
      deleteFolder: {
        id: null,
        post: null,
        flag: false,
        loader: false,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
    };
  },
  methods: {
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    async deleteFolderHandler(folderId, post) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.post = post;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            folder: [this.deleteFolder.id],
            post_id: this.deleteFolder.post.id,
          };
          await this.$api.mutate({
            mutation: DetachMessageFolderMutation,
            variables,
          });
          //Remove from Entity cache
          const postEntity = this.$api.getEntity(
            "post",
            this.deleteFolder.post.id
          );
          postEntity.mediafolder = postEntity.mediafolder.filter(
            (f) => f.id !== this.deleteFolder.id
          );
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.post = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.media.push(mediaEntity);
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.media = savedItemsQuery.data.savedItems.media.filter(
          (item) => item.id != mediaEntity.id
        );
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    timeDifference(current, previous) {
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;
      if (elapsed < msPerMinute) {
        return "just now";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " m";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " h";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " d";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " mo";
      } else {
        return Math.round(elapsed / msPerYear) + " y";
      }
    },
    removeMedia(postId, mediaId) {
      this.delete_media.post = postId;
      this.delete_media.id = mediaId;
      this.delete_media.flag = true;
    },
    async removeMediaDialogHandler(flag = false) {
      if (flag) {
        const variables = {
          post_id: this.delete_media.post,
          media: [this.delete_media.id],
        };

        await this.$api.mutate({
          mutation: DetachPostMediaMutation,
          variables,
        });
      }
      this.delete_media.flag = false;
      this.delete_media.post = null;
      this.delete_media.id = null;
    },
    trucateContet(content) {
      if (!content) return "";
      return content.substring(0, 165) + "...";
    },
    async removeMessageBookmark() {
      try {
        const variables = {
          post_id: this.message.id,
        };
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.post = savedItemsQuery.data.savedItems.post.filter(
          (item) => item.id != this.message.id
        );
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.messageWorkspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveMessageBookmarkMutation,
          variables,
          skipUpdate: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    PostHtml() {
      return compile(this.message.content);
    },
    messageWorkspace() {
      const workspace = this.$api.getEntity(
        "workspace",
        this.message.workspace_id
      );
      return workspace;
    },
    dateTimeFormat() {
      return this.timeDifference(
        new Date(),
        fromUnixTime(parseInt(this.message.created_at))
      );
    },
    isWorkspaceOwner() {
      if (this.messageWorkspace && this.user) {
        return this.user.id === this.messageWorkspace.owner.id;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-message {
  .workspace-name {
    color: #677484;
    font-size: 12px;
  }
  .user-name {
    font-size: 15px;
    font-weight: 600;
  }
  .post-content {
    color: #1d2b3b;
  }
  .post-time {
    color: #a1aabc;
    font-size: 13px;
  }
  p {
    &:first-child {
      margin: 0 0 0 0;
    }
    &:not(:first-child) {
      margin: 0;
    }
  }
}
</style>
