import { compact, concat, uniq } from "lodash";
import { Workspaces2Query, InvitePeopleMutation, GetUserMutation } from "@/gql";
class ServiceProvider {
  async getAllWorkspaces(api) {
    const result = await api.query({
      query: Workspaces2Query
    });

    if (result) {
      const workspaces = compact(
        concat(result.data.workspaces.public, result.data.workspaces.private)
      );
      return workspaces;
    } else {
      return null;
    }
  }

  async sendInvitation(emailList = [], workspaceList = [], captchaToken, api) {
    const emails = uniq(compact(emailList));
    const workspaceIds = uniq(compact(workspaceList));

    if (
      !emails ||
      emails.length <= 0 ||
      !workspaceIds ||
      workspaceIds.length <= 0
    ) {
      return false;
    }

    const variables = {
      emails,
      workspace_ids: workspaceIds,
      g_recaptcha_response: captchaToken
    };

    const response = await api.mutate({
      mutation: InvitePeopleMutation,
      variables
    });

    if (!response.data.invitePeople) {
      return false;
    } else {
      return true;
    }
  }
  
  // Latest method
  async sendInvitationToUser(emailList = [], workspaceList = [], captchaToken, api) {
    const emails = uniq(compact(emailList));
    const workspaceIds = uniq(compact(workspaceList));

    if (
      !emails ||
      emails.length <= 0 ||
      !workspaceIds ||
      workspaceIds.length <= 0
    ) {
      return false;
    }

    const variables = {
      emails,
      workspace_ids: workspaceIds,
      g_recaptcha_response: captchaToken
    };

    return await api.mutate({
      mutation: InvitePeopleMutation,
      variables
    });
  }

  async getUserDetails(userid, api) {
    const variables = {
      user_id: userid
    };

    const result = await api.mutate({
      mutation: GetUserMutation,
      variables
    });

    if (result) {
      return result.data.getUser;
    } else {
      return null;
    }
  }
}

export default new ServiceProvider();
