<template>
  <tr
    class="task-list-item task-timer row flex-no-wrap items-center group-hover relative-position q-mb-sm"
  >
    <td
      class="row task-column-item items-center justify-center"
      style="min-width: 50px;max-width:135px"
      v-if="!task.completed"
    >
      <q-circular-progress
        indeterminate
        size="sm"
        :thickness="0.2"
        color="primary"
        v-if="loading"
      />
      <div v-else class="q-px-md">
        <timer
          :startTime="activeTimelog.start_date"
          v-if="activeTimelog && user.id === activeTimelog.owner.id"
          :stopTimer="stopTimerHandler"
          :cardTask="task"
          :deleteTaskTimeLogs="deleteTaskTimeLogs"
          @openTimelogDialog="timelogs.flag = true"
          :hideEditButton="true"
        />
        <div
          v-if="!activeTimelog && !totalWorkTime"
          @click="createTimer()"
          class="timer-start-icon row justify-center items-center cursor-pointer"
        >
          <q-icon size="15px" color="primary" :name="$icons.matPlayArrow" />
        </div>
        <div
          @click="createTimer()"
          class="total-time row items-center flex-no-wrap cursor-pointer relative-position"
          v-if="totalWorkTime && !activeTimelog"
        >
          <q-icon :name="$icons.matPlayCircleFilled" size="20px" />
          <span
            class="q-ml-sm time-text row items-center"
            style="margin-top:1px;"
            v-if="totalWorkTime"
            >{{ totalWorkTime }}</span
          >
          <div class="time-log-edit-delete row items-center">
            <div
              class="cursor-pointer edit-icon"
              @click.stop="timelogs.flag = true"
            >
              <q-icon size="16px" class="q-ml-xs" :name="$icons.matEdit" />
              <q-tooltip>Edit Time Report</q-tooltip>
            </div>
            <div
              class="delete-icon row items-center"
              clickable
              @click.stop="deleteTaskTimeLogs"
            >
              <q-icon size="16px" class="q-mr-xs" :name="$icons.matDelete" />
              <q-tooltip>Delete Time Report</q-tooltip>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td
      :class="['row items-center q-pl-sm task-column-item-titel']"
      style="min-width:215px;width: 100%;"
    >
      <div style="z-index:1" class="q-mr-sm">
        <div
          @click="changeCompleteStatus"
          v-if="task.completed"
          class="cursor-pointer "
          style="min-width:40px;"
        >
          <div class="row items-centern justify-center flex-no-wrap">
            <div class="task-icon relative-position">
              <q-icon
                class="absolute text-bold"
                :name="$icons.fasCheck"
                style="color:#15d89a"
                size="15px"
              />
            </div>
          </div>
          <q-tooltip>
            Mark Task InComplete
          </q-tooltip>
        </div>
        <div
          @click="changeCompleteStatus"
          v-else
          class="cursor-pointer notification-sidebar-outline-dot "
        >
          <q-tooltip>
            Mark Task Complete
          </q-tooltip>
        </div>
      </div>
      <div
        class="cursor-pointer ellipses q-mr-xs"
        style="max-width: 435px;"
        @click="edit(task, 'taskComment')"
        :class="[task.completed && 'strike']"
      >
        <span class="dont-break-out an-15 medium-text">
          {{ task.title }}
        </span>
      </div>
      <span v-if="hasNotifications.length">
        <q-icon
          color="pink"
          size="8px"
          :name="$icons.matFiberManualRecord"
          style="margin-top:-5px"
        />
      </span>
    </td>
    <div
      class="task-menu-option thread-icons row items-center q-px-xs group-hover-item q-mr-sm"
      style="right:0px"
    >
      <div
        v-if="task.isbookmarked"
        @click="removeTaskBookmark(task)"
        class="cursor-pointer q-mr-xs"
      >
        <q-icon size="xs" color="pink" :name="$icons.matBookmark">
          <q-tooltip>
            Remove from Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <q-btn
        flat
        size="sm"
        round
        color="black"
        dense
        padding="0"
        style="margin-top: -1px;"
        :icon="$icons.matMoreHoriz"
      >
        <q-menu auto-close content-class="menu-hover overflow-auto q-pa-sm">
          <q-list>
            <q-item
              @click="gotoWorkspace('BoardView', taskWorkspace.id)"
              clickable
            >
              <q-item-section>Go to workspace</q-item-section>
            </q-item>
            <q-item @click="changeCompleteStatus" clickable>
              <q-item-section v-if="!task.completed"
                >Complete task</q-item-section
              >
              <q-item-section v-else>Mark as incomplete</q-item-section>
            </q-item>
            <q-item @click="edit(task, 'taskComment')" clickable>
              <q-item-section>Edit task</q-item-section>
            </q-item>
            <q-item @click="$emit('delete', task)" class="text-red" clickable>
              <q-item-section>Delete task</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <time-log-dialog
      v-if="timelogs.flag"
      v-model="timelogs.flag"
      :timelogs="task.timelogs"
      :board="board"
      :taskObj="task"
      :workspace="taskWorkspace"
      :closeDialog="closeTimeLogDialog"
      :isAddTaskTime="true"
    />
    <stop-timer-confirmation-dialog
      v-if="stopTimerConfirmation.flag"
      v-model="stopTimerConfirmation.flag"
      :timelogId="stopTimerConfirmation.id"
      :stopTimer="stopTimer"
      @closeDialog="
        stopTimerConfirmation.flag = false;
        stopTimerConfirmation.id = null;
      "
    />
    <start-new-timer-dialog
      v-if="startNewTimer.flag"
      v-model="startNewTimer.flag"
      :activeTaskId="startNewTimer.activeTaskId"
      :createTimer="createTimer"
      :currentCompany="currentCompany"
      @closeDialog="
        startNewTimer.flag = false;
        startNewTimer.activeTaskId = null;
      "
    />
  </tr>
</template>

<script>
const soundFile = require("@/assets/completeTaskSound.wav");

import { fromUnixTime, getUnixTime } from "date-fns";

import TimeLogDialog from "@/components/Dialogs/TimeLogDialog";
import Timer from "@/components/Timer";
import StopTimerConfirmationDialog from "@/components/Dialogs/StopTimerConfirmationDialog";
import StartNewTimerDialog from "@/components/Dialogs/StartNewTimerDialog";
import {
  CreateTaskTimeLogMutation,
  UpdateCompanySettingsMutation,
  DeleteTaskTimeLogsMutation,
  UpdateTaskMutation,
  RemoveTaskBookmarkMutation,
  UpdateTaskTimeLogMutation,
} from "@/gql";

export default {
  name: "SavedTask",
  props: ["task", "editTaskDialogHandler", "currentCompany", "gotoWorkspace"],
  components: {
    TimeLogDialog,
    Timer,
    StopTimerConfirmationDialog,
    StartNewTimerDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      sound: null,
      loading: false,
      timelogs: {
        flag: false,
      },
      startNewTimer: {
        flag: false,
        activeTaskId: null,
      },
      stopTimerConfirmation: {
        flag: false,
        id: null,
      },
    };
  },
  mounted() {
    this.sound = new Audio(soundFile);
    if (this.task.activetimelog) {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = this.task.id;
      companyEntity.start_time_log_id = this.task.activetimelog.id;
    }
  },
  methods: {
    closeTimeLogDialog() {
      this.timelogs.flag = false;
    },
    edit(task, isOpen = null) {
      const obj = {
        task,
        isOpen,
      };
      this.editTaskDialogHandler(obj);
    },
    async changeCompleteStatus() {
      this.sound.currentTime = 0;
      this.sound.play();
      const variables = {
        id: this.task.id,
        completed: !this.task.completed,
      };
      this.task.completed = !this.task.completed;
      try {
        await this.$api.mutate({
          mutation: UpdateTaskMutation,
          variables,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    stopTimerHandler(data) {
      this.stopTimerConfirmation.flag = true;
      this.stopTimerConfirmation.id = data.id;
    },
    async stopTimer(timelogId) {
      const variables = {
        end_date: getUnixTime(new Date()),
        id: timelogId,
        is_start: false,
      };
      const newTaskTimeLog = await this.$api.mutate({
        mutation: UpdateTaskTimeLogMutation,
        variables,
      });

      this.task.activetimelog = null;
      const taskEntity = this.$api.getEntity("task", this.task.id);

      let objIndex = taskEntity.timelogs.findIndex(
        (obj) => obj.id == timelogId
      );
      taskEntity.timelogs[objIndex] = newTaskTimeLog.data.updateTaskTimeLog;

      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = null;
      companyEntity.start_time_log_id = null;
      const companyVariables = {
        name: companyEntity.name,
        start_task_id: null,
        start_time_log_id: null,
      };
      await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables: companyVariables,
      });
    },
    timeCaclulate(startDate, endDate) {
      let dateFuture = fromUnixTime(endDate);
      let dateNow = fromUnixTime(startDate);

      return Math.floor((dateFuture - dateNow) / 1000);
    },
    convertIntoTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      //seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

      return days * 24 + hours + "h " + minutes + "m";
    },
    async createTimer() {
      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      if (!companyEntity.start_task_id && !companyEntity.start_time_log_id) {
        this.loading = true;
        const variables = {
          start_date: getUnixTime(new Date()),
          end_date: getUnixTime(new Date()),
          task_id: this.task.id,
          workspace_id: this.taskWorkspace.id,
          is_start: true,
        };
        let response = await this.$api.mutate({
          mutation: CreateTaskTimeLogMutation,
          variables,
        });

        const taskEntity = this.$api.getEntity("task", this.task.id);
        taskEntity.timelogs.push(response.data.createTaskTimeLog);
        this.taskWorkspace.timelogs.push(response.data.createTaskTimeLog);
        companyEntity.start_task_id = this.task.id;
        companyEntity.start_time_log_id = response.data.createTaskTimeLog.id;

        this.task.activetimelog = response.data.createTaskTimeLog;
        this.loading = false;
        const companyVariables = {
          name: companyEntity.name,
          start_task_id: this.task.id,
          start_time_log_id: response.data.createTaskTimeLog.id,
        };
        await this.$api.mutate({
          mutation: UpdateCompanySettingsMutation,
          variables: companyVariables,
        });
        this.$mixpanelEvent("track-time", companyVariables);
      } else {
        this.startNewTimer.flag = true;
        this.startNewTimer.activeTaskId = companyEntity.start_task_id;
      }
    },
    async deleteTaskTimeLogs() {
      const variables = {
        workspace_id: this.taskWorkspace.id,
        task_id: this.task.id,
        user_id: this.user.id,
      };
      const taskEntity = this.$api.getEntity("task", this.task.id);

      taskEntity.timelogs = [];
      await this.$api.mutate({
        mutation: DeleteTaskTimeLogsMutation,
        variables,
      });
    },
    async removeTaskBookmark() {
      try {
        const variables = {
          task_id: this.task.id,
        };

        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.task = savedItemsQuery.data.savedItems.task.filter(
          (item) => item.id != this.task.id
        );
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.taskWorkspace.id
        );
        workspaceEntity.hasbookmarked = --workspaceEntity.hasbookmarked;
        await this.$api.mutate({
          mutation: RemoveTaskBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.taskWorkspace.id
        );
        workspaceEntity.hasbookmarked = ++workspaceEntity.hasbookmarked;
        throw new Error(error);
      }
    },
  },
  computed: {
    hasNotifications() {
      const query = this.$api.getQuery("NotificationsQuery");
      if (query.data && this.task) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
            n.action.object && ((n.action.type === "task-comment-created" &&
              n.action.object.task_id === this.task.id) ||
              (n.action.type === "subtask-member-attached" &&
                n.action.object.task_id === this.task.id) ||
              (n.action.type === "task-member-attached" &&
                n.action.object_id === this.task.id))
          );
        return notifications;
      }
      return [];
    },
    totalWorkTime() {
      let taskTotalTime;
      if (this.task.timelogs?.length) {
        let timeInSecond = 0;
        this.task.timelogs.forEach((timelog) => {
          timeInSecond += this.timeCaclulate(
            timelog.start_date,
            timelog.end_date
          );
        });
        taskTotalTime = this.convertIntoTime(timeInSecond);
        return taskTotalTime;
      }
      return null;
    },
    activeTimelog() {
      return this.task.activetimelog;
    },
    board() {
      const board = this.$api.getEntity("board", this.task.board_id);
      return board;
    },
    taskWorkspace() {
      if (this.task.board_id) {
        const board = this.$api.getEntity("board", this.task.board_id);
        const workspace = this.$api.getEntity("workspace", board.workspace_id);
        return workspace;
      } else {
        const card = this.$api.getEntity("card", this.task.card_id);
        const board = this.$api.getEntity("board", card.board_id);
        const workspace = this.$api.getEntity("workspace", board.workspace_id);
        return workspace;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.task-icon {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d5f7ec;
}
</style>
