var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1"},[_c('div',[_c('q-btn',{staticClass:"control-button",class:_vm.menu && 'active',style:({
        position: 'absolute',
        top: '17px',
        height: '29px',
        width: '86px',
        left: _vm.mediaExpansion ? '134px' : '150px',
      }),attrs:{"dense":"","flat":"","color":"grey-10","size":"md","padding":"0","no-caps":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around","width":"100%"}},[_c('span',[_vm._v("Add")]),_c('div',{staticClass:"icon"},[(_vm.menu)?_c('q-icon',{attrs:{"name":_vm.$icons.matKeyboardArrowUp,"size":"xs"}}):_c('q-icon',{attrs:{"name":_vm.$icons.matKeyboardArrowDown,"size":"xs"}})],1)]),(!_vm.menu)?_c('q-tooltip',[_vm._v("Add attachments")]):_vm._e(),_c('q-menu',{attrs:{"auto-close":""},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('q-list',[_c('q-item',{attrs:{"clickable":""},on:{"click":_vm.fromComputer}},[_c('q-item-section',{staticClass:"q-pr-none",staticStyle:{"min-width":"35px"},attrs:{"avatar":""}},[_c('q-icon',{attrs:{"name":_vm.$icons.matComputer,"size":"24px"}})],1),_c('q-item-section',[_vm._v(" Your Computer ")])],1),_c('q-item',{attrs:{"clickable":""},on:{"click":function($event){_vm.sharedFileDialog = true}}},[_c('q-item-section',{staticClass:"q-pr-none",staticStyle:{"min-width":"35px"},attrs:{"avatar":""}},[_c('q-icon',{attrs:{"name":_vm.$icons.fasCopy,"size":"24px"}})],1),_c('q-item-section',[_vm._v(" Shared Files ")])],1),_c('q-separator')],1)],1)],1)],1),_c('vue-dropzone-file-upload',{ref:"vueFileUploader",attrs:{"hidden":"","workspace":_vm.currentWorkspace,"isWorkspaceOwner":_vm.isWorkspaceOwner,"authToken":_vm.authToken,"fromTaskAttachment":true},on:{"close":_vm.updateMediaCache,"setProgress":_vm.setProgress,"updateUploadCount":(total, uploaded) => {
        _vm.uploadCount = uploaded;
        _vm.totalFile = total;
      }}}),(_vm.progress > 0)?_c('div',{staticClass:"q-mb-sm",staticStyle:{"display":"flex","justify-content":"center"}},[_c('q-linear-progress',{staticClass:"ma-0 progress",attrs:{"color":"green","indeterminate":_vm.query,"size":"20px","value":_vm.progress,"dark":"","rounded":""}},[_c('div',{staticClass:"absolute-full flex label",staticStyle:{"margin-left":"10px"}},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.progress < 1 && _vm.uploadCount && _vm.totalFile? `${_vm.uploadCount} of ${_vm.totalFile - 1} files uploaded` : 'Uploaded')+" "),_c('span',[_vm._v(_vm._s(Math.round(_vm.progress * 100) + "%"))])])])])])],1):_vm._e(),(_vm.sharedFileDialog)?_c('shared-files-dialog',{attrs:{"imageList":_vm.imageList,"attachMediaToPost":(data) => _vm.addSharedFileToUploader(data, 'fromTaskAttachment'),"currentWorkspaceId":_vm.currentWorkspace},on:{"closeDialog":function($event){_vm.sharedFileDialog = false}},model:{value:(_vm.sharedFileDialog),callback:function ($$v) {_vm.sharedFileDialog=$$v},expression:"sharedFileDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }