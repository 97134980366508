<template>
  <q-dialog transition-hide="none" transition-show="none" v-model="proxyModel">
    <div
      class="relative-position	bg-white"
      style="min-width:370px;"
      v-if="userinfo"
    >
      <div class="text-center profile-gradient" style="height: 228px">
        <q-avatar
          size="150px"
          class="bg-grey-6"
          style="margin-top:80px"
        >
          <img
            :src="userinfo.pic"
            alt="avatar"
            style="border: 3px solid #FFF"
            v-if="userinfo && userinfo.pic"
          />
          <!-- <span class="text-grey-9 an-40 demi-bold-text" v-else
            >{{ userinfo.first | firstChar
            }}{{ userinfo.last | firstChar }}</span
          > -->
          <avatar v-else :size="150" :customStyle="{'font-size':'40px'}" :username="userinfo.first+' '+userinfo.last"></avatar>
        </q-avatar>
      </div>
      <div class="profile-style">
        <div class="text-center">
          <h2 class="username q-mt-xs">
            {{ userinfo.first | capitalize }} {{ userinfo.last | capitalize }}
          </h2>
          <h3 class="user-designation">{{ userinfo.designation }}</h3>
          <p :class="[userinfo.designation ? '' : 'q-mt-sm', 'user-connect']">
            @{{ userinfo.username }}
          </p>
        </div>
        <div class="user-details-list q-px-lg">
          <div
            class="row q-py-sm"
            v-if="userinfo && (userinfo.city || userinfo.country)"
          >
            <q-icon :name="$icons.matLocationOn" size="24px" color="grey-7">
              <q-tooltip> Location </q-tooltip>
            </q-icon>
            <div class="detail-content q-pl-sm">
              {{ userinfo.city }}, {{ userinfo.country }}
            </div>
          </div>
          <!--  -->
          <div class="row q-py-sm">
            <q-icon :name="$icons.matAccessTime" size="24px" color="grey-7">
              <q-tooltip>
                Local Time
              </q-tooltip>
            </q-icon>
            <div class="detail-content q-pl-sm">{{ localTime }}</div>
          </div>
          <!--  -->
          <div class="row q-py-sm">
            <q-icon :name="$icons.matEmail" size="24px" color="grey-7">
              <q-tooltip>
                Email
              </q-tooltip>
            </q-icon>
            <div class="detail-content q-pl-sm">
              <a class="break-word" :href="`mailto:${userinfo.email}`">{{
                userinfo.email
              }}</a>
            </div>
          </div>
          <!--  -->
          <div class="row q-py-sm" v-if="userinfo && userinfo.phone">
            <q-icon :name="$icons.matPhone" size="24px" color="grey-7">
              <q-tooltip>
                Phone
              </q-tooltip>
            </q-icon>
            <div class="detail-content q-pl-sm">
              {{ userinfo.phone | formatPhoneNumber }}
            </div>
          </div>
          <!--  -->
          <div class="row q-py-sm" v-if="userinfo && userinfo.skype">
            <q-icon :name="$icons.fabSkype" size="24px" color="grey-7">
              <q-tooltip>
                Skype
              </q-tooltip>
            </q-icon>
            <div class="detail-content q-pl-sm">{{ userinfo.skype }}</div>
          </div>
        </div>
        <div class="bg-grey-3 q-pa-md" v-if="!isSelf">
          <div class="relative-position full-width">
            <q-input
              :placeholder="'Message ' + userinfo.first"
              borderless
              standout
              input-class="q-pl-sm text-grey-10 rounded-borders bg-white"
              input-style="height:50px"
              v-model="content"
              @keyup.enter="createPost"
            />
            <q-btn
              dense
              class="absolute q-py-xs"
              color="teal-14"
              text-color="white"
              :icon="$icons.matSend"
              size="18px"
              style="top:1px; right:0px; min-width:50px"
              @click="createPost"
              :loading="loading"
              :disabled="
                loading || this.content === null || this.content === ''
              "
            />
          </div>
        </div>
      </div>
      <q-btn
        flat
        dense
        round
        :icon="$icons.matClose"
        class="absolute"
        color="grey-5"
        style="top: 5px;right:5px"
        @click="proxyModel = false"
      />
    </div>
  </q-dialog>
</template>

<script>
import format from "date-fns/format";
import serviceProvider from "@/services/ServiceProvider";
import errorHandler from "@/utils/ErrorHandler";
import ProxyModelMixin from "@/mixins/ProxyModelMixin";
import mixpanel from "@/mixpanel";
import Avatar from 'vue-avatar'

import {
  Workspaces2Query,
  CreatePostMutation,
  CreatePeopleWorkspaceMutation,
} from "@/gql";

export default {
  name: "ProfileDialog",
  mixins: [ProxyModelMixin],
  props: ["recepient", "user", "currentWorkspaceId"],
  data() {
    return {
      userinfo: null,
      currentDate: null,
      content: null,
      newWorkspace: null,
      loading: false,
      id: null,
    };
  },
  components:{
    Avatar,
  },
  mounted() {
    this.currentDate = new Date();
    clearInterval(this.id);
    this.id = setInterval(this.updateDateTime, 1000);
  },
  created() {
    if (this.recepient) this.showUserInfo();
  },
  beforeDestroy() {
    clearInterval(this.id);
  },
  methods: {
    updateDateTime() {
      this.currentDate = new Date();
    },
    showUserInfo() {
      serviceProvider
        .getUserDetails(this.recepient.id, this.$api)
        .then((result) => {
          if (result) {
            this.userinfo = result;
          }
        });
    },
    clearUserInfoDialog() {
      this.userinfo = null;
      this.$emit("close");
    },
    async createPost() {
      let workspaceId = this.workspaceId;

      if (!this.workspaceId) {
        await this.create(this.recepient.id);
        workspaceId = this.newWorkspace.id;
      }

      const variables = {
        workspace_id: workspaceId,
        content: this.content,
        media: [],
      };

      const response = await this.$api.mutate({
        mutation: CreatePostMutation,
        variables,
      });
      mixpanel.track("Message Create", { from_profile: true });
      this.$api.query({
        query: Workspaces2Query,
        cacheKey: "Workspaces2Query",
      });

      if (response) {
        this.$nextTick(() => {
          this.redirection(workspaceId);
        });
      }
    },
    redirection(workspaceId) {
      if (this.currentWorkspaceId !== workspaceId) {
        this.$router.push({
          name: "MessagingView",
          params: {
            company: this.$route.params.company,
            workspace: workspaceId,
          },
        });
      }
      this.clearUserInfoDialog();
    },
    async create(member) {
      this.loader = true;
      let variables = {
        user_id: member,
      };

      try {
        const response = await this.$api.mutate({
          mutation: CreatePeopleWorkspaceMutation,
          variables,
        });

        this.$api.query({
          query: Workspaces2Query,
          cacheKey: "Workspaces2Query",
        });

        if (response) {
          const newWorkspace = response.data.createPeopleWorkspace;
          this.$emit("success", newWorkspace.id);
          this.newWorkspace = newWorkspace;
        }
        this.loader = false;
      } catch (error) {
        this.loader = false;

        this.errorMessage = {
          flag: true,
          text: errorHandler.getErrorMessage(
            error,
            this,
            "message.InvalidWorkspaceTitleError"
          ),
        };
      }
    },
  },
  computed: {
    localTime() {
      return format(this.currentDate, "pp");
    },
    isSelf() {
      return this.userinfo.id === this.user.id;
    },
    workspaceId() {
      if (!this.isSelf && this.userinfo.people) {
        return this.userinfo.people;
      } else {
        return false;
      }
    },
  },
};
</script>
