<template>
  <div class="saved-items-wrapper q-pt-md relative-position">
    <div v-if="isBannerVisible" class="saved-items-banner relative-position">
      <div class="row flex-no-wrap">
        <div>
          <q-icon style="color: #ffa21e" size="sm" :name="$icons.matBookmark" />
        </div>
        <div class="saved-items-video relative-position">
          <div
            @click="videoPlayer"
            class="saved-items-video-icon cursor-pointer"
          >
            <q-icon size="lg" color="primary" :name="$icons.matPlayArrow" />
          </div>
        </div>
        <div style="font-size: 16px">
          <div class="text-bold">
            Save messages, tasks and files to reference later
          </div>
          <div>
            You can save messages, tasks and files across workspaces to come
            back to later. Only you can see your saved items.
          </div>
          <div
            style="width: 100px; height: 32px; margin-top: 10px"
            class="d-flex"
          >
            <!-- <q-btn
              dense
              no-caps
              class="primary-button"
              color="#15d89a"
              size="md"
              style=""
              label="Learn more"
              @click="$router.push({ name: 'LearnView' })"
            /> -->
            <q-btn
              dense
              no-caps
              style="margin-left: 10px; height: 32px; border: solid 1px #677484"
              size="md"
              label="Dismiss"
              @click="removeBanner"
            />
          </div>
        </div>
      </div>
      <div class="close-icon cursor-pointer">
        <q-btn
          :icon="$icons.matClose"
          text-color="blue-grey-8"
          round
          flat
          padding="0"
          dense
          @click="removeBanner"
        />
      </div>
    </div>
    <!-- <div
      @click="displayBanner"
      v-if="!isBannerVisible"
      class="help cursor-pointer"
    >
      <q-icon :name="$icons.mdiHelp" size="25px" style="color:#e75279" />
    </div> -->
    <div
      @click.stop="$emit('closeNotificaiton')"
      class="row flex-no-wrap overflow-auto q-mt-lg"
      :style="
        isBannerVisible
          ? { height: 'calc(100vh - 236px)' }
          : { height: 'calc(100vh - 84px)' }
      "
    >
      <saved-messages
        :savedMessages="savedMessages"
        :isBannerVisible="isBannerVisible"
        :gotoWorkspace="gotoWorkspace"
        :editDoc="editDoc"
        class="q-mr-lg"
      />
      <saved-tasks
        :isBannerVisible="isBannerVisible"
        :savedTasks="savedTasks"
        :gotoWorkspace="gotoWorkspace"
      />
      <saved-files
        :isBannerVisible="isBannerVisible"
        :savedFiles="savedFiles"
        :savedFolders="savedFolders"
        :gotoWorkspace="gotoWorkspace"
        :editDoc="editDoc"
        :openDocEditor="openDocEditor"
      />
    </div>
    <video-dialog
      v-model="showVideoModel"
      v-if="showVideoModel"
      :resetVideoDialog="resetVideoDialog"
      :videoMediaData="videoMediaData"
      :displayPlayer="displayPlayer"
    />
    <document-editor-dialog
      v-if="documentDialog"
      v-model="documentDialog"
      :openDocEditor="openDocEditor"
      :closeDocEditor="closeDocEditor"
      :documentProp="createdDoc"
      :setActiveDocument="setActiveDocument"
      :activeDocumentId="activeDocumentId"
      :hideDocumentSidebar="true"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import orderBy from "lodash/orderBy";
import DocumentEditorMixin from "@/mixins/DocumentEditorMixin";

import SavedFiles from "@/views/SavedItemsView/SavedFiles";
import SavedMessages from "@/views/SavedItemsView/SavedMessages";
import SavedTasks from "@/views/SavedItemsView/SavedTasks";
import VideoDialog from "@/components/VuePlayer/VideoDialog";
import DocumentEditorDialog from "@/components/Dialogs/DocumentEditorDialog";

import { SavedItemsQuery, UserQuery } from "@/gql";
export default {
  name: "SavedItemsView",
  mixins: [DocumentEditorMixin],
  api: {
    savedItems: {
      query: SavedItemsQuery,
      cacheKey: "SavedItemsQuery",
      defaultValue: null,
    },
    user: {
      query: UserQuery,
      cacheKey: "UserQuery",
      defaultValue: null,
    },
  },
  components: {
    SavedMessages,
    SavedTasks,
    SavedFiles,
    VideoDialog,
    DocumentEditorDialog,
  },
  props: ["drawerRight", "toggleDrawerRight"],
  data() {
    return {
      showVideoModel: false,
      videoMediaData: null,
      displayPlayer: false,
    };
  },
  mounted() {
    let eligible = this.$checkEligiblity("saved_items");
    if (!eligible) {
      this.$router.push({
        name: "LearnView",
      });
    }
  },
  methods: {
    removeBanner() {
      this.$store.dispatch("toggleSavedItemsBanner", false);
    },
    gotoWorkspace(name, workspaceId) {
      this.$router.push({
        name,
        params: {
          company: this.$route.params.company,
          workspace: workspaceId,
        },
      });
    },
    videoPlayer() {
      this.videoMediaData = {
        file: "https://player.vimeo.com/video/565101982?title=0&byline=0&portrait=0",
        isVimeo: true,
      };
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      isBannerVisible: "isBannerVisible",
    }),
    savedMessages() {
      return (
        this.savedItems &&
        orderBy(this.savedItems.post, ["bookmarkdate"], ["desc"])
      );
    },
    savedTasks() {
        return this.savedItems && orderBy(orderBy(this.savedItems.task, ["bookmarkdate"], ["desc"]), [
          "completed",
        ]).filter((c) => ((c.owner.id === this.user.id && c.is_invisible) || !c.is_invisible));
    },
    savedFiles() {
      return (
        this.savedItems &&
        orderBy(this.savedItems.media, ["bookmarkdate"], ["desc"])
      );
    },
    savedFolders() {
      return (
        this.savedItems &&
        orderBy(this.savedItems.media_folder, ["bookmarkdate"], ["desc"])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.saved-items-wrapper {
  padding-left: 34px;
  padding-right: 14px;
  background: #f9fafc;
  height: calc(100vh - 44px);
}
.saved-items-banner {
  background-color: #fff;
  border-radius: 11px;
  min-height: 89px;
  padding: 16px 17px 22px 23px;
  .saved-items-video {
    width: 171px;
    height: 96px;
    border-radius: 4.6px;
    min-width: 171px;
    background-color: #eff8ff;
    margin-left: 21px;
    margin-right: 31px;
    .saved-items-video-icon {
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
</style>
