<template>
  <div
    class="relative-position media-list-folder edtor-media-upload-attachment group-hover q-px-none task-attachment-block items-center q-mr-sm q-mb-sm q-pr-sm row flex-no-wrap group-hover-media cursor-pointer"
    @click="openFolder(folder)"
  >
    <div class="row items-center full-width cursor-pointer">
      <div class="relative-position" style="height:40px">
        <q-avatar size="40px" rounded>
          <q-icon
            class="media-grid-folder-icon"
            size="40px"
            :name="$icons.matFolder"
          />
          <div class="media-grid-folder-count">
            {{ folder.media_count > 9 ? "9+" : folder.media_count }}
          </div>
        </q-avatar>
      </div>
      <div style="margin-left:12px" v-tooltip>
        {{ folder.title }}
      </div>
    </div>
    <div
      class="group-hover-item task-attachment-menu row items-center flex-no-wrap q-px-sm"
    >
     <div>
        <q-item @click.stop="confirm = true" clickable style="padding:6px">
          <q-icon  
            size="13px"                      
            class="text-red"
            :name="$icons.fasTrash"
          >
           </q-icon>
        </q-item>
      </div>
      <q-btn
        dense
        flat
        round
        color="grey-10"
        padding="0"
        :icon="$icons.matMoreVert"
        size="sm"
        class="three-dot-menu"
        @click.stop
      >
        <q-menu content-class="overflow-auto" auto-close>
          <q-list>
            <q-item
              @click="copyMoveFileFolderHandler([folder], true, true)"
              clickable
            >
              <q-item-section> Copy </q-item-section>
            </q-item>
            <q-item
              clickable
              class="q-mb-sm"
              @click="$emit('renameFolder', folder)"
              v-close-popup
            >
              <q-item-section>
                Rename
              </q-item-section>
            </q-item>
            <q-item @click="confirm = true" class="text-red" clickable>
              <q-item-section>
                Remove
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <confirm-dialog
      v-model="confirm"
      title="Remove Folder from Attachments?"
      question="Are you sure you want to delete this folder?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => (confirm = false)"
      :successMethod="
        () => detachAttachment({ taskId: task.id, folderId: folder.id })
      "
      :loading="false"
    />
  </div>
</template>

<script>
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { DetachTaskMediaFolderMutation } from "@/gql";
export default {
  props: ["folder", "task", "openFolder", "copyMoveFileFolderHandler"],
  data() {
    return {
      confirm: false,
    };
  },
  components: {
    ConfirmDialog,
  },
  methods: {
    async detachAttachment(args) {
      const variables = {
        task_id: args.taskId,
        folder: [args.folderId],
      };
      await this.$api.mutate({
        mutation: DetachTaskMediaFolderMutation,
        variables,
      });

      const taskEntity = this.$api.getEntity("task", args.taskId);
      taskEntity.mediafolder = taskEntity.mediafolder.filter(
        (f) => f.id !== args.folderId
      );
      this.confirm = false;
    },
  },
};
</script>
