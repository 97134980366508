var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-menu',{attrs:{"persistent":"","value":_vm.value,"max-height":"auto"}},[_c('div',{staticClass:"q-px-md"},[_c('div',{staticClass:"custom-header"},[_c('q-btn',{staticClass:"help-button",staticStyle:{"margin-top":"5px"},attrs:{"icon":_vm.$icons.mdiChevronLeft,"round":"","size":"sm"},on:{"click":function($event){return _vm.$emit('backToCFList')}}}),_c('div',{staticClass:"q-py-sm app-text"},[_vm._v(" Edit field ")]),_c('div',{staticClass:"new-close-button cursor-pointer",on:{"click":function($event){return _vm.$emit('closeMenu')}}},[_c('q-icon',{staticClass:"close-icon",attrs:{"name":_vm.$icons.matClose}})],1)],1),_c('div',{staticClass:"q-mb-sm"},[_c('label',{staticClass:"app-text-normal tag-title"},[_vm._v("Title")]),_c('q-input',{staticClass:"field-input app-text-normal",attrs:{"placeholder":"edit field","dense":"","borderless":"","type":"text"},on:{"input":_vm.debounceFunction},model:{value:(_vm.selectedForEdit.title),callback:function ($$v) {_vm.$set(_vm.selectedForEdit, "title", $$v)},expression:"selectedForEdit.title"}})],1),_c('div',[_c('label',{staticClass:"app-text-normal tag-title"},[_vm._v("Type")]),_c('div',{staticClass:"selected-field-styles text-grey cursor-pointer",staticStyle:{"padding-left":"5%"}},[_c('div',{staticClass:"app-text"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.selectedForEdit.type))+" ")])])]),_c('div',{directives:[{name:"sortable",rawName:"v-sortable",value:({
        onAdd: _vm.onDragAddOrUpdate,
        onUpdate: _vm.onDragAddOrUpdate,
        handle: '.handle',
      }),expression:"{\n        onAdd: onDragAddOrUpdate,\n        onUpdate: onDragAddOrUpdate,\n        handle: '.handle',\n      }"}],staticClass:"overflow-auto q-py-sm",staticStyle:{"max-height":"175px"}},_vm._l((_vm.fieldOptions),function(optionData){return _c('div',{key:optionData.id,staticClass:"row flex-no-wrap q-mt-sm relative-position",attrs:{"data-id":optionData.id}},[_c('custom-field-option',{attrs:{"optionData":optionData,"deleteOption":_vm.deleteOption,"updateCustomFieldOptionColor":_vm.updateCustomFieldOptionColor,"editOption":_vm.editOption,"updateOptionLabel":_vm.updateOptionLabel}})],1)}),0),(_vm.selectedForEdit.type === 'dropdown')?_c('div',{staticClass:"q-mt-md row items-centers justify-between flex-no-wrap"},[_c('q-input',{staticClass:"field-input app-text-normal",staticStyle:{"width":"140px"},attrs:{"placeholder":"Add item","dense":"","borderless":"","type":"text"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;_vm.addOption({ ..._vm.selectedForEdit, inputOptionText: _vm.inputOptionText });
          _vm.inputOptionText = null;}},model:{value:(_vm.inputOptionText),callback:function ($$v) {_vm.inputOptionText=$$v},expression:"inputOptionText"}}),_c('div',{staticClass:"cursor-pointer row justify-center items-center add-dropdown-option",on:{"click":function($event){_vm.addOption({ ..._vm.selectedForEdit, inputOptionText: _vm.inputOptionText });
          _vm.inputOptionText = null;}}},[_c('q-icon',{staticClass:"icon-color",staticStyle:{"font-weight":"500"},attrs:{"size":"15px","name":_vm.$icons.fasPlus}})],1)],1):_vm._e(),_c('div',[_c('q-checkbox',{staticClass:"flex-1 app-text-normal q-mt-sm",attrs:{"value":_vm.selectedForEdit.show_in_task,"size":"xs","label":"Show field on task-card"},on:{"input":function($event){return _vm.updateField(true)}}})],1),_c('q-item',{staticClass:"text-red app-text-normal",staticStyle:{"width":"95%","margin-bottom":"10px"},attrs:{"clickable":""},on:{"click":function($event){return _vm.confirmRemoveFieldHandler(true)}}},[_c('q-item-section',[_vm._v(" Delete field ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }