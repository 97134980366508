<template>
  <div
    class="row items-center justify-between full-width flex-no-wrap "
    v-if="isWorkspaceTimer"
  >
    <div class="row flex-no-wrap flex-1">
      <!-- <q-icon :name="$icons.matTimer" class="timer-icon q-mt-xs" size="sm" /> -->
      <div @click="openTaskDetail" class="cursor-pointer">
        <div class="timer">
          {{ timer }}
        </div>
        <div class="task-name">
          <q-btn
              flat
              dense
              size="8px"
              style="color:#99CAFF;"
              padding="0"
              :icon="$icons.fasCheck"
              class="q-mr-xs"
            >
            </q-btn>
          {{ cardTask.title }}
        </div>
      </div>
      <q-tooltip>
        {{ cardTask.title }}
      </q-tooltip>
    </div>
    <div class="row flex-no-wrap">
      <!-- <div
        @click="openTimeLogDialog"
        class="timer-option row items-center justify-center q-mr-sm cursor-pointer"
      >
        <q-icon :name="$icons.matCreate" />
      </div> -->
      <div
        @click="stopTimer(cardTask.activetimelog)"
        class="timer-option row items-center justify-center cursor-pointer"
      >
        <q-icon size="25px" :name="$icons.matStop" />
        <q-tooltip>Stop timer</q-tooltip>
      </div>
    </div>
  </div>
  <div v-else class="task-timer">
    <div
      @click="stopTimer(cardTask.activetimelog)"
      class="running-timer row items-center flex-no-wrap cursor-pointer relative-position"
    >
      <div class="card-task-time-log">
        <q-icon :name="$icons.matStop" />
      </div>
      <span class="time-text ">{{ timer }}</span>
      <div class="time-log-edit-delete row items-center" v-if="!hideEditButton">
        <div
          class="cursor-pointer edit-icon row items-center"
          @click.stop="$emit('openTimelogDialog')"
        >
          <q-icon size="16px" :name="$icons.matEdit" />
          <q-tooltip>Edit Time Report</q-tooltip>
        </div>
        <!-- <div class="delete-icon" clickable @click.stop="deleteTaskTimeLogs">
          <q-icon size="16px" class="q-mr-xs" :name="$icons.matDelete" />
          <q-tooltip>Delete Time Report</q-tooltip>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { fromUnixTime } from "date-fns";
export default {
  name: "Timer",
  props: [
    "startTime",
    "stopTimer",
    "cardTask",
    "isWorkspaceTimer",
    "openTimeLogDialog",
    "openTaskDetail",
    "deleteTaskTimeLogs",
    "hideEditButton",
  ],
  data() {
    return {
      now: new Date(),
    };
  },
  created() {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  computed: {
    timer() {
      let seconds = Math.floor(
        (this.now.getTime() - fromUnixTime(this.startTime).getTime()) / 1000
      );
      const hours = 3600 > seconds ? 0 : parseInt(seconds / 3600, 10);
      const time = {
        hours: this._padNumber(hours),
        seconds: this._padNumber(seconds % 60),
        minutes: this._padNumber(parseInt(seconds / 60, 10) % 60),
      };
      if(time.hours > 0) {
        return `${time.hours}h : ${time.minutes}m : ${time.seconds}s`;
      }
      return `${time.minutes}m : ${time.seconds}s`;
    },
  },
  methods: {
    _padNumber: (number) =>
      number > 9 || number === 0 ? number : "0" + number,
  },
};
</script>
