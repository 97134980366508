<template>
  <div class="q-mr-sm">
    <div>
      <q-menu
        :value="addTaskTimeLog"
        @input="closeAddingTimeLog"
        max-height="auto"
        anchor="top left"
        self="top left"
        content-class="overflow-auto task-date-picker"
      >
        <q-date
          mask="YYYY-MM-DD"
          :value="dates"
          today-btn
          range
          @input="updatedDate"
          class="date-picker-calendar"
        >
          <div
            class="date-time-block q-px-md q-py-sm column no-flex-wrap justify-between"
            style="height:125px"
          >
            <!-- Start Dat and Time -->
            <div class="row justify-between flex-no-wrap items-center q-mb-md">
              <div class="date-label">
                <span class="demi-bold">
                  Starts:
                </span>
                <span class="an-14 demi-bold-text">
                  {{ startDate ? startDate.substring(0, 10) : "None" }}
                </span>
              </div>
              <div class="time-input">
                <q-input
                  :value="startTime"
                  @input="(val) => (startTime = val)"
                  @blur="checkTimeValidation"
                  dense
                  type="time"
                  bg-color="white"
                >
                  <div
                    @click="startTimeMenu = !startTimeMenu"
                    class="arrow-icon row items-center no-flex-wrap"
                  >
                    <q-icon
                      v-if="startTimeMenu"
                      :name="$icons.matKeyboardArrowUp"
                    />
                    <q-icon v-else :name="$icons.matKeyboardArrowDown" />
                  </div>
                  <q-menu
                    no-parent-event
                    content-class="time-option-wrapper overflow-auto"
                    max-height="180px"
                    v-model="startTimeMenu"
                  >
                    <q-list class="time-list">
                      <template
                        v-for="(time, index) in startTimeDropdownOptions"
                      >
                        <q-item
                          :key="index"
                          @click.stop="setStartTime(time)"
                          clickable
                        >
                          <q-item-section>
                            {{ time.label }}
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-menu>
                </q-input>
              </div>
            </div>
            <!-- End Date and Time row -->
            <div class="row flex-no-wrap justify-between items-center ">
              <div class="date-label">
                <span class="demi-bold">
                  Ends:
                </span>
                <span class="an-14 demi-bold-text">
                  {{ endDate ? endDate.substring(0, 10) : "None" }}
                </span>
              </div>
              <span class="an-14 time-input">
                <q-input
                  :value="endTime"
                  @input="(val) => (endTime = val)"
                  @blur="checkTimeValidation"
                  dense
                  type="time"
                  bg-color="white"
                >
                  <div
                    @click="endTimeMenu = !endTimeMenu"
                    class="arrow-icon row items-center no-flex-wrap"
                  >
                    <q-icon
                      v-if="endTimeMenu"
                      :name="$icons.matKeyboardArrowUp"
                    />
                    <q-icon v-else :name="$icons.matKeyboardArrowDown" />
                  </div>
                  <q-menu
                    no-parent-event
                    content-class="time-option-wrapper overflow-auto"
                    max-height="180px"
                    v-model="endTimeMenu"
                  >
                    <q-list class="time-list">
                      <template v-for="(time, index) in endTimeDropdownOptions">
                        <q-item
                          :key="index"
                          @click.stop="setEndTime(time)"
                          clickable
                        >
                          <q-item-section>
                            {{ time.label }}
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-list>
                  </q-menu>
                </q-input>
              </span>
            </div>
            <div class="flex justify-center">
              <span
                class="an-14 bold-text q-mt-sm"
                style="text-align: center;width: 100%;font-weight: 600;"
              >
                {{ dateDiff ? dateDiff : "0h 0m" }}
              </span>
            </div>
          </div>
          <div class="q-gutter-x-sm q-mt-sm">
            <q-btn
              flat
              color="grey"
              class="rounded-borders"
              @click="closeAddingTimeLog"
              label="Cancel"
              clickable
            />
            <q-btn
              flat
              padding="xs md"
              class="bg-teal-14 text-white rounded-borders"
              style="width: 70px"
              label="OK"
              @click.stop="addTaskTimeLogData"
              :disabled="
                isDisabled && !(startDate && endDate && startTime && endTime)
              "
            />
          </div>
        </q-date>
      </q-menu>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
// import { fromUnixTime } from "date-fns";
import { timeList } from "@/utils/Contents.js";
export default {
  name: "AddTaskTimeLog",
  //props: ["addTaskTimeLog", "className"],
  props: {
    addTaskTimeLog: Boolean,
    closeAddingTimeLog: Function,
    addTaskTimeLogEventHandler: Function,
    taskData: Object,
  },
  mounted() {
    // if (this.taskdates) {
    //   if (this.taskdates.to) {
    //     this.dates = this.taskdates;
    //   } else {
    //     this.dates = this.taskdates.from;
    //   }
    // }
    if (this.taskData) {
      this.dates = {
        from: this.taskData.start_date.substr(0, 10),
        to: this.taskData.end_date.substr(0, 10),
      };
      this.id = this.taskData.id;
      this.startTime = this.taskData.start_date.substr(11, 8);
      this.endTime = this.taskData.end_date.substr(11, 8);
    } else {
      this.dates = format(new Date(), "yyyy-MM-dd");
    }
  },
  data() {
    return {
      id: null,
      dates: null,
      menu: true,
      startTime: "09:00",
      endTime: "17:00",
      isDisabled: true,
      startTimeMenu: false,
      endTimeMenu: false,
    };
  },
  methods: {
    setStartTime(time) {
      this.startTime = time.value;
      this.startTimeMenu = false;
    },
    setEndTime(time) {
      this.endTime = time.value;
      this.endTimeMenu = false;
    },
    checkTimeValidation() {
      if (
        Date.parse(this.startDate + " " + this.startTime) >
        Date.parse(this.endDate + " " + this.endTime)
      ) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Invalid time value",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.startTime = "09:00";
        this.endTime = "17:00";
      }
      this.isDisabled = false;
    },
    updatedDate(date) {
      if (date && date.type && date.type === "input") {
        date.preventDefault();
        return false;
      }

      if (date && date.from) {
        this.dates = date;
      } else {
        this.dates = date;
      }
    },
    addTaskTimeLogData() {
      if (this.startDate && this.endDate && this.startTime && this.endTime) {
        let data = {
          start_date: this.startDate + " " + this.startTime,
          end_date: this.endDate + " " + this.endTime,
        };
        if (this.id) {
          data.id = this.id;
        }
        this.addTaskTimeLogEventHandler(data);
        this.resetTaskTimeLog();
        this.closeAddingTimeLog();
      }
    },
    resetTaskTimeLog() {
      this.dates = null;
      this.startTime = null;
      this.endTime = null;
    },
  },
  computed: {
    startTimeDropdownOptions() {
      if (this.startDate === this.endDate) {
        const startTimeIndex = timeList.findIndex(
          (t) => t.value === this.endTime
        );
        return timeList.slice(0, startTimeIndex);
      }
      return timeList;
    },
    endTimeDropdownOptions() {
      if (this.startDate === this.endDate) {
        const endTimeIndex = timeList.findIndex(
          (t) => t.value === this.startTime
        );
        return timeList.slice(endTimeIndex + 1, timeList.length);
      }
      return timeList;
    },
    startDate() {
      if (this.dates) {
        return this.dates.hasOwnProperty("from") ? this.dates.from : this.dates;
      } else {
        return null;
      }
    },
    endDate() {
      return this.dates && this.dates.hasOwnProperty("to")
        ? this.dates.to
        : this.startDate;
    },
    dateDiff() {
      if ((this.startDate || this.endDate) && this.startTime && this.endTime) {
        let end_date = this.endDate ? this.endDate : this.startDate;
        let dateFuture = new Date(end_date + "T" + this.endTime);
        let dateNow = new Date(this.startDate + "T" + this.startTime);
        let seconds = Math.floor((dateFuture - dateNow) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        //seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

        return days * 24 + hours + "h " + minutes + "m";
      }
      return null;
    },
  },
};
</script>
