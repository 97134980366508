<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card style="min-width:400px;" class="q-pa-lg">
      <div class="row title q-mb-md">
        <span class="q-mx-auto">What did you work on?</span>
      </div>
      <q-input
        placeholder="Type description here"
        type="textarea"
        dense
        outlined
        autofocus
        v-model="timelogDescription"
      />
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="15px"
          label="Cancel"
          style="width:100px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          label="Done"
          style="width:100px"
          @click="stopActiveTimer"
          :loading="loading"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { UpdateTaskTimeLogMutation } from "@/gql";
export default {
  name: "StopTimerConfirmationDialog",
  props: ["value", "timelogId", "stopTimer"],
  data() {
    return {
      timelogDescription: null,
      loading: false,
    };
  },
  methods: {
    async stopActiveTimer() {
      this.loading = true;
      await this.stopTimer(this.timelogId);
      this.updateDescription();
    },
    async updateDescription() {
      if (this.timelogDescription && this.timelogDescription !== "") {
        const variables = {
          description: this.timelogDescription,
          id: this.timelogId,
        };
        await this.$api.mutate({
          mutation: UpdateTaskTimeLogMutation,
          variables,
        });
        this.timelogDescription = null;
      }
      this.loading = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
.title {
  font-weight: 500;
  font-size: 18px;
}
</style>
