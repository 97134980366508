<template>
  <div class="row items-center">
    <div class="handle" style="max-width: 25px; cursor: all-scroll;">
      <q-icon
        size="sm"
        class="inborader--text"
        :name="$icons.mdiDragVertical"
        :ref="`optionData-${optionData.id}`"
      />
    </div>
    <div class="row  flex-no-wrap">
      <div
        style="height:28px; width:28px; border-radius: 4.6px; margin-right:12px"
        :style="{
          'background-color': optionData.colorcode,
        }"
        @click.stop="isSwatchOpen = true"
      ></div>

      <div
        class="app-text-normal ellipses"
        style="position:relative; top:5px;max-width: 90px;"
        v-if="!editOption || editOption.id !== optionData.id"
        @click="enableEditOption"
        v-tooltip
      >
        {{ optionData.label }}
        <q-tooltip v-if="isTruncated">
          {{ optionData.label }}
        </q-tooltip>
      </div>
      <q-input
        v-else
        v-model="optionData.label"
        placeholder="Add item"
        dense
        borderless
        autofocus
        class="edit-option-input app-text-normal "
        type="text"
        style="width: 85px;"
        @blur="updateOptionLabel(optionData)"
        @keydown.enter.exact="updateOptionLabel(optionData)"
      />
      <div
        style="text-aglin:center; position:absolute; right:0px"
        @click="deleteOption(optionData)"
      >
        <q-icon
          :name="$icons.mdiDeleteOutline"
          size="27px"
          style="text-aglin:center;"
        ></q-icon>
      </div>
    </div>
    <q-menu
      v-if="isSwatchOpen"
      v-model="isSwatchOpen"
      max-height="auto"
      content-class="swatch-container row q-py-sm q-px-md"
    >
      <div
        v-for="data in swatchColorOptions"
        :key="'swathc-' + data.id"
        class="row flex-no-wrap relative-position"
        @click="
          updateCustomFieldOptionColor({
            ...optionData,
            swatchColor: data.colorcode,
          });
          isSwatchOpen = false;
        "
      >
        <div
          :class="[
            data.isWhite && 'white-box',
            'swatch-box',
            isSwatchSelected(data) ? 'selected' : 'not-selected',
          ]"
          :style="{
            'background-color': data.colorcode,
          }"
        ></div>
        <q-icon
          v-if="isSwatchSelected(data)"
          :name="$icons.fasCheck"
          color="white"
          size="15px"
          class="swatch-selected-check"
        />
      </div>
    </q-menu>
  </div>
</template>

<script>
import { swatchColors } from "@/utils/Contents.js";

export default {
  props: [
    "optionData",
    "deleteOption",
    "updateCustomFieldOptionColor",
    "editOption",
    "updateOptionLabel",
  ],
  data() {
    return {
      isSwatchOpen: false,
      isTruncated: false,
    };
  },
  methods: {
    enableEditOption() {
      if (this.editOption) {
        this.editOption.id = this.optionData.id;
      }
    },
    isSwatchSelected(swatch) {
      return (
        this.optionData.colorcode.toLowerCase() ===
        swatch.colorcode.toLowerCase()
      );
    },
  },
  computed: {
    swatchColorOptions() {
      return swatchColors;
    },
  },
};
</script>
<style lang="scss" scoped>
.field-input {
  height: 20px !important;
  border-radius: 5px;
  border: solid 1px #b8c1cb;
  background-color: #f4f5f8;
  margin-right: 10px;
  padding-bottom: 29px;
  padding-left: 8px;
}
</style>
