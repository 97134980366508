<template>
  <div class="flex-1">
    <div>
      <q-btn
        dense
        flat
        class="control-button"
        :class="menu && 'active'"
        color="grey-10"
        size="md"
        padding="0"
        :style="{
          position: 'absolute',
          top: '17px',
          height: '29px',
          width: '86px',
          left: mediaExpansion ? '134px' : '150px',
        }"
        no-caps
        @click.stop
      >
        <div
          style="display: flex;
            justify-content: space-around;
            width: 100%;"
        >
          <span>Add</span>
          <div class="icon">
            <q-icon v-if="menu" :name="$icons.matKeyboardArrowUp" size="xs" />
            <q-icon v-else :name="$icons.matKeyboardArrowDown" size="xs" />
          </div>
        </div>
        <q-tooltip v-if="!menu">Add attachments</q-tooltip>
        <q-menu auto-close v-model="menu">
          <q-list>
            <q-item @click="fromComputer" clickable>
              <q-item-section style="min-width:35px" class="q-pr-none" avatar>
                <q-icon :name="$icons.matComputer" size="24px" />
              </q-item-section>
              <q-item-section>
                Your Computer
              </q-item-section>
            </q-item>
            <q-item @click="sharedFileDialog = true" clickable>
              <q-item-section style="min-width:35px" class="q-pr-none" avatar>
                <q-icon :name="$icons.fasCopy" size="24px" />
              </q-item-section>
              <q-item-section>
                Shared Files
              </q-item-section>
            </q-item>
            <q-separator />
            <!-- implement dropbox and googledrive after API change -->
            <!-- <q-item @click="dropBox(null, currentUser.id)" clickable>
              <q-item-section style="min-width:35px" class="q-pr-none" avatar>
                <q-icon :name="$icons.fabDropbox" size="24px" />
              </q-item-section>
              <q-item-section>
                Dropbox
              </q-item-section>
            </q-item>
            <q-item @click="googleDrive(null, currentUser.id)" clickable>
              <q-item-section style="min-width:35px" class="q-pr-none" avatar>
                <q-icon :name="$icons.fabGoogleDrive" size="24px" />
              </q-item-section>
              <q-item-section>
                Google Drive
              </q-item-section>
            </q-item> -->
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="currentWorkspace"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="authToken"
      :fromTaskAttachment="true"
      @close="updateMediaCache"
      @setProgress="setProgress"
      @updateUploadCount="
        (total, uploaded) => {
          uploadCount = uploaded;
          totalFile = total;
        }
      "
    >
      <!-- <div
        class="task-attachment-uploader row justify-center items-center cursor-pointer q-mb-md"
      >
        <div class="attachment-text">
          Add attachments
        </div>
        <q-menu content-class="overflow-auto" auto-close>
          <q-list>
            <q-item clickable>
              <q-item-section style="min-width:35px" class="q-pr-none" avatar>
                <q-icon :name="$icons.matComputer" size="24px" color="grey-7" />
              </q-item-section>
              <q-item-section>
                Your Computer
              </q-item-section>
            </q-item>
            <q-item clickable>
              <q-item-section style="min-width:35px" class="q-pr-none" avatar>
                <q-icon :name="$icons.matImage" size="24px" color="grey-7" />
              </q-item-section>
              <q-item-section>
                Shared Files
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        <q-tooltip>
          Add attachments
        </q-tooltip>
      </div> -->
    </vue-dropzone-file-upload>
    <div
      v-if="progress > 0"
      style="display: flex; justify-content: center"
      class="q-mb-sm"
    >
      <q-linear-progress
        color="green"
        :indeterminate="query"
        size="20px"
        class="ma-0 progress"
        :value="progress"
        dark
        rounded
      >
        <div class="absolute-full flex label" style="margin-left: 10px">
          <div>
            <p>
              {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile - 1} files uploaded` : 'Uploaded'}}
              <span>{{ Math.round(progress * 100) + "%" }}</span>
            </p>
          </div>
        </div>
      </q-linear-progress>
    </div>
    <shared-files-dialog
      v-if="sharedFileDialog"
      v-model="sharedFileDialog"
      @closeDialog="sharedFileDialog = false"
      :imageList="imageList"
      :attachMediaToPost="
        (data) => addSharedFileToUploader(data, 'fromTaskAttachment')
      "
      :currentWorkspaceId="currentWorkspace"
    />
  </div>
</template>
<script>
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload.vue";
import { AttachTaskMediaFolderMutation } from "@/gql";
import { mapGetters } from "vuex";

export default {
  name: "TaskAttachmentUploader",
  props: [
    "authToken",
    "currentWorkspace",
    "taskLabels",
    "currentUser",
    "currentCompany",
    "isWorkspaceOwner",
    "imageList",
    "task",
    "attachTaskMediaHandler",
    "expandAttachmentHandler",
    "mediaExpansion",
    "updateTask",
  ],
  mixins: [CopiedImageOnEditor, GoogleDriveMethodMixin, DropBoxMixin],
  data() {
    return {
      fileUpload: {
        flag: false,
        dialog: false,
      },
      fileList: null,
      sharedFileDialog: false,
      progress: 0,
      totalFile: 0,
      uploadCount: 0,
      menu: false,
    };
  },
  mounted() {
    let dzMessage = document.getElementsByClassName("dz-message")[0];
    dzMessage.remove();
  },
  components: {
    SharedFilesDialog,
    VueDropzoneFileUpload,
  },
  computed: {
    ...mapGetters({
      allFeatureList: "allFeatureList",
    }),
    allFeatures() {
      return this.allFeatureList.data.features;
    },
    user() {
      return this.currentUser;
    },
    // outSourcedFiles() {
    //   return filter(this.fileList, (media) => {
    //     if (
    //       media.file_resource === "google" ||
    //       media.file_resource === "dropbox"
    //     ) {
    //       return media;
    //     }
    //   });
    // },
  },
  methods: {
    async attachTaskMediaFolder(folderIds, task_id) {
      const variables = {
        folder: folderIds,
        task_id: task_id,
      };
      const response = await this.$api.mutate({
        mutation: AttachTaskMediaFolderMutation,
        variables,
      });

      const taskEntity = this.$api.getEntity("task", task_id);

      taskEntity.mediafolder = [
        ...response.data.attachTaskMediaFolder.mediafolder,
      ];
    },
    updateMediaCache(data) {
      this.fileUploadCloseHandler(data.fileList, "fromTaskAttachment");
      if (
        data.fileSuccessCounter === 1 &&
        !this.currentCompany.status.subscriptionOver
      ) {
        this.allFeatures.forEach((o) => {
          if (o.key === "task_cover") {
            const media = data.fileList[Object.keys(data.fileList)[0]];
            if (media.type.match(/image.*/)) {
              this.setCoverImage(media);
            }
          }
        });
        return false;
      }
      this.fileList = {};
    },
    setCoverImage(media) {
      const task = this.$api.getEntity("task", this.task.id);
      if(task) {
        task.cover_image = media.id;
        this.updateTask({
          id: this.task.id,
          cover_image: media.id,
        });
      }
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    cancelUpload() {
      this.$refs.vueFileUploader.cancelUpload();
      this.fileList = {};
    },
  },
};
</script>
