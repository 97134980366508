<template>
  <q-card class="column no-wrap edit-message-card">
    <q-card-section class="row col-shrink no-wrap q-pa-none">
      <!-- Left Editor Section -->
      <div
        class="column col-grow left-section relative-position q-pa-lg flex-no-wrap"
      >
        <div class="dialog-title">
          Edit comment
        </div>
        <div class="row edit-editor overflow-auto q-mt-md q-pa-md">
          <add-media-plus
            @clicked="fileUpload.dialog = true"
            @openSharedFile="sharedFileDialog = true"
            @dropBox="() => dropBox(null, currentUser.id)"
            @googleDrive="() => googleDrive(null, currentUser.id)"
            @fromComputer="fromComputer"
          />
          <!-- Editor -->
          <editor
            v-model="content"
            :isAbPosition="true"
            :mentions="mentionsList"
            :maxHeight="665"
            :minHeight="590"
            :class-name="['ProseMirror-collaboration-editor']"
            :autofocus="true"
            :showMenu="false"
            placeholder="Type a message here"
            @submit="submit"
          />
          <shared-files-dialog
            v-model="sharedFileDialog"
            :imageList="imageList"
            @closeDialog="sharedFileDialog = false"
            :attachMediaToPost="addSharedFileToUploader"
            :currentWorkspaceId="currentWorkspace"
          />
          <vue-dropzone-file-upload
            hidden
            ref="vueFileUploader"
            :workspace="currentWorkspace"
            :isWorkspaceOwner="isWorkspaceOwner"
            :authToken="authToken"
            :totalFile="totalFile"
            @close="fileUploadCloseHandler"
            @setProgress="setProgress"
            @totalFileCount="(total) => (totalFile = total)"
            @updateUploadCount="(uploaded) => (uploadCount = uploaded)"
          ></vue-dropzone-file-upload>
        </div>
      </div>
      <!-- Right Media Section -->
      <div
        class="column col-grow right-section justify-between relative-position q-py-lg flex-no-wrap"
      >
        <div>
          <div class="attachments-title">
            Attachments
          </div>
          <q-btn
            :icon="$icons.matClose"
            text-color="black"
            class="close-icon"
            round
            flat
            padding="0"
            dense
            @click="cancel"
          />
          <div class="q-mt-xs" v-if="progress > 0">
            <q-linear-progress
              color="green"
              :indeterminate="query"
              size="20px"
              class="ma-0 progress"
              :value="progress"
              dark
              rounded
            >
              <div class="absolute-full flex label" style="margin-left: 5px">
                <div style="margin-right: 5px">
                  <q-btn
                    round
                    :icon="$icons.matClose"
                    size="5px"
                    style="color: #000000; opacity: 0.5; background: #ffffff"
                  >
                    <q-tooltip>
                      Cancel Upload
                    </q-tooltip>
                  </q-btn>
                </div>
                <div>
                  <p>
                    {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile} files uploaded` : 'Uploaded'}}
                    <span>{{ Math.round(progress * 100) + "%" }}</span>
                  </p>
                </div>
              </div>
            </q-linear-progress>
          </div>
          <div
            class="overflow-auto q-mt-sm full-width media-attachments-wrapper comment-editor-box"
            style="max-height:600px"
            v-if="totalFiles.length > 0 || folderListArr.length"
          >
            <template v-for="folder in folderListArr">
              <div v-if="folder" :key="folder.id">
                <div
                  class="relative-position media-list-folder edtor-media-upload-attachment q-mb-sm  row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
                  style="max-width:230px"
                  @click="openFolder(folder)"
                >
                  <div class="relative-position" style="height:40px">
                    <q-avatar size="40px" rounded>
                      <q-icon
                        class="media-grid-folder-icon"
                        size="40px"
                        :name="$icons.matFolder"
                      />
                      <div class="media-grid-folder-count">
                        {{ folder.media_count > 9 ? "9+" : folder.media_count }}
                      </div>
                    </q-avatar>
                  </div>
                  <div class="file-name" v-tooltip>
                    {{ folder.title }}
                  </div>
                  <div
                    @click.stop="removeFolder(folder)"
                    class="media-remove cursor-pointer group-hover-media-item"
                  >
                    <q-icon :name="$icons.matClose" class="close-icon" />
                  </div>
                </div>
              </div>
            </template>
            <div
              v-if="totalFiles.length > 0"
              v-sortable="{
                onAdd: onDragAddOrUpdate,
                onUpdate: onDragAddOrUpdate,
                emptyInsertThreshold: 100,
              }"
            >
              <template v-for="file in totalFiles">
                <div v-if="file" :data-id="file.id" :key="file.id">
                  <media
                    :showProgress="showProgress"
                    :media="file"
                    :removeMedia="removeMedia"
                    :height="40"
                    :maxWidth="230"
                    :fullview="fullview"
                    :editDoc="editDoc"
                    id="comment-editor-media"
                  ></media>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div style="align-self: center;">
          <q-btn
            text-color="black"
            dense
            padding="7px 32px"
            label="Cancel"
            class="q-mr-sm"
            outline
            no-caps
            @click="cancel"
          />
          <q-btn
            text-color="white"
            color="primary"
            dense
            padding="7px 38px"
            label="Save"
            @click="submit"
            :disabled="
              !content || isEnoughCharacter || showProgress || !isFileUploading
            "
            no-caps
          />
        </div>
      </div>
    </q-card-section>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      :fromUploader="false"
      :imageList="totalFiles"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="taskLabels"
      :resetDialog="resetDialog"
      @clickonhidebutton="ishide = !ishide"
      @setActiveMedia="setActiveMedia"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
      @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
      @sendComment="(data) => $emit('sendComment', data)"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
      @updateComment="(data) => $emit('updateComment', data)"
    ></image-viewer-dialog>
  </q-card>
</template>
<script>
import { mapGetters } from "vuex";
import get from "lodash/get";
import keys from "lodash/keys";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";
import RandomPosition from "random-position";

import DropBoxMixin from "@/mixins/DropBoxMixin";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import { extractMentionsFromContent } from "@/utils/StringHelpers";

import AddMediaPlus from "@/components/Editor/AddMediaPlus";
import Editor from "@/components/Editor/Editor";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload";
import Media from "@/components/Miscellaneous/Media";
import ImageViewerDialog from "@/components/Dialogs/ImageViewerDialog";
import { UpdateMediaMutation, DetachCommentFolderMutation, DetachTaskCommentMediaMutation } from "@/gql";

export default {
  name: "EditCommentDialog",
  props: [
    "editCommentContent",
    "mentionsList",
    "workspace",
    "isWorkspaceOwner",
    "imageList",
    "currentUser",
    "taskLabels",
    "closeEditDialog",
    "openFolder",
    "editDoc",
  ],
  mixins: [GoogleDriveMethodMixin, DropBoxMixin, CopiedImageOnEditor],
  components: {
    AddMediaPlus,
    Editor,
    SharedFilesDialog,
    VueDropzoneFileUpload,
    Media,
    ImageViewerDialog,
  },
  beforeCreate() {
    this.$eventBus.$off("updateFileList");
  },
  created() {
    this.$eventBus.$on("updateFileList", this.updateFileList);
  },
  mounted() {
    this.content = this.editCommentContent.comment;
  },
  data() {
    return {
      content: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      previousTagged: [],
      fileList: null,
      sharedFileDialog: false,
      progress: 0,
      showModal: false,
      dialogMedia: null,
      gridMediaId: "",
      ishide: false,
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
      ],
      totalFile: 0,
      uploadCount: 0,
      folderList: null,
    };
  },
  methods: {
    removeFolder(folder) {
      if (this.commentMediaFolderIds.includes(folder.id)) {
        this.detachFolder({
          task_comment_id: this.editCommentContent.id,
          folder: [folder.id],
        });
      } else {
        this.deleteFolderHandler(folder);
      }
    },
    async detachFolder(args) {
      const variables = {
        ...args,
      };
      await this.$api.mutate({
        mutation: DetachCommentFolderMutation,
        variables,
      });
      //Remove from Entity cache
      const taskCommentEntity = this.$api.getEntity(
        "taskComment",
        args.task_comment_id
      );
      taskCommentEntity.mediafolder = taskCommentEntity.mediafolder.filter(
        (f) => f.id !== args.folder[0]
      );
      this.editCommentContent.mediafolder = this.editCommentContent.mediafolder.filter(
        (f) => f.id !== args.folder[0]
      );
    },
    cancel() {
      this.$emit("closeDialog");
      if (this.uploadedMedia.length) {
        this.uploadedMedia.forEach((media) => {
          this.removeMedia(media);
        });
      }
    },
    async submit() {
      if (!this.isFileUploading) {
        return;
      }
      this.progress = 0;
      if (this.content && this.content !== "") {
        this.commonOutSourceFileHandler((outsourceMediaIds) => {
          this.createComment(outsourceMediaIds);
        });
      }
    },
    createComment(outsourceMediaIds = []) {
      const optmedia =
        this.sortedFiles && this.sortedFiles.length > 0 ? this.sortedFiles : [];
      // For Any drive Files
      const driveData = this.makeDriveData();

      const commentMediaFolderIds = this.commentMediaFolders.map(
        (obj) => obj.id
      );
      const folderIds = map(this.folderList, (f) => f.id).filter(
        (f) => !commentMediaFolderIds.includes(f)
      );
      this.$emit("submit", {
        text: this.content,
        tagged_user_ids: extractMentionsFromContent(
          this.content,
          this.mentionsList
        ),
        media: [...this.s3MediaIds, ...outsourceMediaIds],
        optmedia: optmedia,
        workspace_id: this.currentWorkspace,
        media_urls: driveData,
        folder: folderIds,
        mediafolder: this.folderListArr,
      });
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.sortedFiles];
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    updateFileList(data) {
      if (!this.fileList[data.media_id].verifyKey) {
        this.fileList[data.media_id].commentPoint.push(data);
      }
    },
    cancelUpload() {
      this.$refs.vueFileUploader.cancelUpload();
      this.fileList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
      setTimeout(() => {
        this.progress = 0;
      }, 10);
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    async removeMedia(media) {
      this.uploadCount = 0;
      if(this.editCommentContent) {
        const variables = {
          task_comment_id: this.editCommentContent.id,
          media: [media.id],
        };

        await this.$api.mutate({
          mutation: DetachTaskCommentMediaMutation,
          variables,
        });
      }

      if (this.fileList) {
        this.deleteCommonMediaHandler(media);
      }
      // const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      // const mediaObj = query.data.newMedia.find((m) => m.id === media.id);
      this.editCommentContent.media = this.editCommentContent.media.filter(
        (m) => m.id !== media.id
      );
      // if (!mediaObj) {
      // }
      if (!this.editCommentContent && (media.is_document_section == 1 || (media.file_resource === "s3" && media["verifyKey"] !== "fromShared"))) {
        this.deleteMediaMutation(media);
      }
      this.$refs.vueFileUploader.removeMedia(media);
      if (!this.uploadedFileIds.length) {
        this.progress = 0;
      }
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    currentWorkspace() {
      return this.workspace ? this.workspace.id : -1;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    uploadedFileIds() {
      if (this.fileList) {
        return map(
          filter(this.fileList, (media) => {
            if (
              media.file_resource !== "google" &&
              media.file_resource !== "dropbox"
            ) {
              return media;
            }
          }),
          (media) => media.id
        );
      } else {
        return [];
      }
    },
    uploadedMedia() {
      return this.uploadedFileIds.map((id) => this.$api.getEntity("media", id));
    },
    s3MediaIds() {
      const s3File = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "outsource" &&
          (a.file_resource === "s3" || a.hasOwnProperty("verifyKey"))
      );
      const data = {};
      for (var media in s3File) {
        data[s3File[media].id] = s3File[media];
      }
      if (data) {
        return keys(data);
      } else {
        return [];
      }
    },
    messageMedia() {
      return this.editCommentContent.media;
    },
    outSourcedFiles() {
      return filter(this.fileList, (media) => {
        if (
          media.file_resource === "google" ||
          media.file_resource === "dropbox"
        ) {
          return media;
        }
      });
    },
    sortedFiles() {
      return orderBy(
        [...this.uploadedMedia, ...this.messageMedia],
        ["sequence"]
      );
    },
    totalFiles() {
      return [...this.sortedFiles, ...this.outSourcedFiles];
    },
    commentMediaFolders() {
      return this.editCommentContent.mediafolder;
    },
    commentMediaFolderIds() {
      return this.commentMediaFolders.map((obj) => obj.id);
    },
    folderListArr() {
      const newSharedFolders = this.folderList
        ? uniqBy(
            Object.keys(this.folderList).map((key) => this.folderList[key]),
            "id"
          )
        : [];
      const mergedFolderList = uniqBy(
        [...newSharedFolders, ...this.commentMediaFolders],
        "id"
      );
      return mergedFolderList;
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = filter(this.totalFiles, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = filter(this.totalFiles, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    counter() {
      if (this.content) {
        return this.content.toString().length;
      } else {
        return 0;
      }
    },
    isEnoughCharacter() {
      return this.counter > 20000;
    },
    isFileUploading() {
      if (this.progress === 0 || this.progress === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
};
</script>
