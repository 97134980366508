<template>
  <div
    class="col-grow border-radius5 comment-editor-box comment-editor"
    style="margin-bottom: 0px"
    id="taskCommentPostUploader"
  >
    <div
      class="row flex-1 relative-position q-py-sm"
      :disabled="!isVisible([1, 2, 3])"
      style="
        background: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      "
    >
      <add-formate-option
        @toggleBold="toggleBold"
        @toggleItalic="toggleItalic"
        @insertText="insertText"
        :closeHyperlinkModal="closeHyperlinkModal"
        :showAddLink="showAddLink"
        @openAddLink="openAddLink"
        :addLinkTitle="addLinkTitle"
      />
    </div>
    <div
      :disabled="!isVisible([1, 2, 3])"
      class="editor-gey-box-border row flex-1 relative-position"
    >
      <add-media-plus
        @clicked="fileUpload.dialog = true"
        @openSharedFile="sharedFileDialog = true"
        @dropBox="() => dropBox(null, currentUser.id)"
        @googleDrive="() => googleDrive(null, currentUser.id)"
        @fromComputer="fromComputer"
        v-if="showAttatchmentIcon"
        @meetCall="getVideoCallLink"
        :isVideoButtonEnable="true"
        style="margin-top: 1px"
      />
      <!-- Editor -->
      <editor
        v-model="content"
        :maxHeight="150"
        ref="commentEditor"
        class="text-subtitle1"
        :editor-style="{ 'max-height': '136px', 'min-height': '44px' }"
        :mentions="mentionsList"
        @submit="submit"
        placeholder="Type comment..."
        :hideEditorMenu="true"
        @blur="handleBlur"
        @paste="handlePaste"
        @keyPressed="(e) => $emit('keyPressed', e)"
        @focus="handleCommentFocus"
        :autofocus="commentFocused"
        :setMargin="setMargin"
        :isAbPosition="true"
        :bottomPositions="true"
        @openAddLink="openAddLink"
      />
      <!-- Send Btn -->
      <q-btn
        class="editor-send-btn q-ma-none elevation-0"
        size="10px"
        style="margin: auto; margin-right: 2px"
        :disabled="
          !(content || videoCallLink) ||
          isEnoughCharacter ||
          showProgress ||
          !isFileUploading
        "
        @click="submit"
        text-color="white"
        :icon="$icons.fasPaperPlane"
      />
      <shared-files-dialog
        v-model="sharedFileDialog"
        v-if="sharedFileDialog"
        @closeDialog="sharedFileDialog = false"
        :imageList="imageList"
        :attachMediaToPost="addSharedFileToUploader"
        :isloadingMedia="isloadingMedia"
        :currentWorkspaceId="currentWorkspace"
      />
      <vue-dropzone-file-upload
        hidden
        ref="vueFileUploader"
        :workspace="currentWorkspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :authToken="authToken"
        :totalFile="totalFile"
        @close="fileUploadCloseHandler"
        @setProgress="setProgress"
        @totalFileCount="(total) => (totalFile = total)"
        @updateUploadCount="(uploaded) => (uploadCount = uploaded)"
      ></vue-dropzone-file-upload>
      <div
        class="overflow-auto q-mt-sm full-width media-attachments-wrapper scroll-wrapper comment-editor-box"
        v-if="videoCallLink"
      >
        <div class="relative-position q-ml-md" style="width: 220px">
          <q-btn
            round
            :icon="$icons.matLink"
            size="5px"
            style="
              color: #000000;
              opacity: 0.5;
              background: #ffffff;
              position: absolute;
              z-index: 1;
              right: 25px;
              top: 5px;
            "
            @click="$copyTextToClipboard(copyVideoCallLink)"
          >
            <q-tooltip> Copy call link </q-tooltip>
          </q-btn>
          <q-btn
            round
            :icon="$icons.matClose"
            size="5px"
            style="
              color: #000000;
              opacity: 0.5;
              background: #ffffff;
              position: absolute;
              z-index: 1;
              right: 5px;
              top: 5px;
            "
            @click="videoCallLink = false"
          >
            <q-tooltip> Cancel Video Call </q-tooltip>
          </q-btn>
          <img
            style="position: relative; width: 100%"
            class="image"
            src="/static/images/join-call.svg"
          />
        </div>
      </div>
      <div
        class="overflow-auto q-mt-sm full-width media-attachments-wrapper scroll-wrapper comment-editor-box"
        v-if="totalFiles.length > 0 || folderListArr.length"
      >
        <template v-for="folder in folderList">
          <div v-if="folder" :data-id="folder.id" :key="folder.id">
            <div
              class="relative-position media-list-folder edtor-media-upload-attachment q-mb-sm q-mx-md row flex-no-wrap items-center group-hover-media cursor-pointer noselect"
              @click="openFolder(folder)"
            >
              <div class="relative-position" style="height: 40px">
                <q-avatar size="40px" rounded>
                  <q-icon
                    class="media-grid-folder-icon"
                    size="40px"
                    :name="$icons.matFolder"
                  />
                  <div class="media-grid-folder-count">
                    {{ folder.media_count > 9 ? "9+" : folder.media_count }}
                  </div>
                </q-avatar>
              </div>
              <div class="file-name" v-tooltip>
                {{ folder.title }}
              </div>
              <div
                @click.stop="removeFolder(folder)"
                class="media-remove cursor-pointer group-hover-media-item"
              >
                <q-icon :name="$icons.matClose" class="close-icon" />
              </div>
            </div>
          </div>
        </template>
        <div
          v-sortable="{
            onAdd: onDragAddOrUpdate,
            onUpdate: onDragAddOrUpdate,
            emptyInsertThreshold: 100,
          }"
          v-if="totalFiles.length > 0 && commentEditorMedia"
        >
          <template v-for="file in totalFiles">
            <div v-if="file" :data-id="file.id" :key="file.id">
              <media
                :showProgress="showProgress"
                :media="file"
                :removeMedia="removeMedia"
                :height="40"
                :fullview="fullview"
                :editDoc="editDoc"
                id="comment-editor-media"
                class="q-mx-md"
              ></media>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <q-linear-progress
        color="green"
        :indeterminate="query"
        size="20px"
        class="ma-0 progress"
        :value="progress"
        v-if="progress > 0"
        dark
        rounded
      >
        <div class="absolute-full flex label" style="margin-left: 10px">
          <div style="margin-right: 5px">
            <q-btn
              round
              :icon="$icons.matClose"
              size="5px"
              style="color: #000000; opacity: 0.5; background: #ffffff"
              @click="cancelUpload"
            >
              <q-tooltip> Cancel Upload </q-tooltip>
            </q-btn>
          </div>
          <div>
            <p>
              {{progress &lt; 1 && uploadCount && totalFile? `${uploadCount} of ${totalFile} files uploaded` : 'Uploaded'}}
              <span>{{ Math.round(progress * 100) + "%" }}</span>
            </p>
          </div>
        </div>
      </q-linear-progress>
    </div>
    <image-viewer-dialog
      v-if="showModal"
      v-model="showModal"
      ref="mediaCommentingView"
      :fromUploader="false"
      :imageList="totalFiles"
      :dialogMedia="dialogMedia"
      :generatedPointPixels="returnpoints"
      :commentListing="returnCommentListing"
      :currentUser="currentUser"
      :moreOption="moreOption"
      :isWorkspaceOwner="isWorkspaceOwner"
      :ishide="ishide"
      :labels="taskLabels"
      :resetDialog="() => resetDialog()"
      @clickonhidebutton="ishide = !ishide"
      @setActiveMedia="setActiveMedia"
      @closeMediaModel="(data) => $emit('closeMediaModel', data)"
      @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
      @deleteCommentPoint="(data) => $emit('deleteCommentPoint', data)"
      @sendComment="(data) => $emit('sendComment', data)"
      @updatepositions="(data) => $emit('updatepositions', data)"
      @updateMediaCommentPointLabel="
        (data) => $emit('updateMediaCommentPointLabel', data)
      "
      @deleteMediaComment="(data) => $emit('deleteMediaComment', data)"
      @updateComment="(data) => $emit('updateComment', data)"
    ></image-viewer-dialog>
    <upgrade-storage-dialog
      v-if="company && company.pricing_version === 3 && upgradeStorageDialog"
      v-model="upgradeStorageDialog"
      :reachedLimitPlan="company && company"
    />
  </div>
</template>
<script>
import get from "lodash/get";
import keys from "lodash/keys";
import filter from "lodash/filter";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import RandomPosition from "random-position";
import uniqBy from "lodash/uniqBy";

import { mapGetters } from "vuex";
import Editor from "@/components/Editor/Editor";
import AddMediaPlus from "@/components/Editor/AddMediaPlus";
import Media from "@/components/Miscellaneous/Media";
import { extractMentionsFromContent } from "@/utils/StringHelpers";
import SharedFilesDialog from "@/components/Dialogs/SharedFilesDialog";
import GoogleDriveMethodMixin from "@/mixins/GoogleDriveMethodMixin";
import DropBoxMixin from "@/mixins/DropBoxMixin";
import UserScope from "@/mixins/UserScope";
import { compile } from "@/components/Editor/MarkupUtils";
import CopiedImageOnEditor from "@/mixins/CopiedImageOnEditor";
import UpgradeStorageDialog from "@/components/Dialogs/UpgradeStorageDialog";
import VueDropzoneFileUpload from "../Miscellaneous/VueDropzoneFileUpload.vue";
import ImageViewerDialog from "../Dialogs/ImageViewerDialog";
import { UpdateMediaMutation } from "@/gql";
import { SET_TASK_COMMENT_EDITOR_CONTENT } from "@/store/mutation-types";
import AddFormateOption from "@/components/Editor/AddFormateOption";

export default {
  name: "CommentEditor",
  props: {
    mentions: Array,
    workspace: Object,
    currentUser: Object,
    taskLabels: Array,
    isWorkspaceOwner: Boolean,
    imageList: Array,
    isloadingMedia: Boolean,
    openTaskComment: Boolean,
    openTaskCommentFocus: Boolean,
    forwardComment: Object,
    //fromMessage: Boolean,
    showBorder: {
      type: Boolean,
      default: false,
    },
    showAttatchmentIcon: {
      type: Boolean,
      default: true,
    },
    setMargin: {
      type: Boolean,
      default: false,
    },
    editCommentContent: {},
    task: Object,
    editDoc: Function,
    openFolder: Function,
  },
  mixins: [
    GoogleDriveMethodMixin,
    DropBoxMixin,
    CopiedImageOnEditor,
    UserScope,
  ],
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    company: {
      cacheKey: "CompanyQuery",
      defaultValue: null,
    },
  },
  data() {
    return {
      panel: [true],
      // content: null,
      fileUpload: {
        flag: false,
        dialog: false,
      },
      commentEditorMedia: true,
      upgradeStorageDialog: false,
      fileList: null,
      folderList: null,
      sharedFileDialog: false,
      showModal: false,
      dialogMedia: null,
      gridMediaId: "",
      ishide: false,
      hideButtonBack: "#231d3b !important",
      moreOption: [
        {
          title: "Add Comment",
        },
        {
          title: "Copy Link",
        },
      ],
      progress: 0,
      totalFile: 0,
      uploadCount: 0,
      commentFocused: this.openTaskCommentFocus ? true : false,
      videoCallLink: false,
      showAddLink: false,
      addLinkTitle:null,
      addLinkTextPosition:{
        start:null,
        end:null,
      },
    };
  },
  components: {
    ImageViewerDialog,
    Editor,
    Media,
    AddMediaPlus,
    SharedFilesDialog,
    UpgradeStorageDialog,
    VueDropzoneFileUpload,
    AddFormateOption,
  },
  beforeCreate() {
    this.$eventBus.$off("updateFileList");
  },
  created() {
    this.$eventBus.$on("updateFileList", this.updateFileList);
  },
  mounted() {
    //this.openTaskComment && this.$refs.commentEditor.$refs.editor.fireFocus();
  },
  methods: {
    openAddLink() {
      this.addLinkTextPosition.start = null;
      this.addLinkTextPosition.end = null;
      if(this.$refs.commentEditor.$refs.editor.value) {
        const { start, end } = this.$refs.commentEditor.$refs.editor.getSelection();
        this.addLinkTitle = this.$refs.commentEditor.$refs.editor.value.substring(start, end);
        this.addLinkTextPosition.start = start;
        this.addLinkTextPosition.end = end;  
      }
      this.showAddLink = true;
    },
    closeHyperlinkModal() {
      this.addLinkTitle = null;
      this.showAddLink = false;
    },
    toggleBold() {
      this.$refs.commentEditor.$refs.editor.toggleMark("*");
    },
    toggleItalic() {
      this.$refs.commentEditor.$refs.editor.toggleMark("_");
    },
    insertText(text) {
      if(this.addLinkTextPosition.start && this.addLinkTextPosition.end) {
        this.$refs.commentEditor.$refs.editor.insertText(text, false, this.$refs.commentEditor.$refs.editor.value.slice(this.addLinkTextPosition.start, this.addLinkTextPosition.end));
      } else {
        this.$refs.commentEditor.$refs.editor.insertText(text);
      }
    },
    copyVideoCallLink() {
      const videoCallLink = `https://${
        process.env.VUE_APP_MEET_URL
      }/${this.$generateString()}-${this.workspace.title
        .replace(/ /g, "-")
        .toLowerCase()}/${this.user.id}`;
      return videoCallLink;
    },
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const media = this.$api.getEntity("media", item.dataset.id);
      const tmp = [...this.sortedFiles];
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      media.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      this.$api.mutate({
        mutation: UpdateMediaMutation,
        skipUpdates: true,
        variables: {
          id: media.id,
          sequence: media.sequence,
        },
      });
    },
    reset() {
      this.content = null;
      this.fileList = {};
      this.folderList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
    },
    async createComment(outsourceMediaIds = []) {
      const optmedia =
        this.sortedFiles && this.sortedFiles.length > 0 ? this.sortedFiles : [];
      const folderIds = this.folderListArr.map((f) => f.id);
      // For Any drive Files
      const driveData = this.makeDriveData();

      if (this.videoCallLink === true) {
        if (this.content == null) {
          this.content = `https://${
            process.env.VUE_APP_MEET_URL
          }/${this.$generateString()}-${this.workspace.title
            .replace(/ /g, "-")
            .toLowerCase()}/${this.currentUser.id}`;
        } else {
          this.content = `${this.content} \n https://${
            process.env.VUE_APP_MEET_URL
          }/${this.$generateString()}-${this.workspace.title
            .replace(/ /g, "-")
            .toLowerCase()}/${this.currentUser.id}`;
        }

        this.videoCallLink = false;
      }

      this.$emit("submit", {
        text: this.content,
        tagged_user_ids: extractMentionsFromContent(
          this.content,
          this.mentionsList
        ),
        media: [...this.s3MediaIds, ...outsourceMediaIds],
        folder: folderIds, // Folder ids
        optmedia: optmedia,
        workspace_id: this.currentWorkspace,
        media_urls: driveData,
        mediafolder: this.folderList,
      });
      this.reset();
    },
    async submit() {
      if (this.canPostMessageFromWorkspace || this.isWorkspaceOwner) {
        if (!this.isFileUploading) {
          return;
        }
        this.progress = 0;
        if (this.$refs.commentEditor) {
          this.$refs.commentEditor.$refs.editor.resetTextArea();
        }
        if ((this.content && this.content !== "") || this.videoCallLink) {
          this.commonOutSourceFileHandler((outsourceMediaIds, code) => {
            if (code === 403) {
              this.upgradeStorageDialog = true;
            } else {
              this.createComment(outsourceMediaIds);
            }
          });
        }
      } else {
        this.reset();
        // this.$q.notify({
        //   color: "negative",
        //   position: "top-right",
        //   message: "Access Denied",
        //   timeout: 2500,
        //   icon: this.$icons.matAnnouncement,
        //   actions: [{ icon: this.$icons.matClose, color: "white" }],
        // });
      }
    },
    removeFolder(folder) {
      this.deleteFolderHandler(folder);
    },
    async removeMedia(media) {
      this.uploadCount = 0;
      this.deleteCommonMediaHandler(media);
      if (
        media.is_document_section == 1 ||
        (media.file_resource === "s3" && media["verifyKey"] !== "fromShared")
      ) {
        this.deleteMediaMutation(media);
      }
      this.$refs.vueFileUploader.removeMedia(media);
      if (!this.uploadedFileIds.length) {
        this.progress = 0;
      }
    },
    fullview(data) {
      if (data.newpoint === "yes") {
        const value = {
          title: "Add Comment",
          gridMediaId: this.gridMediaId,
          media: data.media,
          forscroll: "no",
          x: 0,
          y: 0,
        };
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
        this.clickonmoreoption(value);
      } else {
        this.dialogMedia = data.media;
        this.showModal = true;
        this.gridMediaId = data.media.id;
      }
    },
    resetDialog() {
      this.dialogMedia = null;
      this.showModal = false;
    },
    setActiveMedia(media) {
      this.dialogMedia = media;
      this.gridMediaId = media.id;
    },
    vuseScroll(obj) {
      this.$refs.mediaCommentingView.scrollTop = obj.scrollTop;
      this.$refs.mediaCommentingView.scrollLeft = obj.scrollLeft;
      const findContext =
        this.$refs.mediaCommentingView &&
        this.$refs.mediaCommentingView.$refs.commentBox &&
        this.$refs.mediaCommentingView.$refs.commentBox.find(
          (context) => context.menu
        );
      if (findContext) {
        findContext.menu = false;
      }
    },
    clickonmoreoption(item) {
      const data = {
        title: item.title,
        gridMediaId: item.media.id,
        media: item.media,
        forscroll: item.forscroll,
        x: item.x,
        y: item.y,
      };
      this.$emit("clickonmoreoption", data);
    },
    deleteCommentPoint(deletedata) {
      const makedata = {
        id: deletedata.id,
        index: deletedata.index,
        media_id: this.gridMediaId,
      };

      this.fileList[this.gridMediaId].commentPoint = this.fileList[
        this.gridMediaId
      ].commentPoint.filter((c) => c.id !== deletedata.id);
      this.$emit("deleteCommentPoint", makedata);
    },
    sendComment(data) {
      const commentArg = {
        media_comments_point_id: data.id,
        comment: data.comment,
        media_id: this.gridMediaId,
      };
      this.$emit("sendComment", commentArg);
    },
    deleteMediaComment(commentData) {
      const commentdata = {
        commentid: commentData.commentid,
        index: commentData.index,
        media_id: this.gridMediaId,
        commentPointId: commentData.commentpointid,
      };
      this.$emit("deleteMediaComment", commentdata);
    },
    updateFileList(data) {
      if (!this.fileList[data.media_id].verifyKey) {
        this.fileList[data.media_id].commentPoint.push(data);
      }
    },
    handleBlur(event) {
      this.commentFocused = false;
      this.$emit("blur", event);
    },
    handlePaste() {
      this.$emit("paste");
    },
    handleCommentFocus() {
      this.commentFocused = true;
    },
    setProgress(progress) {
      this.progress = progress;
      if (this.progress === 1) {
        setTimeout(() => {
          this.progress = 0;
        }, 100);
      }
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    getVideoCallLink() {
      this.videoCallLink = !this.videoCallLink;
    },
    cancelUpload() {
      this.$refs.vueFileUploader.cancelUpload();
      this.fileList = {};
      this.uploadCount = 0;
      this.totalFile = 0;
      setTimeout(() => {
        this.progress = 0;
      }, 10);
    },
  },
  computed: {
    ...mapGetters({
      companies: "auth",
      activeCompany: "activeCompany",
    }),
    content: {
      get() {
        return this.$store.getters.taskCommentEditorContent(
          this.currentCompany.companyId,
          this.currentWorkspace,
          this.task.id
        );
      },
      set(value) {
        this.$store.commit(SET_TASK_COMMENT_EDITOR_CONTENT, {
          value,
          companyId: this.currentCompany.companyId,
          workspaceId: this.currentWorkspace,
          taskId: this.task.id,
        });
      },
    },
    folderListArr() {
      return this.folderList
        ? uniqBy(
            Object.keys(this.folderList).map((key) => this.folderList[key]),
            "id"
          )
        : [];
    },
    repliedComment() {
      return this.forwardComment;
    },
    replyCommentContent() {
      if (this.repliedComment) {
        return compile(this.repliedComment.comment);
      }
      return "";
    },
    currentWorkspace() {
      return this.workspace ? this.workspace.id : -1;
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    mentionsList() {
      return this.mentions;
    },
    s3MediaIds() {
      const s3File = filter(
        this.fileList,
        (a) =>
          a.file_resource !== "outsource" &&
          (a.file_resource === "s3" || a.hasOwnProperty("verifyKey"))
      );
      const data = {};
      for (var media in s3File) {
        data[s3File[media].id] = s3File[media];
      }
      if (data) {
        return keys(data);
      } else {
        return [];
      }
    },
    uploadedFileIds() {
      if (this.fileList) {
        return map(
          filter(this.fileList, (media) => {
            if (
              media.file_resource !== "google" &&
              media.file_resource !== "dropbox"
            ) {
              return media;
            }
          }),
          (media) => media.id
        );
      } else {
        return [];
      }
    },
    sortedFiles() {
      return orderBy(
        this.uploadedFileIds.map((id) => this.$api.getEntity("media", id)),
        ["sequence"]
      );
    },
    outSourcedFiles() {
      return filter(this.fileList, (media) => {
        if (
          media.file_resource === "google" ||
          media.file_resource === "dropbox"
        ) {
          return media;
        }
      });
    },
    totalFiles() {
      return [...this.sortedFiles, ...this.outSourcedFiles];
    },
    counter() {
      if (this.content) {
        return this.content.toString().length;
      } else {
        return 0;
      }
    },
    isEnoughCharacter() {
      return this.counter > 20000;
    },
    returnpoints() {
      var vm = this;
      let filteredCommentPoints = filter(this.imageList, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredCommentPoints && filteredCommentPoints.length) {
        return filteredCommentPoints[0].commentPoint;
      }
      return [];
    },
    returnCommentListing() {
      var vm = this;
      let filteredComment = filter(this.imageList, (o) => {
        return o.id === vm.gridMediaId;
      });
      if (filteredComment && filteredComment.length) {
        return filteredComment[0].comments;
      }
      return [];
    },
    isFileUploading() {
      if (this.progress === 0 || this.progress === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    ishide: {
      handler: function (newValue) {
        if (newValue) {
          this.hideButtonBack = "#8E9DAD !important";
        } else {
          this.hideButtonBack = "#231d3b !important";
        }
      },
      deep: true,
    },
    repliedComment() {
      this.$nextTick(() => {
        this.$refs.commentEditor.$refs.editor.doEdit();
      });
    },
    uploadedFileIds() {
      this.panel = [true];
    },
    editCommentContent(to) {
      this.content = to;
      this.$nextTick(() => {
        this.$refs.commentEditor.$refs.editor.doEdit();
      });
    },
  },
};
</script>
