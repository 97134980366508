<template>
  <q-menu persistent :value="value" max-height="auto">
    <div class="q-px-md">
      <div class="custom-header">
        <q-btn
          :icon="$icons.mdiChevronLeft"
          round
          size="sm"
          class="help-button"
          style="margin-top: 5px;"
          @click="$emit('backToCFList')"
        ></q-btn>
        <div class="q-py-sm app-text">
          Edit field
        </div>
        <div
          class="new-close-button cursor-pointer"
          @click="$emit('closeMenu')"
        >
          <q-icon :name="$icons.matClose" class="close-icon" />
        </div>
      </div>
      <div class="q-mb-sm">
        <label class="app-text-normal tag-title">Title</label>
        <q-input
          v-model="selectedForEdit.title"
          placeholder="edit field"
          dense
          borderless
          class="field-input app-text-normal "
          type="text"
          @input="debounceFunction"
        >
        </q-input>
      </div>
      <div>
        <label class="app-text-normal tag-title">Type</label>
        <div
          class="selected-field-styles text-grey cursor-pointer"
          style="padding-left: 5%;"
        >
          <div class="app-text">
            {{ selectedForEdit.type | capitalize }}
          </div>
        </div>
      </div>
      <div
        v-sortable="{
          onAdd: onDragAddOrUpdate,
          onUpdate: onDragAddOrUpdate,
          handle: '.handle',
        }"
        class="overflow-auto q-py-sm"
        style="max-height:175px"
      >
        <div
          v-for="optionData in fieldOptions"
          :key="optionData.id"
          :data-id="optionData.id"
          class="row flex-no-wrap q-mt-sm relative-position"
        >
          <custom-field-option
            :optionData="optionData"
            :deleteOption="deleteOption"
            :updateCustomFieldOptionColor="updateCustomFieldOptionColor"
            :editOption="editOption"
            :updateOptionLabel="updateOptionLabel"
          />
        </div>
      </div>

      <div
        class="q-mt-md row items-centers justify-between flex-no-wrap"
        v-if="selectedForEdit.type === 'dropdown'"
      >
        <q-input
          v-model="inputOptionText"
          placeholder="Add item"
          dense
          borderless
          class="field-input app-text-normal "
          type="text"
          style="width: 140px;"
          @keydown.enter.exact="
            addOption({ ...selectedForEdit, inputOptionText });
            inputOptionText = null;
          "
        >
        </q-input>
        <div
          class="cursor-pointer row justify-center items-center add-dropdown-option"
          @click="
            addOption({ ...selectedForEdit, inputOptionText });
            inputOptionText = null;
          "
        >
          <q-icon
            style="font-weight:500"
            class="icon-color"
            size="15px"
            :name="$icons.fasPlus"
          />
        </div>
      </div>
      <div>
        <q-checkbox
          :value="selectedForEdit.show_in_task"
          @input="updateField(true)"
          size="xs"
          label="Show field on task-card"
          class="flex-1 app-text-normal q-mt-sm"
        />
      </div>
      <q-item
        clickable
        class="text-red app-text-normal"
        style="width: 95%; margin-bottom: 10px"
        @click="confirmRemoveFieldHandler(true)"
      >
        <q-item-section>
          Delete field
        </q-item-section>
      </q-item>
    </div>
  </q-menu>
</template>

<script>
import RandomPosition from "random-position";
import orderBy from "lodash/orderBy";
import debounce from "lodash/debounce";

import CustomFieldOption from "@/views/BoardView/CustomField/CustomFieldOption";
import { UpdateCustomFielOptiondMutation } from "@/gql";

export default {
  props: [
    "value",
    "selectedForEdit",
    "updateField",
    "deleteOption",
    "addOption",
    "deleteField",
    "confirmRemoveFieldHandler",
  ],
  components: {
    CustomFieldOption,
  },
  data() {
    return {
      inputOptionText: null,
      editOption: {
        id: null,
      },
    };
  },
  methods: {
    updateOptionLabel(optionData) {
      this.editOption.id = null;
      const variables = {
        id: optionData.id,
        label: optionData.label,
      };
      this.updateCustomFieldOption(variables);
    },
    debounceFunction: debounce(async function() {
      this.updateField();
    }, 300),
    onDragAddOrUpdate(event) {
      const { item, oldIndex, newIndex } = event;
      const customFieldOption = this.$api.getEntity(
        "customFieldOption",
        item.dataset.id
      );

      const tmp = [...this.fieldOptions];

      // move item from oldIndex to newIndex
      tmp.splice(newIndex, 0, tmp.splice(oldIndex, 1)[0]);
      customFieldOption.sequence = RandomPosition.between(
        tmp[newIndex - 1] ? tmp[newIndex - 1].sequence : RandomPosition.first(),
        tmp[newIndex + 1] ? tmp[newIndex + 1].sequence : RandomPosition.last()
      );
      const variables = {
        id: customFieldOption.id,
        sequence: customFieldOption.sequence,
      };
      this.updateCustomFieldOption(variables);
    },
    async updateCustomFieldOptionColor(option) {
      const tempColor = option.swatchColor;
      const optionEntity = this.$api.getEntity("customFieldOption", option.id);
      try {
        optionEntity.colorcode = option.swatchColor;
        const variables = {
          id: option.id,
          colorcode: option.swatchColor,
        };
        this.updateCustomFieldOption(variables);
      } catch (error) {
        optionEntity.colorcode = tempColor;
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong!",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async updateCustomFieldOption(variables) {
      await this.$api.mutate({
        mutation: UpdateCustomFielOptiondMutation,
        variables,
      });
    },
  },
  computed: {
    fieldOptions() {
      return orderBy(this.selectedForEdit.customFieldOption, ["sequence"]);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-header {
  display: flex;
  width: 200px;
}
.space {
  margin-left: 6%;
  margin-right: 1%;
}
.close-button {
  margin-top: 1px;
  margin-left: 45px;
  font-size: 20px;
}
.new-close-button {
  margin-top: 1px;
  margin-left: 91px;
  font-size: 20px;
}
.field-input {
  height: 20px !important;
  border-radius: 5px;
  border: solid 1px #b8c1cb;
  background-color: #f4f5f8;
  padding-bottom: 29px;
  padding-left: 8px;
}
.tag-title {
  padding-bottom: 5px;
}
.selected-field-styles {
  height: 30px;
  background-color: #f4f5f8;
  display: flex;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 2%;
  border-radius: 6px;
}
</style>
