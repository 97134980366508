import get from "lodash/get";
import getUnixTime from "date-fns/getUnixTime";
import { mapGetters } from "vuex";
import mixpanel from "@/mixpanel";
import {
  GetBoardDetailMutation,
  GetNewMediaListMutation,
  CreateSubTaskMutation,
  ClearSubTaskMutation,
  ClearChildSubTaskMutation,
  CreateTaskCommentMutation,
  DeleteTaskMutation,
  GetTaskMutation,
  AttachTaskMediaMutation,
  DetachTaskMediaMutation,
  DeleteTaskCommentMutation,
  GetNewCardMutation,
  GetCardMutation,
  GetNewTaskMutation,
  GetTaskCommentMutation,
  GetSubTaskMutation,
  ClearBookmarkTaskMutation,
  DeleteTasksMutation,
  AddTaskCommentReactionMutation,
  RemoveTaskCommentReactionMutation,
  UpdateNotificationsMutation,
  UpdateCompanySettingsMutation,
} from "@/gql";
import { TaskQuery } from "../gql";

export default {
  data() {
    return {
      openSubtask: false,
      openTaskComment: false,
      onLine: navigator.onLine,
      showBackOnline: false,
    };
  },
  methods: {
    async updateTask() {
      if (this.task)
        this.$api.query({
          query: TaskQuery,
          variables: {
            id: this.task.id,
          },
        });
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    async reactivityOnUpdateBoard() {
      await this.$api.mutate({
        mutation: GetBoardDetailMutation,
        variables: {
          id: this.board.id,
        },
      });

      this.$store.dispatch("updateBoard", {
        ...this.channelDetails,
        flag: false,
      });
    },
    editTask(obj) {
      this.task = { ...obj.task };
      this.taskDialog = true;
      if (obj.isOpen === "openSubtask") {
        this.openSubtask = true;
      } else if (obj.isOpen === "taskComment") {
        this.openTaskComment = true;
      }
    },
    taskDialogSuccessHandler() {
      this.resetTaskDialog();
    },
    resetTaskDialog() {
      this.task = null;
      this.openSubtask = false;
      this.openTaskComment = false;
      this.taskDialog = false;
    },
    async updateMediaCache(mediaIds) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });

      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      query.data.newMedia.push(...response.data.getNewMediaList);
    },
    async createSubTask(args) {
      // if(args){
      //   return args;
      // }
      // console.log("args", args);
      // const companyEntity = this.$api.getEntity(
      //   "company",
      //   this.currentCompany.companyId
      // );
      // let vm = this;
      // let commentArr = args.title.split(" ");
      // for (let i = 0; i < commentArr.length; i++) {
      //   let item = commentArr[i];
      //   if ((item.startsWith('S') || item.startsWith('T')) && item.includes("-")){
      //     let taskArr =  item.split("-");
      //     const { data } = await vm.$api.query({
      //       query: TaskQuery,
      //       variables: {
      //         id: taskArr[1].replace(/[^a-zA-Z0-9]/g, '').trim(),
      //       },
      //     });
      //     let url;
      //     if(data.task) {
      //       let workspaceName = taskArr[0].substring(1);
      //       let workspace_id = data.task.card ? data.task.card.board.workspace_id : data.task.card_id;
      //       url = `${process.env.VUE_APP_APPBASE_URL}/companies/${companyEntity.uri}/workspaces/${workspace_id}/board?task=${data.task.id}&workspace=${workspace_id}&workspaceName=${workspaceName}`;
      //     }

      //     if(taskArr.length > 2) {
      //       url +="&sub_task="+taskArr[2];
      //     }
      //     commentArr[i] = url;
      //   }
      // }
      // args.title = commentArr.join(" ");

      const variables = {
        id: args.id,
        title: args.title,
        completed: args.completed,
        task_id: args.task_id,
        sequence: args.sequence,
        media: args.uploadedFileIds,
        workspace_id: args.workspace_id,
        media_urls: args.media_urls,
        folder: args.folder, // Folder ids
        parent_id: args.parent_id ? args.parent_id : null,
      };

      const fakeSubTask = {
        __typename: "subTask",
        owner: {
          __typename: "user",
          id: this.user.id,
          first: this.user.first,
          last: this.user.last,
          pic: this.user.pic,
        },
        ...variables,
        members: [],
        childSubtasks:[],
        media: args.optmedia,
        mediafolder: args.mediafolder,
      };
      if(args.parent_id) {
        const subtaskEntity = this.$api.getEntity("subTask", args.parent_id);
        subtaskEntity.childSubtasks.push(fakeSubTask);

        const response = await this.$api.mutate({
          mutation: CreateSubTaskMutation,
          variables,
        });
        mixpanel.track("Task Subtask Create");
        subtaskEntity.childSubtasks = subtaskEntity.childSubtasks.map((subtask) =>
          subtask === fakeSubTask ? response.data.createSubTask : subtask
        );  
      }else {
        const taskEntity = this.$api.getEntity("task", args.task_id);
        taskEntity.subtasks.push(fakeSubTask);

        const response = await this.$api.mutate({
          mutation: CreateSubTaskMutation,
          variables,
        });
        mixpanel.track("Task Subtask Create");
        taskEntity.subtasks = taskEntity.subtasks.map((subtask) =>
          subtask == fakeSubTask ? response.data.createSubTask : subtask
        );
      }
    },
    async clearSubtasks(args) {
      const variables = {
        id: args.id,
        completed: args.completed,
      };
      await this.$api.mutate({
        mutation: ClearSubTaskMutation,
        variables,
      });
      mixpanel.track("Task Subtasks Clear", { completed: args.completed });
      const taskEntity = this.$api.getEntity("task", args.id);
      taskEntity.subtasks = taskEntity.subtasks.filter(
        (s) => s.completed !== args.completed
      );
    },
    async clearChildSubtasks(args) {
      const variables = {
        id: args.id,
        completed: args.completed,
      };
      await this.$api.mutate({
        mutation: ClearChildSubTaskMutation,
        variables,
      });
      mixpanel.track("Task Subtasks Clear", { completed: args.completed });
      const subtaskEntity = this.$api.getEntity("subTask", args.id);
      subtaskEntity.childSubtasks = subtaskEntity.childSubtasks.filter(
        (s) => s.completed !== args.completed
      );
    },
    async createComment(args) {
      const variables = {
        task_id: args.task_id,
        workspace_id: args.workspace_id,
        comment: args.comment,
        task_comment_id: args.task_comment_id,
        tagged_user_ids: args.tagged_user_ids,
        media: args.media,
        folder: args.folder,
        media_urls: args.media_urls,
      };
      const fakeComment = {
        __typename: "taskComment",
        ...variables,
        id: Math.round(Math.random() * -1000000),
        user_id: this.user.id,
        created_at: getUnixTime(new Date()),
        updated_at: getUnixTime(new Date()),
        is_bot: false,
        owner: {
          __typename: "user",
          id: this.user.id,
          first: this.user.first,
          last: this.user.last,
          username: this.user.username,
          pic: this.user.pic,
        },
        sharedTaskComment: args.fullComment,
        media: args.optmedia,
        mediafolder: args.mediafolder,
        commentreactiondata: [],
        commentreactionset: [],
      };

      const taskCommentQuery = this.$api.getQuery(
        `TaskCommentsQuery:${args.task_id}`
      );
      taskCommentQuery.data.taskComments.unshift(fakeComment);

      const response = await this.$api.mutate({
        mutation: CreateTaskCommentMutation,
        variables,
      });

      taskCommentQuery.data.taskComments = taskCommentQuery.data.taskComments.map(
        (comment) =>
          comment === fakeComment ? response.data.createTaskComment : comment
      );
    },
    deleteTaskConfirmBoxHandler(task) {
      this.confirmBox.taskRemove = {
        flag: true,
        id: task.id,
        loader: false,
      };
    },
    resetTaskRemoveConfirmbox() {
      this.confirmBox.taskRemove = {
        flag: false,
        id: null,
        loader: false,
      };
    },
    async deleteTask() {
      this.confirmBox.taskRemove.loader = true;
      if (this.confirmBox.taskRemove.id !== null) {
        const id = this.confirmBox.taskRemove.id;
        const variables = {
          id,
        };

        await this.$api.mutate({
          mutation: DeleteTaskMutation,
          variables,
        });
        if (this.isPrivateTask) {
          const privateTasksQuery = this.$api.getQuery(
            `PrivateTasksQuery:${this.board.id}`
          );
          privateTasksQuery.data.privateTasks = privateTasksQuery.data.privateTasks.filter(
            (task) => task.id !== id
          );
        }
        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);

        query.data.board.tasks = query.data.board.tasks.filter(
          (task) => task.id !== id
        );

        const companyEntity = this.$api.getEntity(
          "company",
          this.currentCompany.companyId
        );
        if (companyEntity.start_task_id === id) {
          companyEntity.start_task_id = null;
          companyEntity.start_time_log_id = null;
          const companyVariables = {
            name: companyEntity.name,
            start_task_id: null,
            start_time_log_id: null,
          };
          await this.$api.mutate({
            mutation: UpdateCompanySettingsMutation,
            variables: companyVariables,
          });
        }

        this.resetTaskRemoveConfirmbox();
        mixpanel.track("Task Delete");
      } else {
        this.resetTaskRemoveConfirmbox();
      }
    },
    async reactivityOnUpdateTaskMembers(data) {
      if (data) {
        await this.$api.mutate({
          mutation: GetTaskMutation,
          variables: {
            id: data.id,
          },
        });
      }
    },
    async attachTaskMedia(args) {
      const variables = {
        media: args.mediaIds,
        task_id: args.task_id,
      };
      const response = await this.$api.mutate({
        mutation: AttachTaskMediaMutation,
        variables,
      });

      const taskEntity = this.$api.getEntity("task", args.task_id);
      taskEntity.media.push(...response.data.attachTaskMedia);
    },
    async detachTaskAttachment(args) {
      const variables = {
        task_id: args.task_id,
        media: [args.id],
      };
      await this.$api.mutate({
        mutation: DetachTaskMediaMutation,
        variables,
      });

      const taskEntity = this.$api.getEntity("task", args.task_id);
      taskEntity.media = taskEntity.media.filter((f) => f.id !== args.id);
    },
    async deleteTaskComment(args) {
      const variables = {
        ...args,
      };
      const taskCommentQuery = this.$api.getQuery(
        `TaskCommentsQuery:${args.task_id}`
      );
      taskCommentQuery.data.taskComments = taskCommentQuery.data.taskComments.filter(
        (c) => c.id !== args.id
      );

      await this.$api.mutate({
        mutation: DeleteTaskCommentMutation,
        variables,
      });
      mixpanel.track("Task Comment Delete");
    },
    async reactivityOnUpdateTask(data) {
      if (data) {
        await this.$api.mutate({
          mutation: GetTaskMutation,
          variables: {
            id: data.id,
          },
        });
      }
    },
    async reactivityOnDeleteTask(task) {
      if (task) {
        const { data } = this.$api.getQuery(`BoardQuery:${this.board.id}`);

        task.id.forEach((id) => {
          let itemIndex = data.board.tasks.findIndex((i) => i.id == id);
          data.board.tasks.splice(itemIndex, 1);
        });
      }
    },
    async reactivityOnAddCardToBoard(card) {
      if (card) {
        const response = await this.$api.mutate({
          mutation: GetNewCardMutation,
          variables: {
            board_id: this.board.id,
            id: card.id,
          },
        });

        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        query.data.board.cards.push(response.data.getNewCard);
      }
    },
    async reactivityOnUpdateCard(data) {
      if (data) {
        await this.$api.mutate({
          mutation: GetCardMutation,
          variables: {
            id: data.cardId,
          },
        });
      }
    },
    async reactivityOnDeleteCard(card) {
      if (card) {
        const { data } = this.$api.getQuery(`BoardQuery:${this.board.id}`);

        const cards = data.board.cards.filter((o) => o.id !== card.cardId);
        data.board.cards = cards;
      }
    },
    async reactivityOnAddTaskToBoard(task) {
      if (task) {
        const variables = {
          board_id: this.board.id,
          id: task.id,
        };

        const response = await this.$api.mutate({
          mutation: GetNewTaskMutation,
          variables,
        });

        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        query.data.board.tasks.push(response.data.getNewTask);
      }
    },
    async reactivityOnSubtaskAdded(task) {
      if (task) {
        await this.$api.mutate({
          mutation: GetTaskMutation,
          variables: {
            id: task.id,
          },
        });
      }
    },
    // async reactivityOnSubtaskDeleted(subtask) {
    //   if (subtask) {
    //     const { data } = this.$api.getQuery(`TaskQuery:${subtask.task_id}`);
    //     const task = data.task;
    //     task.subtasks.splice(
    //       findIndex(task.subtasks, (o) => o.id === subtask.id),
    //       1
    //     );
    //   }
    // },
    async reactivityOnTaskCommentAdded(task) {
      if (task) {
        this.$api.query({
          query: TaskQuery,
          variables: {
            id: task.id,
          },
        });
      }
    },
    async reactivityOnTaskAddAttachment(task) {
      if (task) {
        this.$api.query({
          query: TaskQuery,
          variables: {
            id: task.id,
          },
        });
      }
    },
    async reactivityOnTaskRemoveAttachment(attachment) {
      if (attachment) {
        this.$api.query({
          query: TaskQuery,
          variables: {
            id: attachment.task_id,
          },
        });
      }
    },
    async reactivityOnTaskCommentUpdated(taskComment) {
      await this.$api.mutate({
        mutation: GetTaskCommentMutation,
        variables: {
          id: taskComment.id,
        },
      });
    },
    async reactivityOnSubtaskUpdated(subtask) {
      await this.$api.mutate({
        mutation: GetTaskMutation,
        variables: {
          id: subtask.task_id,
        },
      });
      await this.$api.mutate({
        mutation: GetSubTaskMutation,
        variables: {
          id: subtask.id,
        },
      });
    },
    clearBookmarkedConfirmHandler(flag) {
      if (flag) {
        this.clearAllBookmarked();
      }
      this.clearBookmarkedConfirm = false;
    },
    async clearAllBookmarked() {
      let task_ids = this.board.tasks
        .filter((o) => o.isbookmarked === true)
        .map((e) => e.id);
      if (task_ids > 0) {
        await this.$api.mutate({
          mutation: ClearBookmarkTaskMutation,
          variables: {
            task_ids,
          },
        });
        mixpanel.track("Task Bookmark Remove All");
        const workspaceEntity = this.$api.getEntity(
          "workspace",
          this.workspace.id
        );
        workspaceEntity.hasbookmarked = 0;
        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        query.data.board.tasks.forEach((t) => {
          t.isbookmarked = false;
        });
      }
    },
    async deleteTaskByIdHandler(ids) {
      const variables = {
        ids,
      };

      await this.$api.mutate({
        mutation: DeleteTasksMutation,
        variables,
      });
      if (this.isPrivateTask) {
        const privateTasksQuery = this.$api.getQuery(
          `PrivateTasksQuery:${this.board.id}`
        );
        privateTasksQuery.data.privateTasks = privateTasksQuery.data.privateTasks.filter(
          (task) => !ids.includes(task.id)
        );
      } else {
        const query = this.$api.getQuery(`BoardQuery:${this.board.id}`);
        query.data.board.tasks = query.data.board.tasks.filter(
          (task) => !ids.includes(task.id)
        );
      }
    },
    addTaskAttchmentToMedia(newMedia) {
      const { data } = this.$api.getQuery(
        `MediaQuery:${this.currentWorkspace}`
      );

      data.newMedia.unshift(newMedia);
    },
    async updateMediaCacheWithReactivity(mediaIds) {
      const response = await this.$api.mutate({
        mutation: GetNewMediaListMutation,
        variables: {
          ids: mediaIds,
        },
      });

      const query = this.$api.getQuery(`MediaQuery:${this.currentWorkspace}`);
      query.data.newMedia.push(...response.data.getNewMediaList);
    },
    async reactOnPost(args) {
      let { comment, reaction, fromNotification, noticationId } = args;
      if (typeof reaction !== "object") {
        reaction = this.reactions.find((o) => o.name === reaction);
      }
      const variables = {
        id: comment.id,
        reaction_id: reaction.id,
      };
      const newReactiondata = { ...comment.commentreactiondata };

      if (newReactiondata.hasOwnProperty(reaction.name)) {
        if (
          newReactiondata[reaction.name].map((a) => a.id).includes(this.user.id)
        ) {
          if (newReactiondata[reaction.name].length > 1) {
            let index = newReactiondata[reaction.name].findIndex(
              (a) => a.id === this.user.id
            );
            newReactiondata[reaction.name].splice(index, 1);
          } else {
            delete newReactiondata[reaction.name];
          }
          if (fromNotification) {
            const query = this.$api.getQuery("NotificationsQuery");
            if (query.data) {
              query.data.notifications.map((n) => {
                if (n.id === noticationId) {
                  n.action.object.commentreactiondata = { ...newReactiondata };
                }
              });
            }
          } else {
            const query = this.$api.getQuery("NotificationsQuery");
            if (query.data) {
              query.data.notifications.map((n) => {
                if (n.action.object_id === comment.id) {
                  n.action.object.commentreactiondata = { ...newReactiondata };
                }
              });
            }
          }
          this.$api.updateEntity("taskComment", comment.id, {
            commentreactiondata: newReactiondata,
          });
          this.scrollToBottomComment();
          await this.$api.mutate({
            mutation: RemoveTaskCommentReactionMutation,
            variables,
            skipUpdates: true,
          });
        } else {
          newReactiondata[reaction.name].push({
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          });
          if (fromNotification) {
            const query = this.$api.getQuery("NotificationsQuery");
            if (query.data) {
              query.data.notifications.map((n) => {
                if (n.id === noticationId) {
                  n.action.object.commentreactiondata = { ...newReactiondata };
                }
              });
            }
          } else {
            const query = this.$api.getQuery("NotificationsQuery");
            if (query.data) {
              query.data.notifications.map((n) => {
                if (n.action.object_id === comment.id) {
                  n.action.object.commentreactiondata = { ...newReactiondata };
                }
              });
            }
          }
          this.readTaskCommentNotifications(comment.id, comment.task_id);
          this.scrollToBottomComment();
          this.$api.updateEntity("taskComment", comment.id, {
            commentreactiondata: newReactiondata,
          });
          await this.$api.mutate({
            mutation: AddTaskCommentReactionMutation,
            variables,
            skipUpdates: true,
          });
          mixpanel.track("Task Comment Reaction", { name: reaction.name });
        }
      } else {
        newReactiondata[reaction.name] = [
          {
            first: this.user.first,
            fullname: this.user.first + " " + this.user.last,
            id: this.user.id,
            is_deleted: null,
            last: this.user.last,
            time: getUnixTime(new Date()),
            pic: this.user.pic,
          },
        ];
        if (fromNotification) {
          const query = this.$api.getQuery("NotificationsQuery");
          if (query.data) {
            query.data.notifications.map((n) => {
              if (n.id === noticationId) {
                n.action.object.commentreactiondata = { ...newReactiondata };
              }
            });
          }
        } else {
          const query = this.$api.getQuery("NotificationsQuery");
          if (query.data) {
            query.data.notifications.map((n) => {
              if (n.action.object_id === comment.id) {
                n.action.object.commentreactiondata = { ...newReactiondata };
              }
            });
          }
        }
        this.readTaskCommentNotifications(comment.id, comment.task_id);
        this.scrollToBottomComment();
        this.$api.updateEntity("taskComment", comment.id, {
          commentreactiondata: newReactiondata,
        });

        await this.$api.mutate({
          mutation: AddTaskCommentReactionMutation,
          variables,
          skipUpdates: true,
        });
        mixpanel.track("Task Comment Reaction", { name: reaction.name });
      }
    },
    scrollToBottomComment() {
      setTimeout(() => {
        document.querySelector(
          "#commentScroll"
        ).scrollTop = document.querySelector("#commentScroll").scrollHeight;
      }, 10);
    },
    async readTaskCommentNotifications(commentId, taskId) {
      const query = this.$api.getQuery("NotificationsQuery");
      let notification_ids;
      if (query.data) {
        notification_ids = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              n.action.type === "task-comment-created" &&
              n.action.object_id === commentId &&
              n.action.object.task_id === taskId
          )
          .map((n) => {
            n.read = true;
            return n.id;
          });
        if (notification_ids.length) {
          const variables = {
            notification_ids: notification_ids,
            read: true,
          };
          await this.$api.mutate({
            mutation: UpdateNotificationsMutation,
            variables,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
      companies: "auth",
    }),
    channelDetails() {
      if (this.workspace && this.board) {
        return {
          companyId: this.currentCompany.companyId,
          workspace: this.workspace.id,
          boardId: this.board.id,
        };
      }
      return null;
    },
    editTaskdata: {
      get() {
        if (this.task) {
          const task = this.$api.getEntity("task", this.task.id);
          if (this.task.boardId || this.task.board_id || task) {
            return task;
          } else {
            if (!this.task.card_id) {
              const privateTasksQuery = this.$api.getQuery(
                `PrivateTasksQuery:${this.board.id}`
              );
              return privateTasksQuery.data.privateTasks.find(
                (o) => o.id === this.task.id
              );
            }
            if (this.board) {
              return this.board.tasks.find((o) => o.id === this.task.id);
            }
            return task;
          }
        } else {
          return null;
        }
      },
    },
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : "";
    },
    currentCompany() {
      if (this.companies && this.activeCompany) {
        return get(this.companies, this.activeCompany);
      } else {
        return null;
      }
    },
    companyId() {
      if (this.currentCompany) {
        return this.currentCompany.companyId;
      }
      return null;
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.updateTask();
        }, 1000);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
