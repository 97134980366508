var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q-mr-sm"},[(_vm.activeField.is_active)?_c('div',{class:[
      _vm.selectedOptionColor ? 'text-white' : 'text-grey',
      'selected-field-styles cursor-pointer relative-position q-pl-sm',
    ],style:({
      'background-color': _vm.selectedOptionColor
        ? _vm.selectedOptionColor
        : '#f4f5f8',
    }),on:{"click":function($event){return _vm.$emit('editField')}}},[_c('q-icon',{style:({ color: _vm.selectedOptionColor ? '#fff' : '#919ca8' }),attrs:{"name":_vm.activeField.title === 'Status'
          ? _vm.$icons.matFlag
          : _vm.activeField.title === 'Effort'
          ? _vm.$icons.mdiChevronDoubleUp
          : _vm.activeField.type === 'checkbox'
          ? _vm.$icons.matCheckCircle
          : _vm.activeField.type === 'date'
          ? _vm.$icons.fasCalendarAlt
          : _vm.activeField.type === 'number'
          ? _vm.$icons.mdiPound
          : _vm.activeField.type === 'text'
          ? _vm.$icons.matDescription
          : _vm.$icons.matPriorityHigh,"size":"xs"}}),_c('q-icon',{style:({
        color: _vm.selectedOptionColor ? '#fff' : '#919ca8',
        'margin-left': '-10px',
      }),attrs:{"name":_vm.activeField.title !== 'Status' &&
        _vm.activeField.title !== 'Effort' &&
        _vm.activeField.type === 'dropdown'
          ? _vm.$icons.matPriorityHigh
          : '',"size":"xs"}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"app-text ellipses q-ml-sm",staticStyle:{"position":"relative","max-width":"80px"}},[_vm._v(" "+_vm._s(_vm.activeField.title)+" "),(_vm.isTruncated)?_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.activeField.title)+" ")]):_vm._e()],1),_c('div',{staticClass:"cursor-pointer",staticStyle:{"position":"absolute","right":"5px"},on:{"click":function($event){$event.stopPropagation();return _vm.duplicateCustomField(_vm.activeField)}}},[_c('q-icon',{attrs:{"size":"18px","name":_vm.$icons.mdiContentCopy}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }