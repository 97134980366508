<template>
  <div
    class="border-radius10 relative-position q-mr-sm media-card-hover cursor-pointer"
    style="width: 151px;height: 151px;"
    @click="openFolder(folder)"
  >
    <q-tooltip>
      {{ folder.title }}
    </q-tooltip>
    <div
      class="absolute-full fill-height full-width media-card-hover-item cursor-pointer folder-hover-item "
      style="height: 151px;border-radius:10px"
    >
      <div
        class="task-menu-option thread-icons q-px-xs q-mt-sm q-mr-xs group-hover-item row items-center"
        style="z-index: 100"
      >
        <div
          v-if="folder.isbookmarked"
          @click.stop="removeMediaFolderBookmark(folder)"
        >
          <q-icon
            size="20px"
            class="cursor-pointer"
            color="pink"
            :name="$icons.matBookmark"
          >
            <q-tooltip>
              Remove from Saved items
            </q-tooltip>
          </q-icon>
        </div>
        <q-btn
          text-color="black"
          size="13px"
          round
          dense
          padding="0"
          class="q-ml-xs q-button-hover"
          :icon="$icons.matMoreHoriz"
          @click.stop
        >
          <q-menu content-style="min-width:150px" auto-close>
            <q-list>
              <q-item
                @click="deleteFolderHandler(folder.id)"
                clickable
                class="text-red"
              >
                <q-item-section> Delete </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
    <div class="saved-folder column justify-center items-center">
      <div class="relative-position">
        <q-icon :name="$icons.matFolder" class="saved-folder-icon" />
        <div class="saved-folder-count">
          {{ folder.media_count > 9 ? "9+" : folder.media_count }}
        </div>
      </div>
      <div class="saved-folder-title ellipses">
        {{ folder.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "folder",
    "openFolder",
    "removeMediaFolderBookmark",
    "deleteFolderHandler",
  ],
};
</script>
<style lang="scss" scoped>
.saved-folder {
  border-radius: 10px;
  background-color: #edf6ff;
  height: 151px;
  .saved-folder-icon {
    font-size: 120px;
    color: #64b9fc;
  }
  .saved-folder-title {
    font-family: "Avenir Next";
    font-size: 11px;
    font-weight: 500;
    color: #000;
    width: 100%;
    padding: 0 10px;
    text-align: center;
    z-index: 1;
  }
  .saved-folder-count {
    font-family: "Avenir Next";
    font-size: 18px;
    font-weight: 500;
    color: #b7dfff;
    position: absolute;
    top: 30px;
    right: 18px;
  }
}
</style>
