<template>
  <div class="q-mr-sm">
    <div
      :class="[
        selectedOptionColor ? 'text-white' : 'text-grey',
        'selected-field-styles cursor-pointer relative-position q-pl-sm',
      ]"
      :style="{
        'background-color': selectedOptionColor
          ? selectedOptionColor
          : '#f4f5f8',
      }"
      v-if="activeField.is_active"
      @click="$emit('editField')"
    >
      <q-icon
        :name="
          activeField.title === 'Status'
            ? $icons.matFlag
            : activeField.title === 'Effort'
            ? $icons.mdiChevronDoubleUp
            : activeField.type === 'checkbox'
            ? $icons.matCheckCircle
            : activeField.type === 'date'
            ? $icons.fasCalendarAlt
            : activeField.type === 'number'
            ? $icons.mdiPound
            : activeField.type === 'text'
            ? $icons.matDescription
            : $icons.matPriorityHigh
        "
        :style="{ color: selectedOptionColor ? '#fff' : '#919ca8' }"
        size="xs"
      />
      <q-icon
        :name="
          activeField.title !== 'Status' &&
          activeField.title !== 'Effort' &&
          activeField.type === 'dropdown'
            ? $icons.matPriorityHigh
            : ''
        "
        :style="{
          color: selectedOptionColor ? '#fff' : '#919ca8',
          'margin-left': '-10px',
        }"
        size="xs"
      />
      <div
        class="app-text ellipses  q-ml-sm"
        style="position:relative;max-width:80px"
        v-tooltip
      >
        {{ activeField.title }}
        <q-tooltip v-if="isTruncated">
          {{ activeField.title }}
        </q-tooltip>
      </div>
      <div
        @click.stop="duplicateCustomField(activeField)"
        class="cursor-pointer"
        style="position:absolute; right: 5px;"
      >
        <q-icon size="18px" :name="$icons.mdiContentCopy" />
      </div>
      <!-- <div style="position:absolute; right: 5px;">
        <q-icon :name="$icons.mdiChevronRight" size="20px" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["activeField", "task", "duplicateCustomField"],
  data() {
    return {
      isTruncated: false,
    };
  },
  computed: {
    selectedOptionColor() {
      const dropdpownCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.activeField.id
      );
      const selectedOptionColor =
        dropdpownCFSelection &&
        this.activeField.customFieldOption.find(
          (o) => o.id === dropdpownCFSelection.c_f_o_id
        );
      return selectedOptionColor ? selectedOptionColor.colorcode : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.selected-field-styles {
  height: 30px;
  max-width: 175px;
  display: flex;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 2%;
  border-radius: 6px;
}
</style>
