import Vue from "vue";
import map from "lodash/map";
import {
	AddMediaBookmarkMutation,
	RemoveMediaBookmarkMutation,
	CopyMediaMutation,
	MoveMediaMutation,
	AttachFolderMediaMutation,
	RenameMediaFolderMutation,
	DeleteMediaFolderMutation,
	MediaFoldersQuery,
	CopyMediaFolderMutation,
	MoveMediaFolderMutation,
	AddMediaFolderBookmarkMutation,
	RemoveMediaFolderBookmarkMutation,
} from "@/gql";
export default {
	data() {
		return {
			copyMoveDialog: {
				data: [],
				isFolder: false,
				isMoveAction: false,
				isRoot: true,
				flag: false,
				loading: false,
			},
			createMediaFolderDialog: {
				flag: false,
				loading: false,
			},
			isTruncated: false,
			renameFolderDetails: null,
			deleteFolder: {
				id: null,
				flag: false,
				loader: false,
			},
			folderDetailsDialog: {
				flag: false,
				data: null,
			},
		};
	},
	methods: {
		openFolder(folder) {
			this.folderDetailsDialog.flag = true;
			this.folderDetailsDialog.data = folder;
		},
		closeFolder() {
			this.folderDetailsDialog.flag = false;
			this.folderDetailsDialog.data = null;
			this.updateFolderCache();
		},
		async updateFolderCache() {
			const variables = {
				workspace_id: parseInt(this.currentWorkspace),
			};
			await this.$api.query({
				query: MediaFoldersQuery,
				cacheKey() {
					return `MediaFoldersQuery:${parseInt(
						this.currentWorkspace
					)}`;
				},
				variables,
			});
		},
		async deleteFolderHandler(folderId) {
			this.deleteFolder.id = folderId;
			this.deleteFolder.flag = true;
		},
		async deleteFolderDialogHandler(flag = false) {
			if (flag) {
				this.deleteFolder.loader = true;
				try {
					const variables = {
						id: this.deleteFolder.id,
					};
					await this.$api.mutate({
						mutation: DeleteMediaFolderMutation,
						variables,
					});
					const query = this.$api.getQuery(
						`MediaFoldersQuery:${this.currentWorkspace}`
					);
					const savedItemsQuery = this.$api.getQuery(
						"SavedItemsQuery"
					);
					if (savedItemsQuery.data) {
						savedItemsQuery.data.savedItems.media_folder = savedItemsQuery.data.savedItems.media_folder.filter(
							(item) => item.id != this.deleteFolder.id
						);
					}
					if (query.data) {
						query.data.mediaFolders = query.data.mediaFolders.filter(
							(f) => f.id !== this.deleteFolder.id
						);
					}
				} catch (error) {
					this.$q.notify({
						color: "negative",
						position: "top-right",
						message: "Something went wrong",
						timeout: 2500,
						icon: this.$icons.matAnnouncement,
						actions: [
							{ icon: this.$icons.matClose, color: "white" },
						],
					});
				}
			}
			this.deleteFolder.id = null;
			this.deleteFolder.flag = false;
			this.deleteFolder.loader = null;
		},
		openRenameFolderDialog(folder) {
			this.renameFolderDetails = folder;
			this.createMediaFolderDialog.flag = true;
		},
		async renameFolderHandler(title) {
			this.createMediaFolderDialog.loading = true;
			if (this.renameFolderDetails) {
				try {
					const variables = {
						id: this.renameFolderDetails.id,
						title,
					};
					await this.$api.mutate({
						mutation: RenameMediaFolderMutation,
						variables,
					});
					const query = this.$api.getQuery(
						`MediaFoldersQuery:${this.currentWorkspace}`
					);
					//cache update
					query.data.mediaFolders = query.data.mediaFolders.map(
						(f) => {
							if (f.id === this.renameFolderDetails.id) {
								f.title = title;
							}
							return f;
						}
					);

					this.createMediaFolderDialog.loading = false;
					this.createMediaFolderDialog.flag = false;
					this.renameFolderDetails = null;
				} catch (error) {
					this.$q.notify({
						color: "negative",
						position: "top-right",
						message: "Something went wrong",
						timeout: 2500,
						icon: this.$icons.matAnnouncement,
						actions: [
							{ icon: this.$icons.matClose, color: "white" },
						],
					});
					this.createMediaFolderDialog.loading = false;
					this.createMediaFolderDialog.flag = false;
					this.renameFolderDetails = null;
				}
			}
		},
		attachFolderToPost(folders) {
			let value = {};
			const allFolders = this.$store.getters.workspaceEditorFolders(
				this.currentCompany.companyId,
				this.currentWorkspace
			);

			if (allFolders) {
				value = allFolders;
			}
			folders.forEach((f) => {
				Vue.set(value, f.id, f);
			});
			this.$store.dispatch("setWorkspaceEditorFolder", {
				value,
				companyId: this.currentCompany.companyId,
				workspaceId: this.currentWorkspace,
			});
			if (this.$route.name !== "MessagingView") {
				this.$router.push(
					`/companies/${this.activeCompany}/workspaces/${this.currentWorkspace}/messaging`
				);
			}
		},
		async fileUploadCloseHandler(fileList) {
			// if (this.folderId) {
			const mediaIds = fileList ? map(fileList, (o) => o.id) : [];
			this.attachMediasToFolder({
				mediaIds,
				folder_id: this.folderId,
			});
			// }
		},
		async attachMediasToFolder(args) {
			try {
				const variables = {
					media: args.mediaIds,
					folder_id: args.folder_id,
					workspace_id: this.currentWorkspace,
				};
				await this.$api.mutate({
					mutation: AttachFolderMediaMutation,
					variables,
				});
				this.increaseFolderMediaCount(
					{ id: args.folder_id },
					args.mediaIds
				);
				this.$q.notify({
					classes: "success-notification",
					position: "top-right",
					message: "Files uploaded successfully",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
			} catch (erorr) {
				this.$q.notify({
					color: "negative",
					position: "top-right",
					message: "Something went wrong",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
			}
		},
		uploadFileToFolder(folderId) {
			this.folderId = folderId;
			this.$refs.folderFileUploader.openFileDialog();
		},
		copyMoveFileFolderHandler(
			items,
			isMoveAction = false,
			isFolder = false,
			isRoot = true
		) {
			this.copyMoveDialog.flag = true;
			this.copyMoveDialog.data = items; // Array of folder or file items
			this.copyMoveDialog.isMoveAction = isMoveAction;
			this.copyMoveDialog.isRoot = isRoot;
			this.copyMoveDialog.isFolder = isFolder;
		},
		moveCopyFilFolderHandler(workspace, folder) {
			if (this.copyMoveDialog.isFolder) {
				if (this.copyMoveDialog.isMoveAction) {
					this.moveFolders(workspace);
				} else {
					this.copyFolders(workspace);
				}
			} else {
				if (this.copyMoveDialog.isMoveAction) {
					this.moveFiles(workspace, folder);
				} else {
					this.copyFiles(workspace, folder);
				}
			}
		},
		async moveFolders(workspace) {
			try {
				this.copyMoveDialog.loading = true;
				const folderIds = this.copyMoveDialog.data.map((f) => f.id);
				const variables = {
					folder_id: folderIds,
					workspace_id: workspace.id,
				};
				const response = await this.$api.mutate({
					mutation: MoveMediaFolderMutation,
					variables,
				});
				if (response) {
					const query = this.$api.getQuery(
						`MediaFoldersQuery:${this.currentWorkspace}`
					);
					query.data.mediaFolders = query.data.mediaFolders.filter(
						(f) => !folderIds.includes(f.id)
					);
				}
				this.$q.notify({
					classes: "success-notification",
					position: "top-right",
					message: "Folder(s) moved successfully",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			} catch (error) {
				this.$q.notify({
					color: "negative",
					position: "top-right",
					message: "Something went wrong",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			}
		},
		async copyFolders(workspace) {
			try {
				this.copyMoveDialog.loading = true;
				const folderIds = this.copyMoveDialog.data.map((f) => f.id);
				const variables = {
					folder_id: folderIds,
					workspace_id: workspace.id,
				};
				await this.$api.mutate({
					mutation: CopyMediaFolderMutation,
					variables,
				});
				this.$q.notify({
					classes: "success-notification",
					position: "top-right",
					message: "Folder(s) copied successfully",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			} catch (error) {
				this.$q.notify({
					color: "negative",
					position: "top-right",
					message: "Something went wrong",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			}
		},
		async moveFiles(workspace, folder) {
			try {
				this.copyMoveDialog.loading = true;
				const fileIds = this.copyMoveDialog.data.map((f) => f.id);
				const variables = {
					media_id: fileIds,
					workspace_id: workspace.id,
					target_folder_id: folder ? folder.id : null,
				};
				const response = await this.$api.mutate({
					mutation: MoveMediaMutation,
					variables,
				});
				
				if (response.data) {
					if (workspace.id === this.currentWorkspace) {
						/*increase cache count manually only if
                      destination folder is in same workspace to show user folder's updated count*/
						this.increaseFolderMediaCount(folder, fileIds);
					}

					if(workspace.boards.length) {
						const boardquery = this.$api.getQuery(`BoardQuery:${workspace.boards[0].id}`);
						if(boardquery.data.board.tasks) {
							boardquery.data.board.tasks.forEach((element, index) => {
								boardquery.data.board.tasks[index].media = element.media.filter((f) => !fileIds.includes(f.id));		
							});
						}
					}

					const query = this.$api.getQuery(
						`MediaQuery:${this.currentWorkspace}`
					);
					query.data.newMedia = query.data.newMedia.filter(
						(f) => !fileIds.includes(f.id)
					);
				}
				this.$q.notify({
					classes: "success-notification",
					position: "top-right",
					message: "File(s) moved successfully",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			} catch (error) {
				this.$q.notify({
					color: "negative",
					position: "top-right",
					message: "Something went wrong",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			}
		},
		increaseFolderMediaCount(folder, fileIds) {
			//cache update
			const query = this.$api.getQuery(
				`MediaFoldersQuery:${this.currentWorkspace}`
			);
			query.data.mediaFolders = query.data.mediaFolders.map((f) => {
				if (f.id === folder.id) {
					f.media_count += fileIds.length;
				}
				return f;
			});
		},
		async copyFiles(workspace, folder) {
			try {
				this.copyMoveDialog.loading = true;
				const fileIds = this.copyMoveDialog.data.map((f) => f.id);
				const variables = {
					media_id: fileIds,
					workspace_id: workspace.id,
					target_folder_id: folder ? folder.id : null,
				};
				const response = await this.$api.mutate({
					mutation: CopyMediaMutation,
					variables,
				});
				if (response.data) {
					//increase folder count when copy to folder
					if (workspace.id === this.currentWorkspace) {
						//cache update
						const query = this.$api.getQuery(
							`MediaFoldersQuery:${this.currentWorkspace}`
						);
						query.data.mediaFolders = query.data.mediaFolders.map(
							(f) => {
								if (f.id === folder.id) {
									f.media_count += fileIds.length;
								}
								return f;
							}
						);
					}
				}
				this.$q.notify({
					classes: "success-notification",
					position: "top-right",
					message: "File(s) copied successfully",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			} catch (error) {
				this.$q.notify({
					color: "negative",
					position: "top-right",
					message: "Something went wrong",
					timeout: 2500,
					icon: this.$icons.matAnnouncement,
					actions: [{ icon: this.$icons.matClose, color: "white" }],
				});
				this.closeCopyMoveFileFolderDialog();
			}
		},
		closeCopyMoveFileFolderDialog() {
			this.copyMoveDialog.data = [];
			this.copyMoveDialog.flag = false;
			this.copyMoveDialog.loading = false;
			this.copyMoveDialog.isFolder = false;
		},
		async addMediaBookmark(media) {
			try {
				const variables = {
					media_id: media.id,
				};
				const mediaEntity = this.$api.getEntity("media", media.id);
				mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
				await this.$api.mutate({
					mutation: AddMediaBookmarkMutation,
					variables,
					skipUpdates: true,
				});
			} catch (error) {
				throw new Error(error);
			}
		},
		async removeMediaBookmark(media) {
			try {
				const variables = {
					media_id: media.id,
				};

				const mediaEntity = this.$api.getEntity("media", media.id);
				mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
				await this.$api.mutate({
					mutation: RemoveMediaBookmarkMutation,
					variables,
					skipUpdates: true,
				});
			} catch (error) {
				throw new Error(error);
			}
		},
		async addMediaFolderBookmark(folder) {
			try {
				const variables = {
					media_folder_id: folder.id,
				};
				const folderEntity = this.$api.getEntity(
					"mediaFolder",
					folder.id
				);
				folderEntity.isbookmarked = !folderEntity.isbookmarked;
				await this.$api.mutate({
					mutation: AddMediaFolderBookmarkMutation,
					variables,
					skipUpdates: true,
				});
			} catch (error) {
				throw new Error(error);
			}
		},
		async removeMediaFolderBookmark(folder) {
			try {
				const variables = {
					media_folder_id: folder.id,
				};
				const folderEntity = this.$api.getEntity(
					"mediaFolder",
					folder.id
				);
				folderEntity.isbookmarked = !folderEntity.isbookmarked;
				const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
				if (savedItemsQuery.data) {
					savedItemsQuery.data.savedItems.media_folder = savedItemsQuery.data.savedItems.media_folder.filter(
						(item) => item.id != folder.id
					);
				}
				await this.$api.mutate({
					mutation: RemoveMediaFolderBookmarkMutation,
					variables,
					skipUpdates: true,
				});
			} catch (error) {
				throw new Error(error);
			}
		},
	},
};
