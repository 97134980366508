<template>
  <div>
    <div
      class="q-mr-md q-mt-sm"
      style="width:150px"
      v-if="customField.type === 'dropdown'"
    >
      <div class="text-grey row items-center">
        <q-icon
          :name="
            customField.title === 'Status'
              ? $icons.matFlag
              : customField.title === 'Effort'
              ? $icons.mdiChevronDoubleUp
              : $icons.matPriorityHigh
          "
          style="color:#919ca8"
          size="xs"
        />
        <q-icon
          :name="
            customField.title !== 'Status' && customField.title !== 'Effort'
              ? $icons.matPriorityHigh
              : ''
          "
          style="color:#919ca8;margin-left:-10px"
          size="xs"
        />
        <div
          class="app-text q-ml-xs ellipses field-label"
          style="max-width:120px"
        >
          {{ customField.title }}
        </div>
      </div>
      <div class="q-mt-sm relative-position">
        <q-btn
          class="selected app-text-normal"
          no-caps
          :style="[
            selectedOptionColor
              ? {
                  'background-color': selectedOptionColor,
                  color: 'white',
                  'font-weight': '500',
                }
              : { 'font-weight': '400' },
            { 'justify-content': 'space-between' },
          ]"
          :icon-right="selectedOptionColor ? null : $icons.matKeyboardArrowDown"
        >
          <!-- <q-tooltip>
            {{ selectedOptionLabel }}
          </q-tooltip> -->
          <div class="ellipsis">
            {{ selectedOptionLabel }}
          </div>
          <q-menu content-class="app-text-normal" auto-close>
            <q-list style="min-width:138px; max-height: 250px; overflow: auto">
              <q-item
                v-for="optionData in customFieldOptions"
                :key="optionData.id"
                clickable
                @click="() => handleDropdownOptionSelect(optionData)"
              >
                <q-item-section>
                  {{ optionData.label | capitalize }}
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
        <div
          class="edit-field cursor-pointer row items-center"
          @click="$emit('editField', customField)"
          v-if="selectedOptionColor"
        >
          <q-icon
            :name="$icons.matEdit"
            size="20px"
            color="primary"
            style="margin-left:2px"
          />
        </div>
      </div>
    </div>
    <div
      style="width:150px"
      class="q-mr-md q-mt-sm"
      v-else-if="customField.type === 'checkbox'"
    >
      <div class="text-grey row items-center">
        <q-icon :name="$icons.matCheckCircle" style="color:#919ca8" size="xs" />
        <div
          class="app-text ellipses field-label"
          style="padding-left:5px;max-width:120px"
        >
          {{ customField.title }}
        </div>
      </div>
      <div class="q-mt-sm q-ml-xs">
        <span
          @click="handleCheckboxSelect"
          class="cursor-pointer custom-field-selection-checkbox"
        >
          <div
            v-if="isChecked"
            class="selected-custom-field-selection-checkbox"
          ></div>
        </span>
      </div>
    </div>
    <div
      style="width:150px"
      class="q-mr-md q-mt-sm"
      v-else-if="customField.type === 'number'"
    >
      <div class="text-grey row items-center">
        <q-icon :name="$icons.mdiPound" style="color:#919ca8" size="xs" />
        <div
          class="app-text ellipses field-label"
          style="padding-left:5px;max-width:120px"
        >
          {{ customField.title }}
        </div>
      </div>
      <div class="q-mt-sm">
        <q-input
          :value="CFNumberNoteTxt"
          placeholder="Type number"
          dense
          borderless
          @input="handleNumberInput"
          :class="[
            isErrNumberFormat && 'error-indicator',
            'field-input app-text-normal',
          ]"
          type="text"
          style="margin-top:3px; width: 140px;"
        >
        </q-input>
      </div>
    </div>
    <div
      style=" width:150px"
      class="q-mr-md q-mt-sm"
      v-else-if="customField.type === 'text'"
    >
      <div class="text-grey row items-center">
        <q-icon :name="$icons.matDescription" style="color:#919ca8" size="xs" />
        <div
          class="app-text ellipses field-label"
          style="padding-left:5px;max-width:120px"
        >
          {{ customField.title }}
        </div>
      </div>
      <div class="q-mt-sm input-box">
        <q-input
          placeholder="Add value"
          dense
          borderless
          class="field-input app-text-normal "
          type="text"
          style="  margin-top:3px; width: 140px;"
          :value="CFNumberNoteTxt"
          @click="isEditNotesOpen = true"
        >
        </q-input>
      </div>
    </div>
    <div
      style="width:150px"
      class="q-mr-md q-mt-sm"
      v-else-if="customField.type === 'date'"
    >
      <div class="text-grey row items-center">
        <q-icon
          :name="$icons.fasCalendarAlt"
          style="color:#919ca8"
          size="14px"
        />
        <div
          class="app-text ellipses field-label"
          style="padding-left:5px;max-width:120px"
        >
          {{ customField.title }}
        </div>
      </div>
      <q-btn
        dense
        no-caps
        flat
        class="q-px-sm q-mt-sm"
        :style="[
          dateMenu
            ? { background: '#007aff', color: '#fff' }
            : { background: '#e7ecf0', color: '#172b4d' },
          { 'font-size': '11px', height: '30px', 'min-width': '124px' },
        ]"
        @click="dateMenu = true"
      >
        <q-icon
          style="color: #172b4d;"
          size="8px"
          :name="$icons.fasPlus"
          v-if="!CFDateTimeValue"
          class="q-mr-xs"
        />
        <span class="app-text-normal" style="font-size:14px;font-weight:500">{{
          CFDateTimeValue ? CFDateTimeValue : "Add date"
        }}</span>
      </q-btn>
      <div>
        <q-menu
          :value="dateMenu"
          v-model="dateMenu"
          max-height="auto"
          content-class="overflow-auto task-date-picker date-picker-calendar"
          v-if="dateMenu"
          :offset="[-120, -200]"
        >
          <q-date
            mask="YYYY-MM-DD"
            :value="CFDate"
            today-btn
            :options="optionsFn"
            @input="(date) => (CFDate = date)"
            color="blue-13"
          >
            <div
              class="date-time-block q-px-md q-py-sm column no-flex-wrap justify-between"
              style="height:43px"
            >
              <!-- Start Date and Time row -->
              <div class="row justify-between items-center flex-no-wrap">
                <div class="date-label">
                  <span class="demi-bold">
                    Time
                  </span>
                </div>
                <div class="time-input">
                  <q-input
                    :value="time"
                    @input="(val) => (time = val)"
                    dense
                    type="time"
                    bg-color="white"
                  >
                    <div
                      @click="timeMenu = !timeMenu"
                      class="arrow-icon row items-center no-flex-wrap"
                    >
                      <q-icon
                        v-if="timeMenu"
                        :name="$icons.matKeyboardArrowUp"
                      />
                      <q-icon v-else :name="$icons.matKeyboardArrowDown" />
                    </div>
                    <q-menu
                      no-parent-event
                      content-class="time-option-wrapper overflow-auto"
                      max-height="180px"
                      v-model="timeMenu"
                    >
                      <q-list class="time-list">
                        <template v-for="(time, index) in timeDropdownOptions">
                          <q-item
                            :key="index"
                            @click.stop="setTime(time)"
                            clickable
                          >
                            <q-item-section>
                              {{ time.label }}
                            </q-item-section>
                          </q-item>
                        </template>
                      </q-list>
                    </q-menu>
                  </q-input>
                </div>
              </div>
            </div>
            <div class="row items-center justify-between btn-section">
              <q-btn
                flat
                class="btn-style clear"
                @click="CFDate = null"
                label="Clear"
                no-caps
              />
              <q-btn
                flat
                color="black"
                class="btn-style"
                @click="dateMenu = false"
                label="Cancel"
                no-caps
              />
              <q-btn
                flat
                padding="xs md"
                class="bg-primary text-white btn-style"
                label="OK"
                :disable="!CFDate"
                @click="handleDatePicker"
              />
            </div>
          </q-date>
        </q-menu>
      </div>
    </div>
    <notes-edit-dialog
      v-if="isEditNotesOpen"
      v-model="isEditNotesOpen"
      :description="CFNumberNoteTxt"
      :handleNoteInput="handleNoteInput"
      @closeDialog="isEditNotesOpen = false"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import orderBy from "lodash/orderBy";
import getUnixTime from "date-fns/getUnixTime";
import fromUnixTime from "date-fns/fromUnixTime";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { date } from "quasar";
import { timeList } from "@/utils/Contents.js";
import NotesEditDialog from "@/components/Dialogs/NotesEditDialog";
import RandomPosition from "random-position";

import {
  CreateCustomFieldOptionSelectionMutation,
  DeleteCustomFieldOptionSelectionMutation,
} from "@/gql";
export default {
  props: ["customField", "workspaceId", "task"],
  components: {
    NotesEditDialog,
  },
  data() {
    return {
      isEditNotesOpen: false,
      dateMenu: false,
      CFDate: null,
      time: "09:00",
      timeMenu: false,
      isErrNumberFormat: false,
    };
  },
  mounted() {
    const checkboxCFSelection = this.task.customFieldOptionSelection.find(
      (cfs) => cfs.c_f_id === this.customField.id
    );
    if (
      this.customField.type === "date" &&
      checkboxCFSelection &&
      checkboxCFSelection.option_text
    ) {
      this.CFDate = format(
        fromUnixTime(checkboxCFSelection.option_text),
        "yyyy-MM-dd"
      );
      this.time = format(
        fromUnixTime(checkboxCFSelection.option_text),
        "HH:mm"
      );
    }
  },
  methods: {
    async handleDropdownOptionSelect(data) {
      if (data.id) {
        const variables = {
          task_id: this.task.id,
          c_f_id: data.c_f_id,
          c_f_o_id: data.id,
          option_text: data.option,
        };

        let filteredData = this.customFieldOptions.find(
          (cFilter) => cFilter.id === data.id
        );
        const selectedOption = {
          c_f_id: filteredData.c_f_id,
          c_f_o_id: filteredData.id,
          option_text: filteredData.label,
          task_id: this.task.id,
          id: getUnixTime(new Date()),
          __typename: "customFieldOptionSelection",
        };
        this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
          (cfos) => cfos.c_f_id !== this.customField.id
        );
        this.task.customFieldOptionSelection = [
          ...this.task.customFieldOptionSelection,
          selectedOption,
        ];
        await this.createCFSelection(variables);
      } else {
        const dropdpownCFSelection = this.task.customFieldOptionSelection.find(
          (cfs) => cfs.c_f_id === this.customField.id
        );
        if (dropdpownCFSelection) {
          this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
            (cfos) => cfos.c_f_id !== this.customField.id
          );
          this.$api.mutate({
            mutation: DeleteCustomFieldOptionSelectionMutation,
            variables: {
              c_f_o_s_id: dropdpownCFSelection.id,
            },
          });
        }
      }
    },
    async handleCheckboxSelect() {
      const variables = {
        task_id: this.task.id,
        c_f_id: this.customField.id,
        option_text: this.isChecked ? "false" : "true",
      };
      this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
        (cfs) => cfs.c_f_id !== this.customField.id
      );
      const newCFSelection = {
        __typename: "customFieldOptionSelection",
        id: getUnixTime(new Date()),
        ...variables,
      };
      this.task.customFieldOptionSelection = [
        ...this.task.customFieldOptionSelection,
        newCFSelection,
      ];
      await this.createCFSelection(variables);
    },
    handleNumberInput(value) {
      this.isErrNumberFormat = false;
      var reg = /^[+-]?\d+(\.\d+)?$/;
      let CFNumberValue = null;
      if (reg.test(value)) {
        CFNumberValue = value;
      } else {
        CFNumberValue = null;
      }
      if (CFNumberValue) {
        const variables = {
          task_id: this.task.id,
          c_f_id: this.customField.id,
          option_text: CFNumberValue,
        };
        this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
          (cfs) => cfs.c_f_id !== this.customField.id
        );
        const newCFSelection = {
          __typename: "customFieldOptionSelection",
          id: getUnixTime(new Date()),
          ...variables,
        };
        this.task.customFieldOptionSelection = [
          ...this.task.customFieldOptionSelection,
          newCFSelection,
        ];
        this.debounceFunction(variables);
      } else {
        this.isErrNumberFormat = true;
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Only numbers are allowed",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    async handleNoteInput(value) {
      this.isEditNotesOpen = false;
      const variables = {
        task_id: this.task.id,
        c_f_id: this.customField.id,
        option_text: value,
      };
      this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
        (cfs) => cfs.c_f_id !== this.customField.id
      );
      const newCFSelection = {
        __typename: "customFieldOptionSelection",
        id: getUnixTime(new Date()),
        ...variables,
      };
      this.task.customFieldOptionSelection = [
        ...this.task.customFieldOptionSelection,
        newCFSelection,
      ];
      await this.createCFSelection(variables);
    },
    async handleDatePicker() {
      const variables = {
        task_id: this.task.id,
        c_f_id: this.customField.id,
        option_text: getUnixTime(
          parseISO(this.CFDate + " " + this.time + ":" + "00")
        ).toString(), //Format according required params for parseISO
      };
      this.task.customFieldOptionSelection = this.task.customFieldOptionSelection.filter(
        (cfs) => cfs.c_f_id !== this.customField.id
      );
      const newCFSelection = {
        __typename: "customFieldOptionSelection",
        id: getUnixTime(new Date()),
        ...variables,
      };
      this.task.customFieldOptionSelection = [
        ...this.task.customFieldOptionSelection,
        newCFSelection,
      ];
      this.dateMenu = false;
      await this.createCFSelection(variables);
    },
    createCFSelection(variables) {
      return this.$api.mutate({
        mutation: CreateCustomFieldOptionSelectionMutation,
        variables,
      });
    },
    debounceFunction: debounce(async function(variables) {
      await this.createCFSelection(variables);
    }, 300),
    optionsFn(d) {
      return d >= date.formatDate(Date.now(), "YYYY/MM/DD");
    },
    setTime(time) {
      this.time = time.value;
      this.timeMenu = false;
    },
  },
  computed: {
    selectedOptionLabel() {
      const dropdpownCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      const selectedOptionLabel =
        dropdpownCFSelection &&
        this.customField.customFieldOption.find(
          (o) => o.id === dropdpownCFSelection.c_f_o_id
        );
      return selectedOptionLabel ? selectedOptionLabel.label : "Select";
    },
    selectedOptionColor() {
      const dropdpownCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      const selectedOptionColor =
        dropdpownCFSelection &&
        this.customField.customFieldOption.find(
          (o) => o.id === dropdpownCFSelection.c_f_o_id
        );
      return selectedOptionColor ? selectedOptionColor.colorcode : null;
    },
    isChecked() {
      const checkboxCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      return checkboxCFSelection && checkboxCFSelection.option_text
        ? checkboxCFSelection.option_text.toLowerCase() === "true"
        : false;
    },
    CFNumberNoteTxt() {
      const checkboxCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      return checkboxCFSelection && checkboxCFSelection.option_text;
    },
    CFDateTimeValue() {
      if (this.customField.type === "date") {
        const checkboxCFSelection = this.task.customFieldOptionSelection.find(
          (cfs) => cfs.c_f_id === this.customField.id
        );
        const dateLabel =
          checkboxCFSelection &&
          checkboxCFSelection.option_text &&
          format(fromUnixTime(checkboxCFSelection.option_text), "MMM dd") +
            " at " +
            format(fromUnixTime(checkboxCFSelection.option_text), "hh:mm aaaa");
        return dateLabel;
      }
      return null;
    },
    customFieldOptions() {
      if (!this.customField.customFieldOption.length) return [];
      const orderCFOption = orderBy(this.customField.customFieldOption, [
        "sequence",
      ]);
      const customFieldOptions = [
        {
          __typename: "customFieldOption",
          c_f_id: this.customField.id,
          colorcode: null,
          id: 0,
          label: "Select",
          option: "select",
          sequence: RandomPosition.between(
            RandomPosition.first(),
            orderCFOption[0].sequence
          ),
        },
        ...orderCFOption,
      ];
      return customFieldOptions;
    },
    timeDropdownOptions() {
      return timeList;
    },
  },
  watch: {
    "task.customFieldOptionSelection"() {
      const checkboxCFSelection = this.task.customFieldOptionSelection.find(
        (cfs) => cfs.c_f_id === this.customField.id
      );
      if (
        this.customField.type === "date" &&
        checkboxCFSelection &&
        checkboxCFSelection.option_text
      ) {
        this.CFDate = format(
          fromUnixTime(checkboxCFSelection.option_text),
          "yyyy-MM-dd"
        );
        this.time = format(
          fromUnixTime(checkboxCFSelection.option_text),
          "HH:mm"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-field-selection-checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #677484;
  border-radius: 2px;
  display: flex;
}
.selected-custom-field-selection-checkbox {
  background: #007aff;
  width: 15px;
  height: 15px;
  margin: auto;
  border-radius: 2px;
}
.field-input {
  height: 20px !important;
  border-radius: 5px;
  border: solid 1px #b8c1cb;
  background-color: #f4f5f8;
  margin-right: 10px;
  padding-bottom: 29px;
  padding-left: 8px;
}
.field-label {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.edit-field {
  height: 23px;
  width: 23px;
  position: absolute;
  top: 4px;
  right: 12px;
  background: white;
  border-radius: 3px;
}
</style>
