<template>
  <div class="column items-start" :class="className">
    <div
      class="row full-width group-hover rounded-borders q-px-md q-py-xs task-comment"
    >
      <div class="flex-1 relative-position">
        <div class="row" v-if="comment.is_bot">
          <q-avatar size="48px" v-if="comment.is_bot">
            <img
              src="https://s3-us-west-1.amazonaws.com/vabotu/default/heycollab-round.svg"
              alt="Heycollab"
              class="text-avatar-style"
            />
          </q-avatar>
          <div>
            <div class="messaging-user-detail-chip">
              <span class="user-name">Heycollab</span>
              <span class="time">
                <span>{{ comment.created_at | dateMonthDateTime }}</span>
              </span>
            </div>
            <div
              v-html="commentContent"
              class="post-content dont-break-out editor-prototype q-mt-xs"
              style="white-space:normal"
            ></div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="flex-1">
            <div class="row flex-no-wrap justify-between">
              <div class="row flex-no-wrap items-center">
                <div
                  v-if="hasNotification"
                  class="cursor-pointer notification-sidebar-dot q-mr-xs"
                ></div>
                <div class="messaging-user-detail-chip">
                  <span
                    class="user-name cursor-pointer"
                    style="font-weight:bold"
                    @click="$emit('showUserInfo', comment.owner)"
                    >{{
                      `${comment.owner.first} ${comment.owner.last}`.substring(
                        0,
                        10
                      )
                    }}<span
                      v-if="
                        `${comment.owner.first} ${comment.owner.last}`.length >
                          10
                      "
                      >...</span
                    >
                    <q-tooltip
                      >{{ comment.owner.first }}
                      {{ comment.owner.last }}</q-tooltip
                    >
                  </span>
                  <span class="time">
                    <span v-if="comment.updated_at > comment.created_at">
                      {{ comment.created_at | gettime }}
                      <span class="capitalize-text an-12"
                        ><q-icon size="16px" :name="$icons.matEdit"></q-icon
                        ><q-tooltip>Edited</q-tooltip></span
                      >
                    </span>
                    <span v-else>{{ comment.created_at | gettime }}</span>
                    <span v-if="comment.owner.userStatus" class="q-ml-xs capitalize-text an-12">
                      <img
                      style="width: 16px;vertical-align: middle;"
                      :src="'/static/react-icons/' + comment.owner.userStatus.icon"
                      />
                      <q-tooltip>{{comment.owner.userStatus.name}}</q-tooltip>
                    </span>
                  </span>
                </div>
              </div>
              <div
                class="row group-hover-item flex-no-wrap thread-icons"
                v-if="!comment.is_bot"
              >
                <q-btn
                  dense
                  rounded
                  flat
                  size="10px"
                  class="thread-icon"
                  :icon="$icons.matSentimentSatisfied"
                >
                  <q-tooltip>
                    React to Comment
                  </q-tooltip>
                  <q-menu content-class="overflow-auto" auto-close>
                    <div style="max-width: 300px;padding: 6px 2px 0px 10px;">
                      <img
                        v-for="(emoji, index) in reactions"
                        :key="index"
                        :src="'/static/react-icons/' + emoji.icon"
                        alt="ANGRY"
                        @click="
                          $emit('reactOnPost', { comment, reaction: emoji })
                        "
                        class="react-emoji-icon q-mr-sm cursor-pointer"
                      />
                    </div>
                  </q-menu>
                </q-btn>
                <q-btn
                  dense
                  flat
                  rounded
                  size="10px"
                  class="thread-icon q-ml-xs"
                  :icon="$icons.fasShare"
                  @click="replyTocomment(comment)"
                >
                  <q-tooltip>
                    Reply to Comment
                  </q-tooltip>
                </q-btn>
                <q-btn
                  dense
                  flat
                  rounded
                  size="10px"
                  :icon="$icons.matBorderColor"
                  class="thread-icon q-ml-xs"
                  v-if="currentUser && currentUser.id === comment.owner.id"
                  @click="updateTaskEditorEdit(comment, task.id)"
                >
                  <q-tooltip>
                    Edit Message
                  </q-tooltip>
                </q-btn>
                <q-btn
                  dense
                  flat
                  rounded
                  size="10px"
                  :icon="$icons.mdiClipboardTextMultipleOutline"
                  class="thread-icon q-ml-xs"
                  @click.stop="$copyContentToClip(comment.comment)"
                >
                  <q-tooltip>
                    Copy text
                  </q-tooltip>
                </q-btn>
                <q-btn
                  dense
                  flat
                  rounded
                  size="10px"
                  :icon="$icons.matDelete"
                  class="thread-icon q-ml-xs"
                  v-if="
                    canRemoveMessageFromWorkspace ||
                      isWorkspaceOwner ||
                      (currentUser && currentUser.id === comment.owner.id)
                  "
                  @click.native.stop="
                    confirmCommentBoxHandler(true, comment.id)
                  "
                >
                  <q-tooltip>
                    Remove Message
                  </q-tooltip>
                </q-btn>
              </div>
            </div>
            <div
              v-html="commentContent"
              class="post-content dont-break-out editor-prototype q-mt-xs"
              style="white-space:normal"
            ></div>
            <div
              v-if="
                commentMedia.length > 0 ||
                  (comment.mediafolder && comment.mediafolder.length)
              "
              class="q-mb-xs"
            >
              <sub-task-media
                v-if="commentMedia"
                type="taskComment"
                :comment="comment"
                :openFolder="openFolder"
                :deleteFolderHandler="deleteFolderHandler"
                :copyMoveFileFolderHandler="copyMoveFileFolderHandler"
                :postId="comment.id"
                :attachments="commentMedia"
                :editDoc="editDoc"
                :canRemoveMedia="
                  canRemoveAttachment ||
                    (currentUser && currentUser.id === comment.owner.id)
                "
                :forceDisableRemove="
                  comment.title === '' && commentMedia.length <= 1
                "
                :commentId="comment.id"
                :labels="labels"
                :user="currentUser"
                :workspace="currentWorkspace"
                :isWorkspaceOwner="isWorkspaceOwner"
                :removeMedia="removeMedia"
                :height="88"
                @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                @clickonmoreoption="(data) => $emit('clickonmoreoption', data)"
                @deleteCommentPoint="
                  (data) => $emit('deleteCommentPoint', data)
                "
                @sendComment="(data) => $emit('sendComment', data)"
                @updatepositions="(data) => $emit('updatepositions', data)"
                @updateMediaCommentPointLabel="
                  (data) => $emit('updateMediaCommentPointLabel', data)
                "
                @deleteMediaComment="
                  (data) => $emit('deleteMediaComment', data)
                "
                @updateComment="(data) => $emit('updateComment', data)"
              />
            </div>
            <!-- Replied Post -->
            <div class="reply-comment q-pa-md q-mt-sm" v-if="ForwardComment">
              <div class="row ">
                <div class="flex-1">
                  <div class="messaging-user-detail-chip">
                    <span
                      @click="$emit('showUserInfo', ForwardComment.owner)"
                      class="user-name cursor-pointer"
                    >
                      {{ ForwardComment.owner.first }}
                      {{ ForwardComment.owner.last }}
                    </span>
                    <span class="time">
                      <span
                        v-if="
                          ForwardComment.updated_at > ForwardComment.created_at
                        "
                      >
                        {{ ForwardComment.created_at | gettime }}
                        <span class="capitalize-text an-12">(Edited)</span>
                      </span>
                      <span v-else>
                        {{ ForwardComment.created_at | gettime }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-html="ForwardCommentContent"
                    class="post-content dont-break-out editor-prototype q-mt-xs"
                    style="white-space:normal"
                  ></div>
                </div>
              </div>
              <div>
                <sub-task-media
                  v-if="ForwardComment.media"
                  type="taskComment"
                  :postId="ForwardComment.id"
                  :attachments="ForwardComment.media"
                  :canRemoveMedia="
                    canRemoveAttachment ||
                      (currentUser &&
                        currentUser.id === ForwardComment.owner.id)
                  "
                  :forceDisableRemove="
                    ForwardComment.title === '' &&
                      ForwardComment.media.length <= 1
                  "
                  :commentId="ForwardComment.id"
                  :labels="labels"
                  :user="currentUser"
                  :workspace="currentWorkspace"
                  :isWorkspaceOwner="isWorkspaceOwner"
                  :removeMedia="removeMedia"
                  :height="90"
                  :editDoc="editDoc"
                  @closeMediaModel="(data) => $emit('closeMediaModel', data)"
                  @clickonmoreoption="
                    (data) => $emit('clickonmoreoption', data)
                  "
                  @deleteCommentPoint="
                    (data) => $emit('deleteCommentPoint', data)
                  "
                  @sendComment="(data) => $emit('sendComment', data)"
                  @updatepositions="(data) => $emit('updatepositions', data)"
                  @updateMediaCommentPointLabel="
                    (data) => $emit('updateMediaCommentPointLabel', data)
                  "
                  @deleteMediaComment="
                    (data) => $emit('deleteMediaComment', data)
                  "
                  @updateComment="(data) => $emit('updateComment', data)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            comment.commentreactiondata &&
              Object.keys(comment.commentreactiondata).length
          "
          class="q-pt-sm"
        >
          <div class="row inline">
            <template
              v-for="(commentreactiondata,
              index) in comment.commentreactiondata"
            >
              <div class="q-mr-sm" :key="index">
                <div class="relative-position reaction-icon	row items-center">
                  <img
                    :src="`/static/react-icons/${index}.svg`"
                    :alt="index"
                    class="react-icon-size q-mr-xs cursor-pointer"
                    @click="$emit('reactOnPost', { comment, reaction: index })"
                  />
                  <div class="text-black" style="font-size:12px">
                    {{ commentreactiondata.length }}
                  </div>
                </div>
                <q-tooltip
                  :offset="[-50, 0]"
                  content-class="bg-white elevation-2 q-pa-md"
                >
                  <div class="row reaction-menu">
                    <img
                      :src="`/static/react-icons/${index}.svg`"
                      :alt="index"
                      class="q-mr-md react-menu-icon"
                    />
                    <q-list
                      class="column justify-center"
                      v-if="commentreactiondata"
                    >
                      <q-item
                        :style="
                          commentreactiondata.length === 1 && {
                            'margin-bottom': '0px',
                          }
                        "
                        class="q-pa-none q-mb-sm"
                        style="min-height:30px"
                        v-for="item in commentreactiondata"
                        :key="item.id"
                      >
                        <div class="row items-center ">
                          <div class="q-mr-sm">
                            <q-avatar
                              size="26px"
                              color="blue-grey-11"
                              text-color="grey-10"
                            >
                              <img
                                :src="item.pic"
                                :alt="item.first"
                                v-if="item.pic"
                              />
                              <!-- <span
                                class="text-avatar-style"
                                style="font-size:9px"
                                v-else
                              >
                                {{ item.first | firstChar
                                }}{{ item.last | firstChar }}
                              </span> -->
                              <avatar v-else :size="26" :customStyle="{'font-size':'10px', 'font-weight':'600'}" :username="item.first+' '+item.last"></avatar>
                            </q-avatar>
                          </div>
                          <div class="reacted-user">
                            {{ item.first }} {{ item.last }}
                          </div>
                          <div v-if="item.time" class="reaction-time q-ml-xs">
                            {{ item.time | formatDateTime }}
                          </div>
                        </div>
                      </q-item>
                    </q-list>
                  </div>
                </q-tooltip>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        comment.commentreactiondata &&
          Object.keys(comment.commentreactiondata).length
      "
      class="mb-2"
    ></div>
    <confirm-dialog
      v-if="confirmRemoveComment.flag"
      v-model="confirmRemoveComment.flag"
      title="Delete Comment ?"
      question="Are you sure you want to delete this comment?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => confirmCommentBoxHandler(false)"
      :successMethod="() => deleteComment(confirmRemoveComment.id)"
      :loading="false"
    />
    <folder-details-dialog
      v-if="folderDetailsDialog.flag"
      :isFolderOpen="folderDetailsDialog.flag"
      :folder="folderDetailsDialog.data"
      :currentWorkspaceId="currentWorkspace"
      :user="currentUser"
      :isWorkspaceOwner="isWorkspaceOwner"
      :closeFolder="closeFolder"
      :companyId="currentCompany.companyId"
      :activeCompany="activeCompany"
      :addMediaBookmark="addMediaBookmark"
      :removeMediaBookmark="removeMediaBookmark"
      :labels="labels"
      :folderList="comment.mediafolder"
      :authToken="authToken"
      :editDoc="editDoc"
      :openDocEditor="openDocEditor"
    />
    <post-copy-move-file-folder
      v-if="copyMoveDialog.flag"
      v-model="copyMoveDialog.flag"
      :loading="copyMoveDialog.loading"
      :isFolder="copyMoveDialog.isFolder"
      :isRoot="copyMoveDialog.isRoot"
      :fileFolderDetails="copyMoveDialog.data"
      :cancelMethod="closeCopyMoveFileFolderDialog"
      :folderList="folderList"
      :moveCopyHandler="copyFileFolderHandler"
      :currentWorkspaceId="currentWorkspace"
    />
    <confirm-dialog
      v-if="deleteFolder.flag"
      v-model="deleteFolder.flag"
      title="Delete Folder?"
      question="Are you sure you want to detach the folder from the comment?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => deleteFolderDialogHandler(false)"
      :successMethod="() => deleteFolderDialogHandler(true)"
      :loading="deleteFolder.loader"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import UserScope from "@/mixins/UserScope";
import SubTaskMedia from "@/components/Miscellaneous/SubTaskMedia";
import { compile } from "@/components/Editor/MarkupUtils";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import FolderDetailsDialog from "@/components/Dialogs/FolderDetailsDialog";
import PostCopyMoveFileFolder from "@/components/Dialogs/PostCopyMoveFileFolder";

import {
  AddMediaBookmarkMutation,
  RemoveMediaBookmarkMutation,
  DetachCommentFolderMutation,
  CopyMediaFolderMutation,
  CopyMediaMutation,
} from "@/gql";
export default {
  name: "TaskComment",
  mixins: [UserScope],
  props: [
    "comment",
    "task",
    "currentUser",
    "isWorkspaceOwner",
    "className",
    "labels",
    "currentWorkspace",
    "canRemoveAttachment",
    "removeMedia",
    "reactions",
    "deleteTaskCommentHandler",
    "folderList",
    "currentCompany",
    "editDoc",
    "openDocEditor",
    "hideCommentOption",
  ],
  data() {
    return {
      editMod: false,
      confirmRemoveComment: {
        flag: false,
        id: null,
      },
      folderDetailsDialog: {
        flag: false,
        data: null,
      },
      deleteFolder: {
        id: null,
        flag: false,
        loader: false,
      },
      copyMoveDialog: {
        data: [],
        comment: null,
        isFolder: false,
        isRoot: true,
        flag: false,
        loading: false,
      },
    };
  },
  components: {
    SubTaskMedia,
    ConfirmDialog,
    FolderDetailsDialog,
    PostCopyMoveFileFolder,
  },
  mounted() {
    this.preloadImage();
  },
  methods: {
    copyFileFolderHandler(workspace, folder) {
      if (this.copyMoveDialog.isFolder) {
        this.copyFolders(workspace);
      } else {
        this.copyFiles(workspace, folder);
      }
    },
    async copyFolders(workspace) {
      try {
        this.copyMoveDialog.loading = true;
        // Array of folders
        const folderIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          folder_id: folderIds,
          workspace_id: workspace.id,
        };
        await this.$api.mutate({
          mutation: CopyMediaFolderMutation,
          variables,
        });
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "Folder(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    async copyFiles(workspace, folder) {
      try {
        this.copyMoveDialog.loading = true;
        const fileIds = this.copyMoveDialog.data.map((f) => f.id);
        const variables = {
          media_id: fileIds,
          workspace_id: workspace.id,
          target_folder_id: folder ? folder.id : null,
        };
        const response = await this.$api.mutate({
          mutation: CopyMediaMutation,
          variables,
        });
        if (response.data) {
          //increase folder count when copy to folder
          if (workspace.id === this.currentWorkspace) {
            //cache update
            const query = this.$api.getQuery(
              `MediaFoldersQuery:${this.currentWorkspace}`
            );
            query.data.mediaFolders = query.data.mediaFolders.map((f) => {
              if (f.id === folder.id) {
                f.media_count += fileIds.length;
              }
              return f;
            });
          }
        }
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: "File(s) copied successfully",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
        this.closeCopyMoveFileFolderDialog();
      }
    },
    openFolder(folder) {
      this.folderDetailsDialog.flag = true;
      this.folderDetailsDialog.data = folder;
    },
    closeFolder() {
      this.folderDetailsDialog.flag = false;
      this.folderDetailsDialog.data = null;
    },
    async deleteFolderHandler(folderId) {
      this.deleteFolder.id = folderId;
      this.deleteFolder.flag = true;
    },
    async deleteFolderDialogHandler(flag = false) {
      if (flag) {
        this.deleteFolder.loader = true;
        try {
          const variables = {
            task_comment_id: this.comment.id,
            folder: [this.deleteFolder.id],
          };
          await this.$api.mutate({
            mutation: DetachCommentFolderMutation,
            variables,
          });
          //Remove from props
          this.comment.mediafolder = this.comment.mediafolder.filter(
            (f) => f.id !== this.deleteFolder.id
          );
        } catch (error) {
          this.$q.notify({
            color: "negative",
            position: "top-right",
            message: "Something went wrong",
            timeout: 2500,
            icon: this.$icons.matAnnouncement,
            actions: [{ icon: this.$icons.matClose, color: "white" }],
          });
        }
      }
      this.deleteFolder.id = null;
      this.deleteFolder.flag = false;
      this.deleteFolder.loader = null;
    },
    copyMoveFileFolderHandler(
      items,
      isFolder = false,
      isRoot = true,
      comment = null
    ) {
      this.copyMoveDialog.flag = true;
      this.copyMoveDialog.data = items; // Array of folder or file items
      this.copyMoveDialog.isFolder = isFolder;
      this.copyMoveDialog.isRoot = isRoot;
      this.copyMoveDialog.comment = comment;
    },
    closeCopyMoveFileFolderDialog() {
      this.copyMoveDialog.data = [];
      this.copyMoveDialog.flag = false;
      this.copyMoveDialog.loading = false;
      this.copyMoveDialog.isFolder = false;
      this.copyMoveDialog.post = null;
    },
    async addMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: AddMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };

        const mediaEntity = this.$api.getEntity("media", media.id);
        mediaEntity.isbookmarked = !mediaEntity.isbookmarked;
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    preloadImage() {
      this.reactions.forEach((emoji) => {
        var img = new Image();
        img.src = "/static/react-icons/" + emoji.icon;
      });
    },
    replyTocomment(comment) {
      this.$emit("replyTocomment", comment);
    },
    confirmCommentBoxHandler(flag = true, id = null) {
      this.confirmRemoveComment = {
        flag,
        id,
      };
    },
    deleteComment(id) {
      const variables = {
        id,
        task_id: this.task.id,
      };
      this.deleteTaskCommentHandler(variables);
      this.confirmCommentBoxHandler(false);
    },
    updateTaskEditorEdit(comment, taskId) {
      this.editMod = true;
      this.$emit("updateCommentMod", {
        ...comment,
        task_id: taskId,
      });
    },
  },
  computed: {
    ...mapGetters({
      activeCompany: "activeCompany",
    }),
    authToken() {
      return this.currentCompany ? this.currentCompany.accessToken : null;
    },
    hasNotification() {
      const query = this.$api.getQuery(`NotificationsQuery`);
      if (query.data) {
        const notifications = query.data.notifications
          .filter((n) => !n.read)
          .filter(
            (n) =>
              n.action.type === "task-comment-created" &&
              n.action.object_id === this.comment.id
          );
        return notifications.length > 0;
      }
      return false;
    },
    commentContent() {
      return compile(this.comment.comment);
    },
    ForwardCommentContent() {
      return compile(this.comment.sharedTaskComment.comment);
    },
    ForwardComment() {
      return this.comment.sharedTaskComment;
    },
    commentMedia() {
      return orderBy(uniqBy(this.comment && this.comment.media, "id"), [
        "sequence",
      ]);
    },
  },
};
</script>
<style lang="scss" scoped>
.task-comment {
  background: #ffffff;
}
.task-comment:hover {
  background: #f6f8fa;
}
</style>
