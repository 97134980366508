var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"handle",staticStyle:{"max-width":"25px","cursor":"all-scroll"}},[_c('q-icon',{ref:`optionData-${_vm.optionData.id}`,staticClass:"inborader--text",attrs:{"size":"sm","name":_vm.$icons.mdiDragVertical}})],1),_c('div',{staticClass:"row flex-no-wrap"},[_c('div',{staticStyle:{"height":"28px","width":"28px","border-radius":"4.6px","margin-right":"12px"},style:({
        'background-color': _vm.optionData.colorcode,
      }),on:{"click":function($event){$event.stopPropagation();_vm.isSwatchOpen = true}}}),(!_vm.editOption || _vm.editOption.id !== _vm.optionData.id)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"app-text-normal ellipses",staticStyle:{"position":"relative","top":"5px","max-width":"90px"},on:{"click":_vm.enableEditOption}},[_vm._v(" "+_vm._s(_vm.optionData.label)+" "),(_vm.isTruncated)?_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.optionData.label)+" ")]):_vm._e()],1):_c('q-input',{staticClass:"edit-option-input app-text-normal",staticStyle:{"width":"85px"},attrs:{"placeholder":"Add item","dense":"","borderless":"","autofocus":"","type":"text"},on:{"blur":function($event){return _vm.updateOptionLabel(_vm.optionData)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.updateOptionLabel(_vm.optionData)}},model:{value:(_vm.optionData.label),callback:function ($$v) {_vm.$set(_vm.optionData, "label", $$v)},expression:"optionData.label"}}),_c('div',{staticStyle:{"text-aglin":"center","position":"absolute","right":"0px"},on:{"click":function($event){return _vm.deleteOption(_vm.optionData)}}},[_c('q-icon',{staticStyle:{"text-aglin":"center"},attrs:{"name":_vm.$icons.mdiDeleteOutline,"size":"27px"}})],1)],1),(_vm.isSwatchOpen)?_c('q-menu',{attrs:{"max-height":"auto","content-class":"swatch-container row q-py-sm q-px-md"},model:{value:(_vm.isSwatchOpen),callback:function ($$v) {_vm.isSwatchOpen=$$v},expression:"isSwatchOpen"}},_vm._l((_vm.swatchColorOptions),function(data){return _c('div',{key:'swathc-' + data.id,staticClass:"row flex-no-wrap relative-position",on:{"click":function($event){_vm.updateCustomFieldOptionColor({
          ..._vm.optionData,
          swatchColor: data.colorcode,
        });
        _vm.isSwatchOpen = false;}}},[_c('div',{class:[
          data.isWhite && 'white-box',
          'swatch-box',
          _vm.isSwatchSelected(data) ? 'selected' : 'not-selected',
        ],style:({
          'background-color': data.colorcode,
        })}),(_vm.isSwatchSelected(data))?_c('q-icon',{staticClass:"swatch-selected-check",attrs:{"name":_vm.$icons.fasCheck,"color":"white","size":"15px"}}):_vm._e()],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }