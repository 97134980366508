<template>
  <div class="relative-position mx-2 mt-2 mr-3">
    <q-btn
      v-if="isdelete && !showProgress"
      :icon="$icons.matClose"
      color="grey-9"
      text-color="white"
      round
      flat
      size="6px"
      class="absolute-top-right q-mr-xs q-mt-xs"
      @click="deleteMediaFromPost(media)"
    />
    <div
      class="pos-absolute media-comments-badge"
      style="top: -10px;right: -10px;"
      v-if="
        media.type.match(/image.*/) &&
          media.commentPoint &&
          media.commentPoint.length > 0 &&
          !isdelete
      "
    >
      <span v-if="media.commentPoint.length <= 9">
        {{ media.commentPoint.length }}
      </span>
      <span v-else>9+</span>
    </div>
    <div v-if="isVideo && !isDropbox" class="absolute-center ">
      <div class="text-center">
        <q-btn
          :icon="$icons.mdiPlay"
          color="primary"
          size="lg"
          round
          dense
          flat
        />
      </div>
    </div>
    <div v-if="isGoogle" class="showing-file-type">
      <q-btn
        :icon="$icons.fabGoogleDrive"
        dense
        color="pink"
        text-color="white"
        round
        size="sm"
      />
    </div>
    <div v-if="isDropbox" class="showing-file-type">
      <q-btn
        :icon="$icons.fabDropbox"
        dense
        color="pink"
        text-color="white"
        round
        size="sm"
      />
    </div>
    <img class="border-radius10" :src="imgSrc" />
  </div>
</template>
<script>
import FileExtensionMixin from "@/mixins/FileExtensionMixin";
export default {
  name: "EditorEditMedia",
  props: {
    media: Object,
    deleteMediaFromPost: Function,
    isdelete: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [FileExtensionMixin],
  computed: {
    isGoogle() {
      return this.media.file_resource === "google";
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    imgSrc() {
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      return this.transformMedia(this.media);
    },
  },
};
</script>
