<template>
  <div
    style="width:250px;padding-right: 20px;padding-left:20px"
    class="relative-postion add-custom-label"
  >
    <div>
      <div class="text-bold text-subtitle1 q-mb-sm" style="margin-top:15px">
        Create label
      </div>
      <q-btn
        flat
        size="sm"
        round
        dense
        padding="0"
        color="grey-7"
        class="absolute-top-right q-mr-xs q-mt-xs"
        :icon="$icons.matClose"
        @click="closeAddingLabel"
        clickable
      />
      <q-input
        v-model="label.name"
        placeholder="Label name"
        autofocus
        outlined
        dense
        @keyup.enter="addTaskLabel"
      >
      </q-input>
      <div class="text-bold text-subtitle1 q-mt-md q-mb-sm">
        Select color swatch
      </div>
      <div class="row " style="margin-bottom:6px">
        <template v-for="(label, index) in staticLabels">
          <div
            class="q-pr-sm q-mb-sm"
            @click="toggleTaskLabel(label)"
            :key="`edittask-labels-${index}`"
            clickable
          >
            <div>
              <q-icon
                :name="$icons.matCheckCircle"
                size="27px"
                :style="{ color: label.colorcode }"
                v-if="selectedLabel && label.id === selectedLabel.id"
              >
              </q-icon>
              <q-avatar
                size="27px"
                :style="{ 'background-color': label.colorcode }"
                class="rounded-borders"
                v-else
              />
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="text-bold text-subtitle1  q-mb-cs q-mt-xs">
        Pick color below
      </div>
      <div class="row">
        <template>
          <div class="row items-start q-gutter-md" style="margin-left: 5px;">
            <q-color
              v-model="hex"
              style="min-width: 209px;"
              no-header
              no-footer
              class="my-picker"
              @click="selectColorFromPicker()"
              clickable
            />
          </div>
        </template>
      </div> -->
    </div>
    <div class="add-task-label-button">
      <q-btn
        label="Cancel"
        size="md"
        no-caps
        outline
        dense
        color="blue-grey-12"
        style="width:95px"
        class="q-mt-sm"
        @click.stop="$emit('Cancel')"
        @click="closeAddingLabel"
      />
      <q-btn
        color="primary"
        label="Save"
        class="q-mt-sm q-ml-sm"
        no-caps
        dense
        size="md"
        style="width:99px"
        @click.stop="addTaskLabel"
        :disabled="!(label && label.name && label.name.trim()) || !(selectedLabel && selectedLabel.colorcode)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddTaskLabelMenu",
  //props: ["task", "labels", 'addTaskLabel'],
  props: {
    task: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    closeAddingLabel: Function,
    addTaskLabelEventHandler: Function,
  },
  data() {
    return {
      label: {
        name: null,
        colorcode: null,
      },
      hex: null,
      selectedLabel: null,
      staticLabels: [
        {
          id: 1,
          colorcode: "#597897",
        },
        {
          id: 2,
          colorcode: "#FF5577",
        },
        {
          id: 3,
          colorcode: "#FF7500",
        },
        {
          id: 4,
          colorcode: "#FFA900",
        },
        {
          id: 5,
          colorcode: "#FFD300",
        },
        {
          id: 6,
          colorcode: "#ACE800",
        },
        {
          id: 7,
          colorcode: "#00DE7A",
        },
        {
          id: 8,
          colorcode: "#00D6CA",
        },
        {
          id: 9,
          colorcode: "#3DDBFF",
        },
        {
          id: 10,
          colorcode: "#A03EE7",
        },
        {
          id: 11,
          colorcode: "#FF72FF",
        },
        {
          id: 12,
          colorcode: "#FF46A6",
        },
      ],
    };
  },
  computed: {},
  methods: {
    async toggleTaskLabel(label) {
      this.hex = null;
      this.selectedLabel = label;
    },
    async selectColorFromPicker() {
      this.selectedLabel = null;
    },
    addTaskLabel() {
      if (
        this.label &&
        this.label.name.trim() &&
        (this.selectedLabel || this.hex)
      ) {
        this.label.colorcode = this.selectedLabel
          ? this.selectedLabel.colorcode
          : this.hex;
        this.addTaskLabelEventHandler(this.label);
        this.resetTaskLabel();
        this.closeAddingLabel();
      }
    },
    resetTaskLabel() {
      this.label = {
        name: null,
        colorcode: null,
      };
    },
  },
};
</script>
