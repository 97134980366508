<template>
  <q-dialog
    transition-hide="none"
    transition-show="none"
    persistent
    :value="value"
  >
    <q-card
      style="max-width:390px;padding-left:28px;padding-right:28px"
      class="q-py-lg"
    >
      <div class="row dialog-title q-mb-md">
        <span class="q-mx-auto"
          >Do you want to stop the current timer and start a new one?</span
        >
      </div>
      <div class="text-center q-mt-md">
        <q-btn
          color="transperant"
          dense
          flat
          no-caps
          size="15px"
          label="Cancel"
          style="width:100px"
          class="q-mr-sm"
          @click="$emit('closeDialog')"
        />
        <q-btn
          color="primary"
          dense
          no-caps
          size="15px"
          label="Ok"
          style="width:100px"
          @click="startNewTimer"
          :loading="loading"
        />
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { getUnixTime } from "date-fns";
import {
  UpdateTaskTimeLogMutation,
  UpdateCompanySettingsMutation,
} from "@/gql";

export default {
  name: "StartNewTimerDialog",
  props: ["value", "createTimer", "activeTaskId", "currentCompany"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async startNewTimer() {
      this.stopActiveTaskTimer();
    },
    async stopActiveTaskTimer() {
      this.loading = true;
      const taskEntity = this.$api.getEntity("task", this.activeTaskId);
      const variables = {
        end_date: getUnixTime(new Date()),
        id: taskEntity.activetimelog.id,
        is_start: false,
      };
      const newTaskTimeLog = await this.$api.mutate({
        mutation: UpdateTaskTimeLogMutation,
        variables,
      });

      let objIndex = taskEntity.timelogs.findIndex(
        (obj) => obj.id == taskEntity.activetimelog.id
      );
      taskEntity.activetimelog = null;
      taskEntity.timelogs[objIndex] = newTaskTimeLog.data.updateTaskTimeLog;

      const boardQuery = this.$api.getQuery(`BoardQuery:${taskEntity.board_id}`);

      if(boardQuery.data) {
        boardQuery.data.board.tasks = boardQuery.data.board.tasks.map((task) =>
          task.id === taskEntity.id ? taskEntity : task
        );
      }

      const companyEntity = this.$api.getEntity(
        "company",
        this.currentCompany.companyId
      );
      companyEntity.start_task_id = null;
      companyEntity.start_time_log_id = null;
      const companyVariables = {
        name: companyEntity.name,
        start_task_id: null,
        start_time_log_id: null,
      };
      await this.$api.mutate({
        mutation: UpdateCompanySettingsMutation,
        variables: companyVariables,
      });
      await this.createTimer();
      this.loading = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 1.4 !important;
}
</style>
