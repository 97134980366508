<template>
  <q-item
    @click.stop="attachmentPreviewHandler(media)"
    class="
      group-hover
      q-px-none
      task-attachment-block
      items-center
      q-mb-sm q-pr-sm
    "
    clickable
  >
  <q-menu
    context-menu
    content-class="overflow-auto"
    v-model="attachmentMenu"
    auto-close
  >
    <q-list>
      <q-item @click="downloadFile(mediaId)" clickable>
        <q-item-section> Download </q-item-section>
      </q-item>
      <q-item
        v-if="
          task.cover_image === mediaId &&
            (isVisible([1, 2]) || task.owner.id === user.id)
        "
        @click="updateCoverImage(null)"
        clickable
      >
        <q-item-section> Remove cover </q-item-section>
      </q-item>
      <q-item
        v-if="isVisible([1, 2])"
        @click="$emit('renameFile', media)"
        clickable
      >
        <q-item-section> Rename file </q-item-section>
      </q-item>
      <q-item
        v-if="
          task.cover_image !== mediaId &&
            isImg &&
            (isVisible([1, 2]) || task.owner.id === user.id)
        "
        @click="checkMakeCoverAccess(mediaId)"
        clickable
      >
        <q-item-section> Make cover </q-item-section>
      </q-item>
      <q-item @click="$copyTextToClipboard(media.file)" clickable>
        <q-item-section> Copy Link </q-item-section>
      </q-item>
      <q-item @click="fromComputer" clickable v-if="isImg">
        <q-item-section> Replace image </q-item-section>
      </q-item>
      <q-item
        @click="confirm = true"
        v-if="isVisible([1, 2]) || task.owner.id === user.id"
        clickable
        class="text-red"
      >
        <q-item-section> Remove </q-item-section>
      </q-item>
      <q-item
        v-if="media.is_document_section && !media.is_read_only && (currentUser && currentUser.id === media.user_id)"
        @click="updateReadOnlyHandler(true)"
        clickable
        class="q-mb-sm"
        v-close-popup
      >
        <q-item-section>
          Set to read only
        </q-item-section>
      </q-item>
      <q-item
        v-else-if="media.is_document_section && media.is_read_only  && (currentUser && currentUser.id === media.user_id)"
        @click="updateReadOnlyHandler(false)"
        clickable
        class="q-mb-sm"
        v-close-popup
      >
        <q-item-section>
          Remove read only
        </q-item-section>
      </q-item>
    </q-list>
  </q-menu>
    <div class="row full-width cursor-pointer">
      <q-item-section
        style="min-width: 40px"
        class="q-pr-none media-thumbnail"
        avatar
      >
        <q-avatar size="40px" rounded>
          <img
            :src="isDropbox || isGoogle ? media.thumb : imgSrc"
            alt="avatar"
            style="border-radius: 6px"
          />
          <div
            v-if="isVideo && !isDropbox"
            class="row justify-center items-center absolute video-icon"
          >
            <div class="text-center flex-1">
              <q-btn
                :icon="$icons.mdiFileVideoOutline"
                style="color: #FF001F;"
                size="16px"
                round
                dense
                padding="0"
                flat
              />
            </div>
          </div>
          <div
            class="absolute media-comments-badge-task-attachment"
            v-if="media.type.match(/image.*/) && media.commentPoint.length > 0"
            :style="
              media.commentPoint.length == completedMediaPoints.length
                ? `background: #15d89a`
                : ''
            "
          >
            <span>
              {{
                completedMediaPoints.length + "/" + media.commentPoint.length
              }}
            </span>
          </div>
        </q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label
          class="text-black task-attachment-file-name"
          style="width: 535px;"
          >{{ media.filename }}</q-item-label
        >
        <!-- <q-item-label style="font-size:11px;color:#575D64" caption>
          {{ media.size | filesizeFormat }}mb |
          {{ fileSharedDate }}</q-item-label
        > -->
      </q-item-section>
    </div>
    <div
      class="
        group-hover-item
        task-attachment-menu
        row
        items-center
        justify-between
        flex-no-wrap
        q-px-sm
        absolute
      "
      style="right:0"
    >
      <div
        @click.stop="updateCoverImage(null)"
        v-if="
          task.cover_image === mediaId &&
            (isVisible([1, 2]) || task.owner.id === user.id)
        "
        class="cover-image-text"
        style="margin-top: 2px"
      >
        Remove cover
      </div>
      <div
        @click.stop="checkMakeCoverAccess(mediaId)"
        v-else-if="isImg && (isVisible([1, 2]) || task.owner.id === user.id)"
        class="cover-image-text"
        style="margin-top: 2px"
      >
        Make cover
      </div>
      <div v-if="isVisible([1, 2]) || task.owner.id === user.id">
        <q-item @click.stop="confirm = true" clickable style="padding:6px">
          <q-icon size="13px" class="text-red" :name="$icons.fasTrash">
          </q-icon>
        </q-item>
      </div>
      <div
        v-if="media.isbookmarked"
        @click.stop="removeMediaBookmark(media)"
        class="cursor-pointer"
      >
        <q-icon size="20px" color="pink" :name="$icons.matBookmark">
          <q-tooltip>
            Remove from Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <div
        v-if="!media.isbookmarked"
        @click.stop="addMediaBookmark(media)"
        class=" cursor-pointer"
      >
        <q-icon size="20px" color="black" :name="$icons.matBookmarkBorder">
          <q-tooltip>
            Add to Saved items
          </q-tooltip>
        </q-icon>
      </div>
    </div>
    <confirm-dialog
      v-model="confirm"
      title="Remove Attachment?"
      question="Are you sure you want to delete this attachment?"
      cancleText="Cancel"
      successText="Delete"
      :cancleMethod="() => (confirm = false)"
      :successMethod="() => detachAttachment({ taskId, mediaId })"
      :loading="false"
    />
  </q-item>
</template>
<script>
import format from "date-fns/format";
import fromUnixTime from "date-fns/fromUnixTime";
import UserScope from "@/mixins/UserScope";

import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { getDownloadUrl } from "@/services/downloadS3File";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { UpdateMediaMutation } from "@/gql";

export default {
  name: "TaskAttachment",
  mixins: [FileExtensionMixin, UserScope],
  props: [
    "media",
    "size",
    "fullview",
    "task",
    "pdfView",
    "videoPlayer",
    "audioPlayer",
    "detachTaskAttachmentHandler",
    "updateCoverImage",
    "fromComputer",
    "addMediaBookmark",
    "removeMediaBookmark",
    "isWorkspaceOwner",
    "editDoc",
    "currentUser"
  ],
  data() {
    return {
      show: false,
      confirm: false,
      attachmentMenu: false,
    };
  },
  components: {
    ConfirmDialog,
  },
  methods: {
    async updateReadOnlyHandler(flag) {
      try {
        let is_read_only = flag;
        const variables = {
          id: this.media.id,
          is_read_only,
        };
        await this.$api.mutate({
          mutation: UpdateMediaMutation,
          skipUpdates: true,
          variables,
        });

        const media = this.$api.getEntity("media", this.media.id);
        media.is_read_only = is_read_only;
        this.$q.notify({
          classes: "success-notification",
          position: "top-right",
          message: flag ? "Document added read only mode." : "Document remove read only mode.",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      } catch (error) {
        this.$q.notify({
          color: "negative",
          position: "top-right",
          message: "Something went wrong",
          timeout: 2500,
          icon: this.$icons.matAnnouncement,
          actions: [{ icon: this.$icons.matClose, color: "white" }],
        });
      }
    },
    checkMakeCoverAccess(mediaId) {
      let eligible = this.$checkEligiblity("task_cover");
      if (eligible) {
        this.updateCoverImage(mediaId);
      }
    },

    async downloadFile(id) {
      this.attachmentMenu = false;
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
    detachAttachment(args) {
      if (this.task.cover_image === this.media.id) {
        this.updateCoverImage(null);
      }
      this.detachTaskAttachmentHandler({
        id: args.mediaId,
        task_id: args.taskId,
      });
      this.confirm = false;
    },
    attachmentPreviewHandler(media) {
      if (this.isImg) {
        this.fullview(media);
      } else if (this.isVideo) {
        this.videoPlayer(media);
      } else if (this.isAudio) {
        this.audioPlayer(this.media);
      } else if (this.media.is_document_section) {
        this.editDoc(this.media);
      } else if (!this.isImg && !this.isVideo && !this.isZip) {
        this.pdfView(media);
      }
    },
  },
  computed: {
    canRemove() {
      return this.canRemoveMessageFromWorkspace || this.isWorkspaceOwner;
    },
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    taskId() {
      return this.task.id;
    },
    mediaId() {
      return this.media.id;
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isGoogle() {
      return this.media.file_resource === "google";
    },
    filename() {
      return this.media.filename;
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      // Used Mixin : FileExtensionMixin
      return this.mediaPreview.type;
    },
    fileSharedDate() {
      return format(fromUnixTime(this.media.created_at), "MMM. do @hh:mm aaaa");
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.media-card-bg {
  background: #E6E9EE;
}
</style>
