<template>
  <div
    class="relative-position img-border q-mb-sm card-media-card-hover"
    style="border: 1px solid rgb(215, 224, 231);"
    :class="mediaPreview.hoverClass"
    :style="{ 'background-color': mediaPreview.bgColor }"
    v-if="mediaWorkspace"
  >
    <div
      class="task-menu-option thread-icons row items-center q-px-xs q-mt-sm q-mr-xs"
      style="z-index: 100"
    >
      <div
        v-if="media.isbookmarked"
        @click.stop="removeMediaBookmark(media)"
        class="cursor-pointer"
      >
        <q-icon size="20px" color="pink" :name="$icons.matBookmark">
          <q-tooltip>
            Remove from Saved items
          </q-tooltip>
        </q-icon>
      </div>
      <q-btn
        color="white"
        text-color="black"
        size="13px"
        round
        dense
        padding="0"
        class="q-ml-xs"
        :icon="$icons.matMoreHoriz"
        @click.stop
      >
        <q-menu content-style="min-width:150px; padding: 0 9px;" auto-close>
          <q-list class="q-py-sm">
            <q-item
              clickable
              @click="gotoWorkspace('MediaView', mediaWorkspace.id)"
            >
              <q-item-section>
                <q-item-label>Go to workspace</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              class="moreoption-list-height"
              avatar
              @click.stop="downloadFile(mediaId)"
              v-if="isS3"
              clickable
            >
              <q-item-section>
                Download
              </q-item-section>
            </q-item>
            <q-item
              class="moreoption-list-height"
              avatar
              @click.stop="$copyTextToClipboard(media.file)"
              clickable
            >
              <q-item-section>
                Copy link
              </q-item-section>
            </q-item>
            <!-- <q-item clickable @click.stop="attachToMessage(media)">
              <q-item-section>
                Attach to message
              </q-item-section>
            </q-item> -->
            <q-item
              @click="fromComputer"
              v-if="isImg"
              class="moreoption-list-height"
              clickable
            >
              <q-item-section>
                Replace image
              </q-item-section>
            </q-item>
            <q-item
              @click="deleteFile(mediaId)"
              class="moreoption-list-height text-red"
              clickable
            >
              <q-item-section>
                Delete file
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <q-img
      height="151px"
      width="151px"
      :src="imgSrc"
      class="img-border cursor-pointer "
    >
      <div
        v-if="isVideo && !isDropbox"
        @click="videoPlayer(media)"
        class="row justify-center items-center fit"
      >
        <div class="text-center flex-1">
          <q-btn
            :icon="$icons.mdiPlay"
            color="primary"
            size="lg"
            round
            dense
            flat
          />
        </div>
      </div>
      <div
        @click="openViewMode"
        class="fill-height full-width media-card-hover-item cursor-pointer"
      ></div>
      <q-tooltip>
        {{ media.filename }}
      </q-tooltip>
    </q-img>
    <div
      class="absolute media-comments-badge"
      v-if="media.type.match(/image.*/) && media.commentPoint.length > 0"
      :style="
        media.commentPoint.length == completedMediaPoints.length
          ? `background: #15d89a`
          : ''
      "
    >
      <span>
        {{ completedMediaPoints.length + "/" + media.commentPoint.length }}
      </span>
    </div>
    <vue-dropzone-file-upload
      hidden
      ref="vueFileUploader"
      :workspace="mediaWorkspace.id"
      :isWorkspaceOwner="isWorkspaceOwner"
      :authToken="currentCompany.accessToken"
      @close="fileUploadCloseHandler"
      @setProgress="(data) => $emit('setProgress', data)"
      :media_id="mediaId"
    ></vue-dropzone-file-upload>
  </div>
</template>

<script>
import Vue from "vue";

import FileExtensionMixin from "@/mixins/FileExtensionMixin";
import { getDownloadUrl } from "@/services/downloadS3File";
import VueDropzoneFileUpload from "@/components/Miscellaneous/VueDropzoneFileUpload.vue";

import { SET_WORKSPACE_EDITOR_MEDIA } from "@/store/mutation-types";
import { RemoveMediaBookmarkMutation } from "@/gql";
export default {
  name: "SavedFile",
  mixins: [FileExtensionMixin],
  props: [
    "media",
    "gotoWorkspace",
    "currentCompany",
    "activeCompany",
    "deleteFile",
    "fullview",
    "videoPlayer",
    "audioPlayer",
    "pdfView",
    "user",
    "editDoc",
  ],
  components: { VueDropzoneFileUpload },
  data() {
    return {
      fileUpload: {
        flag: false,
        dialog: false,
      },
    };
  },
  methods: {
    fileUploadCloseHandler(fileList) {
      this.fileUpload.flag = false;
      this.fileUpload.dialog = false;

      if (this.mediaId) {
        const media = this.$api.getEntity("media", this.mediaId);
        media.file = fileList[this.mediaId].file;
        media.thumb = fileList[this.mediaId].thumb;
      }
    },
    fromComputer() {
      this.$refs.vueFileUploader.openFileDialog();
    },
    openViewMode() {
      if (this.isImg) {
        this.fullview({ media: this.media, newpoint: "no" });
      } else if (this.isDoc && this.isS3) {
        this.pdfView(this.media);
      } else if (this.isAudio) {
        this.audioPlayer(this.media);
      } else if (this.isGoogle || this.isDropbox) {
        this.openNextTabLink();
      } else if (this.isVideo && this.isS3) {
        this.videoPlayer(this.media);
      } else if (this.media.is_document_section) {
        this.editDoc(this.media);
      }
    },
    openNextTabLink() {
      const isElectron = navigator.userAgent.indexOf("Electron") !== -1;
      if (isElectron) window.electron.openExternal(this.media.file);
      else window.open(this.media.file, "_blank");
    },
    async downloadFile(id) {
      const response = await getDownloadUrl(id, this.$api);
      if (response !== null) {
        window.location = response;
      }
    },
    attachToMessage(media) {
      let value = {};
      const files = this.$store.getters.workspaceEditorMedia(
        this.currentCompany.companyId,
        this.mediaWorkspace.id
      );

      if (files) {
        value = files;
      }
      media["verifyKey"] = "fromShared";
      Vue.set(value, media.id, media);
      this.$store.commit(SET_WORKSPACE_EDITOR_MEDIA, {
        value,
        companyId: this.currentCompany.companyId,
        workspaceId: this.mediaWorkspace.id,
      });
      if (this.$route.name !== "MessagingView") {
        this.$router.push(
          `/companies/${this.activeCompany}/workspaces/${this.mediaWorkspace.id}/messaging`
        );
      }
    },
    async removeMediaBookmark(media) {
      try {
        const variables = {
          media_id: media.id,
        };
        const savedItemsQuery = this.$api.getQuery("SavedItemsQuery");
        savedItemsQuery.data.savedItems.media = savedItemsQuery.data.savedItems.media.filter(
          (item) => item.id != media.id
        );
        await this.$api.mutate({
          mutation: RemoveMediaBookmarkMutation,
          variables,
          skipUpdates: true,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    fileExt() {
      if (this.media) {
        return this.media.filename.split(".")[1];
      }
      return null;
    },
    mediaId() {
      return this.media.id;
    },
    isDoc() {
      if (
        this.fileExt &&
        (this.fileExt.match(/pdf.*/) ||
          this.fileExt.match(/txt.*/) ||
          this.fileExt.match(/html.*/) ||
          this.fileExt.match(/doc.*/) ||
          this.fileExt.match(/pptx.*/) ||
          this.fileExt.match(/xls.*/) ||
          this.fileExt.match(/rtf.*/))
      ) {
        return true;
      }
      return false;
    },
    isVideo() {
      return this.media.type.match(/video.*/);
    },
    isDropbox() {
      return this.media.file_resource === "dropbox";
    },
    isGoogle() {
      return this.media.file_resource === "google";
    },
    isImg() {
      return this.media.type.match(/image.*/);
    },
    isPdf() {
      return this.media.type.match(/pdf.*/);
    },
    isAudio() {
      return this.media.type.match(/audio.*/);
    },
    isZip() {
      return this.media.type.match(/zip.*/);
    },
    filename() {
      return this.media.filename;
    },
    mediaPreview() {
      return this.transformMedia(this.media);
    },
    imgSrc() {
      if (this.isGoogle) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isImg) {
        return this.media.thumb;
      }
      if (this.isDropbox && this.isVideo) {
        return "/static/media-icons/Mov.svg";
      }
      return this.mediaPreview.type;
    },
    isS3() {
      return this.media.file_resource === "s3";
    },
    mediaWorkspace() {
      const workspace = this.$api.getEntity(
        "workspace",
        this.media.workspace_id
      );
      return workspace;
    },
    isWorkspaceOwner() {
      if (this.mediaWorkspace && this.user) {
        return this.user.id === this.mediaWorkspace.owner.id;
      }
      return false;
    },
    completedMediaPoints() {
      return this.getMedia.commentPoint.filter(
        (point) => point.last_label === "#15d89a"
      );
    },
    getMedia() {
      if (this.media) {
        this.media.commentPoint.forEach((commentPoint) => {
          commentPoint["comments"] = [];
          commentPoint["menuFlag"] = false;
          if (commentPoint.last_label === "#15d89a") {
            commentPoint["completed"] = true;
          } else {
            commentPoint["completed"] = false;
          }
        });
      }
      return this.media;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-border {
  border-radius: 10px;
}
</style>
